import { mdiCellphoneKey, mdiCodeJson } from '@mdi/js';
import { mdiFormTextboxPassword } from '@mdi/js/commonjs/mdi';
import {
  Badge,
  Flex,
  Link,
  Text,
  Button,
  Skeleton,
  Heading,
  HStack,
  Stack,
  Wrap,
} from '@chakra-ui/react';
import { useGetCurrentOrg } from 'features/home/services/identity/organization';
import { useState } from 'react';
import MFAActivationModal from './components/MFAActivationModal';
import MFADeactivationModal from './components/MFADeactivationModal';
import { Feature } from 'lib/featureFlags/features';
import useSetDocumentTitle from 'features/common/hooks/useSetDocumentTitle';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { useSupportCase } from 'features/snow/hooks/useSupportCase';

const cardData = [
  {
    icon: mdiFormTextboxPassword,
    color: 'neutral',
    text: (
      <>
        When MFA is enabled, a user must enter a{' '}
        <strong>one-time password (OTP)</strong> when they log in.
      </>
    ),
  },
  {
    icon: mdiCellphoneKey,
    color: 'neutral',
    text: (
      <>
        This OTP is generated using a mobile app, such as{' '}
        <strong>Microsoft Authenticator.</strong>
      </>
    ),
  },
  {
    icon: mdiCodeJson,
    color: 'neutral',
    text: (
      <>
        If a user does not have access to an authenticator app to generate the
        OTP, they can enter their <strong>recovery code.</strong>
      </>
    ),
  },
];

export const initialData = {
  title: 'Reset MFA request',
  description:
    'Please reset the MFA for the following account: (specify the email address and account owner).',
  app: 'Portal',
  environment: '',
  productcode: 'Portal',
  impact: '4',
};

const MultifactorAuthentication = () => {
  useSetDocumentTitle();
  const { isLoading: isGetCurrentOrganizationLoading, data } =
    useGetCurrentOrg();

  useSupportCase({
    componentName: 'create-mfa-case-drawer',
    buttonSelector: '.js-create-mfa-case-drawer',
    drawerState: {
      selectedApp: 'Portal',
      title: 'Reset MFA request',
      description:
        'Please reset the MFA for the following account: (specify the email address and account owner).',
    },
  });

  const mfaEnabled = data?.data.mfa.required;

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  return (
    <>
      <Flex mb='10' justifyContent='space-between' gap='6'>
        <Wrap align='center' spacing='4'>
          <Heading as='h1'>
            Multi-factor authentication
            <Skeleton
              isLoaded={!isGetCurrentOrganizationLoading}
              //@ts-ignore
              display='inline-block'
            ></Skeleton>
          </Heading>
          <Badge
            mt='1.5'
            colorScheme={mfaEnabled ? 'success' : 'neutral'}
            data-testid={
              mfaEnabled ? 'mfa-badge-enabled' : 'mfa-badge-not-enabled'
            }
          >
            {mfaEnabled ? 'Enabled' : 'Not enabled'}
          </Badge>
        </Wrap>
        <Skeleton isLoaded={!isGetCurrentOrganizationLoading}>
          {mfaEnabled ? (
            <Button
              variant='solid'
              colorScheme='danger'
              data-testid='deactivateButton'
              onClick={() => setShowDeactivateModal(true)}
              data-behavior-analytics-id='disable mfa'
            >
              Disable MFA
            </Button>
          ) : (
            <Button
              variant='solid'
              colorScheme='success'
              onClick={() => setShowActivateModal(true)}
              data-testid='activateButton'
              data-behavior-analytics-id='enable mfa'
            >
              Enable MFA
            </Button>
          )}
        </Skeleton>
      </Flex>
      <Stack spacing='10'>
        <Text maxW='prose'>
          Multi-factor authentication (MFA) is an authentication method that
          requires a user to provide two or more verification factors to log in.
          When MFA is enabled, every user in your organization must use it.
        </Text>

        {cardData.map(({ icon, color, text }, index) => (
          <HStack spacing='6' key={index}>
            <PortalIcon
              path={icon}
              boxSize='icon.4xl'
              layerStyle='icon.subtle'
              color={`${color}`}
            />
            <Text maxW='sm'>{text}</Text>
          </HStack>
        ))}

        <Feature name={'SNOW'}>
          <Text maxW='prose'>
            If a user loses their recovery code,{' '}
            <Link
              data-testid='contactSitecoreSupport'
              className='js-create-mfa-case-drawer'
            >
              create a support ticket
            </Link>{' '}
            to reset their account.
          </Text>
        </Feature>
      </Stack>
      <MFAActivationModal
        isOpen={showActivateModal}
        onClose={() => setShowActivateModal(false)}
      />
      <MFADeactivationModal
        isOpen={showDeactivateModal}
        onClose={() => setShowDeactivateModal(false)}
      />
      <create-mfa-case-drawer />
    </>
  );
};

export default MultifactorAuthentication;
