import { Button, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import RecommendationsCard from './RecommendationsCard';
import { getCtaCallback } from 'features/domains/portal/content/utils';
import { useHistory } from 'react-router';
import {
  Link,
  Maybe,
  RecommendationCard,
  useGetRecommendationCardsQuery,
} from 'gql/graphql';
import { useMainStateDispatch } from 'features/main/context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalStorageValue } from 'features/common/utils/localStorage';
import { useHomepageUtils } from 'features/home/components/homepage/utils/utils';
import { useGetCurrentUser } from 'features/profile/services/identity/user';
import isEmpty from 'lodash/isEmpty';

const Recommendations = () => {
  const history = useHistory();
  const [, dispatch] = useMainStateDispatch();
  const { data } = useGetRecommendationCardsQuery();
  const { data: userData } = useGetCurrentUser();
  const [visibleCards, setVisibleCards] = useState<RecommendationCard[]>([]);
  const recommendationCards = useMemo(
    () => data?.personalizedContent?.recommendationCards ?? [],
    [data],
  );
  const identifierForUserAtLocalStorage = userData?.data.id || '';
  const userSettingsLocalStorage = LocalStorageValue.getInstance(
    identifierForUserAtLocalStorage,
  );
  const { generateKeyForBannerDismissal } = useHomepageUtils();
  const recommendationCardsDismissedLabel = generateKeyForBannerDismissal(
    'recommendationCards',
  );

  const getDismissedCardsFromStorage = useCallback(
    () =>
      (userSettingsLocalStorage.get(
        recommendationCardsDismissedLabel,
      ) as string[]) || [],
    [recommendationCardsDismissedLabel, userSettingsLocalStorage],
  );

  const clearAllHandler = () => {
    const dismissedCards = getDismissedCardsFromStorage();
    userSettingsLocalStorage.set(recommendationCardsDismissedLabel, [
      ...dismissedCards,
      ...visibleCards.map((v) => v.cardId),
    ]);

    setVisibleCards([]);
  };

  const setNewSetOfCards = (
    cards: RecommendationCard[],
    cardRemoved: RecommendationCard,
  ) => {
    const dismissedCards = getDismissedCardsFromStorage();
    userSettingsLocalStorage.set(recommendationCardsDismissedLabel, [
      ...dismissedCards,
      cardRemoved.cardId,
    ]);
    setVisibleCards(
      cards.filter((c: RecommendationCard) => c.cardId !== cardRemoved.cardId),
    );
  };

  const onCardClickHandler = (link: Maybe<Link> | undefined) => {
    if (!link) return;

    return getCtaCallback({ link, history, dispatch: dispatch })();
  };

  useEffect(() => {
    const dismissedCards = getDismissedCardsFromStorage();
    const filteredCards = isEmpty(dismissedCards)
      ? recommendationCards
      : recommendationCards.filter(
          (card) => !dismissedCards.includes(card.cardId),
        );
    const cardsToShow =
      filteredCards.length > 3 ? filteredCards.slice(0, 3) : filteredCards;

    setVisibleCards(cardsToShow);
  }, [getDismissedCardsFromStorage, recommendationCards]);

  if (visibleCards.length === 0) return null;

  return (
    <Flex
      data-testid='recommendations'
      flexDirection='column'
      width='full'
      gap={5}
    >
      <Flex width='full' justifyContent='space-between'>
        <Heading size='md' noOfLines={1}>
          Recommended for you
        </Heading>
        <Button variant='link' onClick={clearAllHandler}>
          Clear all
        </Button>
      </Flex>
      <Grid
        width='full'
        templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={4}
      >
        {visibleCards.map((c) => (
          <GridItem w='full' h='full' key={c.cardId}>
            <RecommendationsCard
              cardId={c.cardId}
              icon={c.icon}
              title={c.title}
              description={c.description}
              link={c.link}
              onClick={() => onCardClickHandler(c.link)}
              onClose={() => {
                setNewSetOfCards(visibleCards, c);
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};

export default Recommendations;
