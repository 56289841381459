const element = document.getElementById('portal-server-settings');
let serverSettings;

if (element) {
  serverSettings = JSON.parse(element.textContent || '');
}

const domain =
  serverSettings?.auth0?.domain ||
  (process.env.REACT_APP_AUTH0_DOMAIN as string);
const clientId =
  serverSettings?.auth0?.clientId ||
  (process.env.REACT_APP_AUTH0_CLIENT_ID as string);
const audience =
  serverSettings?.auth0?.audience ||
  (process.env.REACT_APP_AUTH0_AUDIENCE as string);

export const env = serverSettings?.env || (process.env.NODE_ENV as string);

export const isNonProd = ['staging', 'dev', 'qa'].includes(
  env?.toLowerCase() as string,
);

export function getSnowBaseUrl() {
  const lowerCaseEnv = env?.toLowerCase() as string;

  const snowBaseUrl = {
    dev: 'https://sitecoredev.service-now.com/api/scas/v1',
    staging: 'https://sitecoredev.service-now.com/api/scas/v1',
    'pre-production': 'https://sitecoretest.service-now.com/api/scas/v1',
    production: 'https://sitecore.service-now.com/api/scas/v1',
  }[lowerCaseEnv];

  // NOTICE: will always point to dev until snow side will deploy to the rest scale units
  return snowBaseUrl || 'https://sitecoredev.service-now.com/api/scas/v1';
}

export function getFeatureFlagBaseUrl() {
  const lowerCaseEnv = env?.toLowerCase() as string;

  const featureFlagBaseUrl = {
    dev: 'https://featureflags-dev.sitecore-staging.cloud/api/featureflags/v1',
    qa: 'https://featureflags-qa.sitecore-staging.cloud/api/featureflags/v1',
    staging:
      'https://featureflags-staging.sitecore-staging.cloud/api/featureflags/v1',
    'pre-production':
      'https://featureflags-beta.sitecorecloud.io/api/featureflags/v1',
    production: 'https://featureflags.sitecorecloud.io/api/featureflags/v1',
  }[lowerCaseEnv];

  return featureFlagBaseUrl || '';
}

/**
 * Service to send apm events from this app to.
 * Setting this url also enables APM.
 */
export const apmServerUrl: string =
  (window as any)?.REACT_APP_APM_SERVER_URL ||
  process.env.REACT_APP_APM_SERVER_URL;

/**
 * The label this app appears in APM with, in the list of services.
 * Allowed characters: a-z, A-Z, 0-9, -, _, and space.
 * Optional. Defaults to 'Sitecore cloud Portal UI'.
 */
export const apmServiceName: string =
  (window as any)?.REACT_APP_APM_SERVICE_NAME ||
  process.env.REACT_APP_APM_SERVICE_NAME ||
  'Sitecore Cloud Portal UI';

export const getAuthSettings = () => {
  return { domain, clientId, audience };
};
