import { Flex, Text, Heading, Tooltip, Box, VStack } from '@chakra-ui/react';
import { NoConnections } from './no-connections-component';
import AddConnectionButton from './add-connection-button-component';
import { ConnectionsTable } from './connections-table/connections-table';
import { useConnectionsState } from 'features/sso/sso-context';
import SSOConnectionDrawer from 'features/sso/add-connection-drawer';

import EnableSSOConnectionModal from 'features/sso/enable-sso-connection-modal/enable-sso-connection-modal';
import { RestartTest, StartTest } from 'features/sso/testConnection/variants';
import DeleteSSOConnectionModal from 'features/sso/delete-sso-connection-modal/delete-sso-connection-modal';
import VerifyConnectionModal from './verifyConnection/verify-connection-modal';
import {
  ssoCreateRoute,
  ssoEditRoute,
  ssoTestRoute,
  ssoRestartTestRoute,
  ssoVerifyRoute,
  ssoEnableRoute,
  ssoDeleteRoute,
  SSORoute,
} from './sso-routes';
import useSetDocumentTitle from 'features/common/hooks/useSetDocumentTitle';
import { ReactElement } from 'react';
import { SSOSaml } from './saml';
import { useFeatures } from 'lib/featureFlags/features';
import SSOSamlConnectionModal from 'features/sso/saml/sso-saml-connection-modal';

export const ssoProviders = [
  {
    label: 'OpenID Connect',
    value: 'oidc',
  },
  {
    label: 'SAML',
    value: 'samlp',
  },
];

export const SSO = () => {
  const { connections, isLoading } = useConnectionsState();
  const hasConnections = connections && connections.length > 0;
  const hasOverFiveConnections = connections && connections.length >= 5;
  const { SAML: isSamlEnabled, OPENID: isOpenIdEnabled } = useFeatures();

  // filter the available creation options
  const enabledSSOProviders = ssoProviders.filter((provider) =>
    provider.label === 'SAML'
      ? isSamlEnabled
      : provider.label === 'OpenID Connect'
      ? isOpenIdEnabled
      : true,
  );

  // filter the available enabled connections
  const enabledConnections = connections.filter((connection) =>
    connection.strategy === 'samlp'
      ? isSamlEnabled
      : connection.strategy === 'oidc'
      ? isOpenIdEnabled
      : true,
  );

  useSetDocumentTitle();
  return (
    <Flex
      height='100%'
      direction='column'
      alignItems='center'
      data-testid='sso-page'
    >
      <Flex justifyContent='space-between' width='100%'>
        <Flex direction='column' alignSelf='start'>
          <Heading as='h1'>Single sign-on (SSO)</Heading>
          <VStack my='8'>
            <Text maxW='2xl'>
              With single sign-on, your team can log in to your Sitecore Cloud
              Portal organization and apps using your existing identity
              provider.
            </Text>
            <Text maxW='2xl'>
              Setting up an SSO connection involves a few steps: Add and
              configure a new connection → Verify the domains → Test the new
              connection → Enable the new connection.
            </Text>
          </VStack>
        </Flex>
        {hasConnections && (
          <Tooltip
            label={
              hasOverFiveConnections
                ? 'SSO connection limit reached. To add another SSO connection, delete an existing one.'
                : ''
            }
            data-testid='add-sso-connection-button-tooltip'
          >
            <Box height='fit-content'>
              <AddConnectionButton
                label='Add SSO connection'
                menuItems={enabledSSOProviders}
                isDisabled={hasOverFiveConnections}
                variant='solid'
              />
            </Box>
          </Tooltip>
        )}
      </Flex>
      {isLoading || hasConnections ? (
        <ConnectionsTable connections={enabledConnections} />
      ) : (
        <NoConnections ssoProviderOptions={enabledSSOProviders} />
      )}

      <SSORoute exact path={ssoCreateRoute} component={SSOComponent} />
      <SSORoute exact path={ssoEditRoute} component={SSOComponent} />
      <SSORoute exact path={ssoTestRoute} component={StartTest} />
      <SSORoute exact path={ssoRestartTestRoute} component={RestartTest} />
      <SSORoute exact path={ssoVerifyRoute} component={VerifyConnectionModal} />
      <SSORoute
        exact
        path={ssoEnableRoute}
        component={EnableSSOConnectionModal}
      />
      <SSORoute
        exact
        path={ssoDeleteRoute}
        component={DeleteSSOConnectionModal}
      />
    </Flex>
  );
};

type SSOMap = {
  [key: string]: React.ComponentType<any>;
};

const ssoProviderMap: SSOMap = {
  oidc: SSOConnectionDrawer,
  samlp: SSOSaml,
};

const SSOComponent = (props: {
  match: { params: { ssoProvider: string | number }; path: string };
}): ReactElement | null => {
  // check in url path if there is edit or create
  const isEdit = props.match.path.includes('edit');
  const setupMap = isEdit ? ssoConfigureMap : ssoProviderMap;

  const Component = setupMap[props.match.params?.ssoProvider || ''];
  return Component ? <Component /> : null;
};
const ssoConfigureMap: SSOMap = {
  oidc: SSOConnectionDrawer,
  samlp: SSOSamlConnectionModal,
};
