/* eslint-disable no-console */

declare global {
  interface Window {
    aptrinsic: any;
  }
}

type KcbCallback = {
  onDone: () => void;
  onError: () => void;
};

export function show(onDone?: KcbCallback, onError = console.error) {
  window.aptrinsic('kcb', 'show', {
    onDone,
    onError,
  });
}

let initialDisplay: string | null = null;

// Add the keydown event listener to the window

const keydownHandler = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    window.aptrinsic('kcb', 'hide');
  }
};

document.addEventListener('keydown', keydownHandler);

export function open(onDone?: KcbCallback, onError = console.error) {
  window.aptrinsic('kcb', 'open', {
    onDone,
    onError,
  });

  // Create a MutationObserver to watch for changes in the button
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const button = document.querySelector(
          '.apt-widget-dragging-handle',
        ) as HTMLElement;

        if (!button) return;

        button.addEventListener(
          'click',
          (event) => {
            event.stopImmediatePropagation();
            hide();
            const iframe = button.parentElement?.querySelector(
              'iframe[name="widget-icon"]',
            ) as HTMLIFrameElement;
            if (iframe) {
              if (initialDisplay === null) {
                initialDisplay = iframe.style.display;
              }
              iframe.style.setProperty('display', 'none', 'important');
            }
          },
          true,
        );
      }
    }
  });

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  // Reset the display property of the iframe when the open function is called
  const iframe = document.querySelector(
    'iframe[name="widget-icon"]',
  ) as HTMLIFrameElement;
  if (iframe && initialDisplay !== null) {
    iframe.style.display = initialDisplay;
  }
}

export function hide(onDone?: KcbCallback, onError = console.error) {
  window.aptrinsic('kcb', 'hide', {
    onDone,
    onError,
  });
}

export function close(onDone?: KcbCallback, onError = console.error) {
  window.aptrinsic('kcb', 'close', {
    onDone,
    onError,
  });
}
