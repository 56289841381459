import {
  Grid,
  Heading,
  Skeleton,
  CardBody,
  Card,
  Button,
  Flex,
} from '@chakra-ui/react';
import range from 'lodash/range';
import { memo } from 'react';
import { AppTile } from 'features/home/components/homepage/components/AppTile';
import NoTenantsPanelMessage from './NoTenantsPanelMessage';
import { env } from 'features/common/config/envConfig';
import { T } from '@transifex/react';
import {
  ContextUserApplication,
  GetTenantsQuery,
  useGetTenantsQuery,
} from 'gql/graphql';
import { selectApp, useMainStateDispatch } from 'features/main/context';
import { openDrawer } from 'features/main/context/drawer/actionCreators';
import { isNonProdEnvironment } from '@sitecore-ui/portal-singular';

const TenantsFeed = () => {
  const [, dispatch] = useMainStateDispatch();

  const {
    data: tenantsQueryData,
    loading: tenantsQueryLoading,
    fetchMore,
  } = useGetTenantsQuery({
    variables: {
      first: 20,
      env,
    },
    notifyOnNetworkStatusChange: true,
  });

  const edges = tenantsQueryData?.user?.applications?.edges ?? [];
  const nodes = tenantsQueryData?.user?.applications?.nodes ?? [];

  const getCustomerEnvironmentType = (id: string) =>
    nodes
      .filter((node) => node.id === id)[0]
      .labels?.filter(
        (label) =>
          label.key === 'CustomerEnvironmentType' &&
          !isNonProdEnvironment(label.value),
      )[0]?.value;

  const applicationsViews = edges.map((edge) => {
    const {
      node: {
        id,
        appearance: {
          // @ts-ignore
          web: {
            actions: { nodes },
          },
        },
      },
    } = edge;

    const aNode = nodes[0];

    return {
      displayName: aNode?.displayName,
      name: aNode?.name,
      id,
      icon: aNode?.icon,
      customerEnvironmentType: getCustomerEnvironmentType(id),
    };
  });

  const fetchMoreTenants = () => {
    const { endCursor } = tenantsQueryData?.user?.applications?.pageInfo || {};

    return fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (prev: GetTenantsQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const pageInfo = fetchMoreResult?.user?.applications?.pageInfo || {
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null,
          endCursor: null,
        };

        return {
          user: {
            ...fetchMoreResult.user,
            applications: {
              ...fetchMoreResult.user?.applications,
              edges: [
                ...(prev?.user?.applications?.edges ?? []),
                ...(fetchMoreResult?.user?.applications?.edges ?? []),
              ],
              nodes: [
                ...(prev?.user?.applications?.nodes ?? []),
                ...(fetchMoreResult?.user?.applications?.nodes ?? []),
              ],
              pageInfo,
              totalCount: fetchMoreResult?.user?.applications?.totalCount || 0,
            },
          },
        };
      },
    });
  };

  const cardOnClick = (tenantId: string) => {
    dispatch(
      selectApp({
        app: nodes.find(
          (node) => node.id === tenantId,
        ) as ContextUserApplication,
      }),
    );
    dispatch(openDrawer('tenant-drawer'));
  };

  const shouldShowMoreButton =
    (!tenantsQueryLoading &&
      tenantsQueryData?.user?.applications?.pageInfo?.hasNextPage) ??
    false;

  return (
    <>
      <Heading size='md' noOfLines={1}>
        Apps
      </Heading>
      <Card
        variant='flat'
        size={['md', 'lg']}
        w='full'
        data-testid='apps-block'
        data-behavior-analytics-feature='apps - home page'
      >
        <CardBody>
          {tenantsQueryLoading && (
            <Grid
              gap='4'
              templateColumns='repeat(auto-fill, minmax(var(--sizes-40), 1fr))'
            >
              {range(10).map((_, index) => (
                <Skeleton
                  h='32'
                  w='40'
                  isLoaded={false}
                  key={index}
                  data-testid='app-skeleton-placeholder'
                />
              ))}
            </Grid>
          )}
          {!tenantsQueryLoading && (
            <>
              {applicationsViews.length < 1 ? (
                <NoTenantsPanelMessage />
              ) : (
                <Grid
                  columnGap='0'
                  rowGap='4'
                  templateColumns={[
                    'repeat(auto-fill, minmax(var(--sizes-32), 1fr))',
                    'repeat(auto-fill, minmax(var(--sizes-44), 1fr))',
                  ]}
                >
                  {applicationsViews?.map(
                    (
                      { displayName, name, icon, id, customerEnvironmentType },
                      idx,
                    ) => {
                      const subTitleNoSpaces = displayName.replaceAll(' ', '-');

                      return (
                        <AppTile
                          data-testid={`app-card-${idx}`}
                          data-behavior-analytics-id={`app-card_ga_${name}_${subTitleNoSpaces}`}
                          appName={name}
                          tenantName={displayName}
                          key={`${name}_${displayName}`}
                          onClick={() => cardOnClick(id)}
                          image={icon.src}
                          customerEnvironmentType={customerEnvironmentType}
                        />
                      );
                    },
                  )}
                </Grid>
              )}
            </>
          )}
          {shouldShowMoreButton && (
            <Flex align='center' justifyContent='center'>
              <Button
                variant='link'
                mt='6'
                onClick={fetchMoreTenants}
                data-testid='show-all-button'
              >
                <T _str='Show more' />
              </Button>
            </Flex>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export const TenantsPanel = memo(TenantsFeed);
