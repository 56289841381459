import { Link, Text } from '@chakra-ui/react';
import { GenericErrorTemplate } from 'features/common/components/GenericErrorTemplate';
import { FallbackProps } from 'react-error-boundary';

export interface IErrorPageProps {
  resetErrorBoundary?: FallbackProps['resetErrorBoundary'];
}

export const ErrorPage = ({ resetErrorBoundary }: IErrorPageProps) => (
  <GenericErrorTemplate
    title='Something went wrong'
    text={
      <Text>
        Please try again. If the issue persists, try visiting our{' '}
        <Link mb={4} href='https://support.sitecore.com/kb' isExternal>
          Visit Knowledge Base
        </Link>{' '}
        for assistance.
      </Text>
    }
    variant='error'
    shortcuts={
      <>
        {resetErrorBoundary && (
          <Link mb={4} onClick={resetErrorBoundary}>
            Retry
          </Link>
        )}
        <Link
          onClick={() => (window.location.href = window.location.origin)}
          mb={4}
        >
          Go to Homepage
        </Link>
      </>
    }
  />
);
