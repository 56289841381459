/* istanbul ignore file */
import {
  AgentConfigOptions,
  init as initApm,
  Transaction,
} from '@elastic/apm-rum';
import { Environment } from '@sitecore-ui/portal-singular';

import { apmServerUrl, env } from 'features/common/config/envConfig';

const localDebugEnabled = process.env.REACT_APM_ENABLED === 'true';

/**
 * Determines if apm should be enabled. This is based on the environment and local debug flag. If the environment is not pre-prod or prod and local debug is not enabled, apm will be disabled.
 */
const apmIsEnabled = (() => {
  return (
    ['pre-production', 'production'].includes(env.toLowerCase()) ||
    localDebugEnabled
  );
})();

const productionConfig: AgentConfigOptions = {
  transactionSampleRate: 0.1,
  ignoreTransactions: [
    '/api/status/healthz,/api/status/healthz/live,/api/status/healthz/ready,/api/v2.0/prometheus',
  ],
  centralConfig: false,
};

const sanitateEnvironment = (env: Environment) => {
  if (env === 'production') return 'prod';

  return env;
};

/**
 * handles apm config per environment or development mode.
 */
const initApmOptions = (() => {
  const defaultOptions = {
    centralConfig: true,
    serverUrl: apmServerUrl,
    serviceName: 'Sitecore Cloud Portal UI - FE',
    environment: sanitateEnvironment(env.toLowerCase()),
  };

  // if local debug is not enabled apply pre-prod/prod configuration options.
  if (!localDebugEnabled) {
    Object.assign(defaultOptions, productionConfig);
  }

  return defaultOptions;
})();

/**
 * Setup APM for the application.
 */
export function setupApm() {
  if (!apmIsEnabled || !apmServerUrl) {
    return;
  }

  const apm = initApm(initApmOptions);

  const newTransactionNames = new Map<Transaction, string>();

  apm.observe('transaction:start', (transaction) => {
    if (transaction.type === 'user-interaction') {
      const { activeElement } = document;

      if (!activeElement) {
        return;
      }

      const label = (activeElement as HTMLInputElement).labels?.length
        ? (activeElement as HTMLInputElement).labels?.item(0).innerText ?? ''
        : activeElement?.getAttribute('aria-label') ??
          activeElement?.getAttribute('title') ??
          (activeElement as HTMLElement)?.innerText?.substring(0, 100) ??
          '';

      const technicalName = activeElement?.getAttribute('data-testid') ?? '';
      const newUserInteractionName =
        label && technicalName
          ? `${label} (${technicalName})`
          : label || technicalName;

      newTransactionNames.set(transaction, newUserInteractionName);
    }
  });

  apm.observe('transaction:end', (transaction) => {
    const newTransactionName = newTransactionNames.get(transaction);
    newTransactionNames.delete(transaction);

    // Transaction types can change over time (i.e. start as user-interaction, end as route-change)
    if (newTransactionName && transaction.type === 'user-interaction') {
      transaction.name = newTransactionName;
    }
  });
}
