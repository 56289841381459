/* eslint-disable no-console */
import { useState } from 'react';
import {
  Button,
  Stack,
  Wrap,
  WrapItem,
  Skeleton,
  SkeletonCircle,
  Heading,
  Text,
  Box,
  useToast,
  Card,
  CardBody,
  Avatar,
  Center,
  Image,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { ProfileDetailsList } from './ProfileDetailsList';
import { ProfileEditDetails } from './ProfileEditDetails';
import { mdiCircleSmall } from '@mdi/js';
import {
  useAppAccess,
  OrgAppAccess,
} from 'features/common/components/OrganizationAppAccess';
import { resetPassword } from '../../../domains/auth0/services/user';
import { t } from '@transifex/native';
import useSetDocumentTitle from 'features/common/hooks/useSetDocumentTitle';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { useGetCurrentUserQuery } from 'gql/graphql';
import { useAuth0 } from '@auth0/auth0-react';

export const ProfileManage = () => {
  const [showEditDetails, setShowEditDetails] = useState(false);
  const toast = useToast();
  useSetDocumentTitle();

  const {
    hasOrg,
    userRolesAreLoading,
    hasError: hasUserRolesError,
  } = useAppAccess();
  const { loading: userIsLoading, data, error } = useGetCurrentUserQuery();
  const hasError = error || hasUserRolesError;

  const toggleEditDetails = () => {
    setShowEditDetails(!showEditDetails);
  };

  const onResetPassword = async () => {
    try {
      const email = data?.user?.email;
      if (!email) {
        throw new Error('No email is provided');
      }
      await resetPassword(email);
      toast({
        description: t('Check your email to complete the password reset.'),
        status: 'success',
      });
    } catch (e) {
      toast({
        description: 'Could not reset your password.',
        status: 'error',
      });
    }
  };

  const userData = data?.user;
  const name =
    (userData?.givenName || userData?.familyName) &&
    `${userData.givenName} ${userData?.familyName}`;
  const userHasRestrictions = userData?.isExternal;

  // this is used only to get the avatar from auth0
  const { user } = useAuth0();

  return (
    <>
      <Box data-testid='header' background='chakra-subtle-bg'>
        <Box
          maxW='container.xl'
          margin='auto'
          display='flex'
          alignItems='center'
          padding='10'
        >
          {userIsLoading ? (
            <SkeletonCircle mr='10' boxSize='24' />
          ) : (
            <Avatar
              size='xl'
              mr='10'
              src={user?.picture}
              // if the name is undefined we dont pass the name property and the chakra component will render the fallback icon
              {...(name && { name: name })}
            />
          )}
          <Stack direction='column'>
            <Skeleton isLoaded={!userIsLoading}>
              <Heading as='h1' data-testid='user-profile-header'>
                {!name ? userData?.email : name}
              </Heading>
            </Skeleton>

            <Skeleton isLoaded={!userIsLoading}>
              <Text variant='subtle'>
                {name && userData?.email}
                {userData?.jobRole && (
                  <>
                    {name && (
                      <PortalIcon
                        data-testid='mdi-circle-icon'
                        path={mdiCircleSmall}
                        color='inherit'
                      />
                    )}
                    {userData?.jobRole}
                  </>
                )}
              </Text>
            </Skeleton>
          </Stack>
        </Box>
      </Box>
      <Wrap
        maxW='container.xl'
        layerStyle='section.main'
        data-testid='wrap'
        margin='auto'
        spacing={10}
      >
        <WrapItem>
          <Box>
            <Card mb={4} w='xs' variant='outline'>
              <CardBody>
                <Heading variant='section' mb='6'>
                  <T _str='Details' />
                </Heading>
                {/*@ts-ignore*/}
                <ProfileDetailsList {...userData} isLoading={userIsLoading} />
                {!userHasRestrictions && (
                  <Skeleton isLoaded={!userIsLoading} mt='8'>
                    <Button
                      onClick={toggleEditDetails}
                      variant='outline'
                      width='full'
                      data-behavior-analytics-id='Edit details - button'
                    >
                      <T _str='Edit details' />
                    </Button>
                  </Skeleton>
                )}
              </CardBody>
            </Card>
            {!userHasRestrictions && (
              <Card w='xs' variant='outline'>
                <CardBody>
                  <Heading variant='section' mb='4'>
                    <T _str='Security' />
                  </Heading>
                  <Skeleton isLoaded={!userIsLoading} mt='8'>
                    <Button
                      onClick={onResetPassword}
                      variant='outline'
                      width='full'
                      data-testid={'reset-password'}
                      data-behavior-analytics-id='Reset password'
                    >
                      <T _str='Reset password' />
                    </Button>
                  </Skeleton>
                </CardBody>
              </Card>
            )}
          </Box>
        </WrapItem>
        {hasOrg && !hasError && (
          <WrapItem flex='1' width='full'>
            <Box flex={1}>
              <Skeleton isLoaded={!userRolesAreLoading} mt='2.5'>
                <OrgAppAccess />
              </Skeleton>
            </Box>
          </WrapItem>
        )}
        {hasOrg && hasError && (
          <WrapItem
            flex='1'
            width='full'
            justifyContent='center'
            alignItems='center'
          >
            <Center>
              <Stack align='center' textAlign='center' spacing='4' maxW='sm'>
                <Image
                  boxSize='16'
                  src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle'
                  alt='alert icon'
                />
                <Stack>
                  <Heading size='md'>Something went wrong</Heading>
                </Stack>
              </Stack>
            </Center>
          </WrapItem>
        )}
      </Wrap>
      <ProfileEditDetails
        isOpen={showEditDetails}
        onClose={toggleEditDetails}
        userData={userData}
      />
    </>
  );
};
