import React from 'react';
import { Heading } from '@chakra-ui/react';
import { LayoutAside } from 'features/common/components/Layouts';
import { T } from '@transifex/react';
import { NavigationVertical } from 'features/common/components/Navigation';
import {
  useGetUserManagementMenuItems,
  useGetorganizationManagementMenuItems,
} from 'features/common/hooks/navigationMenu/useGetNavigationMenuItems';

const AdminSideNav = () => {
  const { userManagementMenuItems } = useGetUserManagementMenuItems();
  const { organizationManagementMenuItems } =
    useGetorganizationManagementMenuItems();

  return (
    <LayoutAside order={1}>
      <Heading variant='section' noOfLines={1} wordBreak='break-all' mb='4'>
        <T _str='User management' />
      </Heading>
      {userManagementMenuItems && (
        <NavigationVertical items={userManagementMenuItems} />
      )}
      {organizationManagementMenuItems &&
        Boolean(organizationManagementMenuItems.length) && (
          <Heading
            variant='section'
            noOfLines={1}
            wordBreak='break-all'
            mb='4'
            mt='6'
          >
            <T _str='Organization management' />
          </Heading>
        )}
      {organizationManagementMenuItems && (
        <NavigationVertical items={organizationManagementMenuItems} />
      )}
    </LayoutAside>
  );
};

export default AdminSideNav;
