import {
  getSnowBaseUrl,
  env as environment,
  getFeatureFlagBaseUrl,
} from './envConfig';

const snowBaseUrl = getSnowBaseUrl();
const featureFlagsUrl = getFeatureFlagBaseUrl();

const snow = {
  create_ticket: {
    scope: '',
    url: `${snowBaseUrl}/sc_dxp_integration/supportcases`,
  },
};

const toAzureFeatureLabel = (envName: string) => {
  switch (envName) {
    case 'dev':
      return 'dev';
    case 'qa':
      return 'qa';
    case 'staging':
      return 'staging';
    case 'pre-production':
      return 'preprod';
    case 'production':
    default:
      return 'prod';
  }
};

const featureFlags = {
  scope: 'featureflags',
  url: `${featureFlagsUrl}/flags?key=portal&labelfilter=${toAzureFeatureLabel(
    environment.toLowerCase(),
  )}`,
};

export default {
  snow,
  featureFlags,
};

/*
    client side config, not to be used for sensitive data
*/
