import {
  Heading,
  VStack,
  Box,
  Grid,
  GridItem,
  useMediaQuery,
  Alert,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/react';
import { useMemo, useEffect, useState } from 'react';
import { useGetCurrentUser } from '../../../profile/services/identity/user';
import { UserHasOrgs } from 'features/common/components/OrganizationAppAccess';
import {
  AdminOwnerWrapper,
  UserHasNoOrgs,
} from '../../../common/components/OrganizationAppAccess/utils';
import QuickLinksPanel from './components/QuickLinksPanel';
import { useMainState } from 'features/main/context';
import { LocalStorageValue } from 'features/common/utils/localStorage';
import { useHomepageUtils } from './utils/utils';
import { TenantsPanel } from './components/TenantsPanel';
import { NewFeatureAnnouncementModal } from './components/NewFeatureAnnouncementModal';
import { Feature } from 'lib/featureFlags/features';
import sitecoreTheme from '@sitecore/blok-theme';
import QuickLinksPanelNoOrg from 'features/home/components/homepage/components/QuickLinksPanelNoOrg';
import TryApps from '../../../try-apps/TryApps';
import { PersonalizeCookiePolicyModal } from './components/PersonalizeCookiePolicyModal';
import Recommendations from 'features/recommendations/Recommendations';
import isNil from 'lodash/isNil';

export const HomePage = () => {
  const state = useMainState();
  const { data: userData, isLoading } = useGetCurrentUser();
  const identifierForUserAtLocalStorage = userData?.data.id || '';

  const userSettingsLocalStorage = LocalStorageValue.getInstance(
    identifierForUserAtLocalStorage,
  );

  const { generateKeyForBannerDismissal } = useHomepageUtils();

  const newFeatureAnnouncementModalLabel = generateKeyForBannerDismissal(
    'newFeatureAnnouncementModal',
  );

  const newFeatureModalIsDismissed = Boolean(
    userSettingsLocalStorage.get(newFeatureAnnouncementModalLabel) || false,
  );

  const [isNewFeatureModalDismissed, setNewFeatureModalDismissed] =
    useState<boolean>(newFeatureModalIsDismissed);

  useEffect(() => {
    setNewFeatureModalDismissed(newFeatureModalIsDismissed);
  }, [newFeatureModalIsDismissed]);

  const personalizedCookiePolicyModalLabel =
    'isPersonalizedCookiePolicyModalDismissed';

  const personalizedCookiePolicyModalIsDismissed = useMemo(() => {
    // if user data is still loading dont display the cookie modal policy
    // keep this check here because the storage key needs the user id
    if (isLoading) return true;

    const isModalDismissed = userSettingsLocalStorage.get(
      personalizedCookiePolicyModalLabel,
    );

    // if its null or undefined then there is no local storage entry
    if (isNil(isModalDismissed)) return false;

    return Boolean(isModalDismissed);
  }, [userSettingsLocalStorage, isLoading]);

  const [
    isPersonalizedCookiePolicyDismissed,
    setPersonalizedCookiePolicyDismissed,
  ] = useState<boolean>(personalizedCookiePolicyModalIsDismissed);

  useEffect(() => {
    setPersonalizedCookiePolicyDismissed(
      personalizedCookiePolicyModalIsDismissed,
    );
  }, [personalizedCookiePolicyModalIsDismissed]);

  const onPersonalizeCloseHandler = () => {
    setPersonalizedCookiePolicyDismissed(true);
    userSettingsLocalStorage.set(personalizedCookiePolicyModalLabel, true);
  };

  const [isBiggerThanXL] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.xl})`,
  );

  return (
    <Box
      h='full'
      overflow='auto'
      layerStyle='section.main'
      bg='chakra-subtle-bg'
    >
      <Grid
        templateAreas={isBiggerThanXL ? '"main side"' : '"main" "side"'}
        templateColumns={
          isBiggerThanXL
            ? `1fr minmax(${sitecoreTheme.sizes.sm}, ${sitecoreTheme.sizes.sm})`
            : '1fr'
        }
        gap={10}
      >
        <GridItem area={'main'}>
          <VStack spacing={5} alignItems={'start'}>
            {userData?.data?.givenName && (
              <>
                <Heading size='xl' mb={4}>
                  Welcome, {userData.data.givenName}
                </Heading>
              </>
            )}
            <UserHasNoOrgs>
              <Alert status='error' data-testid='no-part-of-org-alert'>
                <AlertIcon />
                <AlertDescription aria-label='Alert'>
                  You are not currently in an organization.
                </AlertDescription>
              </Alert>
            </UserHasNoOrgs>
            <UserHasOrgs>
              {state.env?.ORG_USER &&
                state.env?.WITH_NO_TENANTS &&
                // @ts-ignore
                // TODO Need to update the type on singular part, when PORTAL-2488 will be merged
                !state.env?.isLoading && (
                  <Alert status='warning'>
                    <AlertIcon />
                    <AlertDescription aria-label='Alert'>
                      You do not have access to any apps. Contact your
                      organization administrator.
                    </AlertDescription>
                  </Alert>
                )}
            </UserHasOrgs>
            <UserHasOrgs>
              <Feature name='RECOMMENDATIONS'>
                <Recommendations />
                <PersonalizeCookiePolicyModal
                  isOpen={!isPersonalizedCookiePolicyDismissed}
                  onClose={onPersonalizeCloseHandler}
                />
              </Feature>
              <TenantsPanel />
              <Feature name='PROVISION_APPS'>
                <AdminOwnerWrapper>
                  <TryApps />
                </AdminOwnerWrapper>
              </Feature>
            </UserHasOrgs>
          </VStack>
        </GridItem>
        <GridItem area={'side'}>
          <VStack spacing='4'>
            <UserHasOrgs>
              <QuickLinksPanel />
            </UserHasOrgs>
            <UserHasNoOrgs>
              <QuickLinksPanelNoOrg />
            </UserHasNoOrgs>
          </VStack>
        </GridItem>
      </Grid>
      <Feature name={'NEW_FEATURE_ANNOUNCEMENT'}>
        {!isLoading && state.env?.ORG_ADMIN_OR_OWNER && (
          <NewFeatureAnnouncementModal
            isOpen={!isNewFeatureModalDismissed}
            onClose={() => {
              setNewFeatureModalDismissed(true);
              userSettingsLocalStorage.set(
                newFeatureAnnouncementModalLabel,
                true,
              );
            }}
          />
        )}
      </Feature>
    </Box>
  );
};
