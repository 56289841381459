import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  MultiplierPath: { input: any; output: any };
};

/** The account ID. */
export type AccountIdFilterInput = {
  and?: InputMaybe<Array<AccountIdFilterInput>>;
  or?: InputMaybe<Array<AccountIdFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Defines an Action. */
export type Action = {
  __typename?: 'Action';
  /**
   * The category of the Action. There are following categories at the moment
   *
   *     Direct Links
   *     Quick Actions
   *     Helpful links
   *     Button
   */
  category?: Maybe<Scalars['String']['output']>;
  /**
   * The color, which is defined in the SDS and defines the icon's color and background color of the quick action.
   * (only the colors that have 100 and 600 variations from SDS are supported.
   */
  color: Scalars['String']['output'];
  /**
   * The description of the Action. Currently, it is applicable to Direct Link
   * Category.
   */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** The icon. */
  icon: Icon;
  /** The Action's link. */
  link: Link;
  /** The panel(s), where the Action should be shown. */
  location?: Maybe<Array<Scalars['String']['output']>>;
  /** The Action title/name. */
  name: Scalars['String']['output'];
};

/**
 * Defines an action category. Currently, there are the following categories
 *
 *         Direct Links
 *         Quick Actions
 *         Helpful links
 *         Button
 *         Portal Actions
 */
export type ActionCategoryFilterInput = {
  and?: InputMaybe<Array<ActionCategoryFilterInput>>;
  or?: InputMaybe<Array<ActionCategoryFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Defines an Action. */
export type ActionFilterInput = {
  and?: InputMaybe<Array<ActionFilterInput>>;
  /**
   * The category of the Action. There are following categories at the moment
   *
   *     Direct Links
   *     Quick Actions
   *     Helpful links
   *     Button
   */
  category?: InputMaybe<ActionCategoryFilterInput>;
  /**
   * The color, which is defined in the SDS and defines the icon's color and background color of the quick action.
   * (only the colors that have 100 and 600 variations from SDS are supported.
   */
  color?: InputMaybe<ColorFilterInput>;
  /**
   * The description of the Action. Currently, it is applicable to Direct Link
   * Category.
   */
  description?: InputMaybe<StringOperationFilterInput>;
  /** The display name. */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** The icon. */
  icon?: InputMaybe<IconFilterInput>;
  /** The Action's link. */
  link?: InputMaybe<LinkFilterInput>;
  /** The panel(s), where the Action should be shown. */
  location?: InputMaybe<ListStringOperationFilterInput>;
  /** The Action title/name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ActionFilterInput>>;
};

/** A connection to a list of items. */
export type ActionsConnection = {
  __typename?: 'ActionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActionsEdge = {
  __typename?: 'ActionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Action;
};

/** Represents an error occurring on an attempt to activate a connection, when there were invalid input parameters. */
export type ActivateConnectionBadRequestError = Error & {
  __typename?: 'ActivateConnectionBadRequestError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Represents an error occurring on an attempt to activate a connection, when the connection cannot be activated. */
export type ActivateConnectionConflictError = Error & {
  __typename?: 'ActivateConnectionConflictError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type ActivateConnectionError =
  | ActivateConnectionBadRequestError
  | ActivateConnectionConflictError
  | ActivateConnectionNotFoundError
  | UnknownError;

export type ActivateConnectionInput = {
  /** The connection ID. */
  id: Scalars['String']['input'];
};

/** Represents an error occurring on an attempt to activate a connection, when the connection is not found by its ID. */
export type ActivateConnectionNotFoundError = Error & {
  __typename?: 'ActivateConnectionNotFoundError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type ActivateConnectionPayload = {
  __typename?: 'ActivateConnectionPayload';
  connection?: Maybe<VerifiedConnection>;
  errors?: Maybe<Array<ActivateConnectionError>>;
};

/** Defines Application ID. */
export type ApplicationIdFilterInput = {
  and?: InputMaybe<Array<ApplicationIdFilterInput>>;
  or?: InputMaybe<Array<ApplicationIdFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type ApplicationsConnection = {
  __typename?: 'ApplicationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrganizationApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApplicationsEdge = {
  __typename?: 'ApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OrganizationApplication;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION',
}

/** A connection to a list of items. */
export type AssignedRolesConnection = {
  __typename?: 'AssignedRolesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AssignedRolesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserApplicationRole>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssignedRolesEdge = {
  __typename?: 'AssignedRolesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserApplicationRole;
};

/** The error thrown when the request could not be processed due to incorrect input parameters. */
export type BadRequestError = Error & {
  __typename?: 'BadRequestError';
  /** The details of the validation errors. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

/** Defines a color. */
export type ColorFilterInput = {
  and?: InputMaybe<Array<ColorFilterInput>>;
  or?: InputMaybe<Array<ColorFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** The error thrown when the request could not be carried out because of a conflict on the server. */
export type ConflictError = Error & {
  __typename?: 'ConflictError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The Connect product to provision. */
export type ConnectProductInput = {
  /** Indicates whether audit logs should be enabled for the tenant. */
  auditLogsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The display name of the tenant. */
  displayName: Scalars['String']['input'];
  /** The name of the tenant. */
  name: Scalars['String']['input'];
  /** The deployment region id. */
  regionId: Scalars['String']['input'];
};

/** Defines a connection. */
export type Connection = {
  __typename?: 'Connection';
  /** The connection account ID. */
  accountId?: Maybe<Scalars['String']['output']>;
  /** Gets the connection appearance data. */
  appearance: ConnectionAppearance;
  /** The creation date of the connection. */
  created: Scalars['DateTime']['output'];
  /** The connection display name. */
  displayName: Scalars['String']['output'];
  /** The connection email domain. */
  emailDomain: Scalars['String']['output'];
  /** The connection ID. */
  id: Scalars['String']['output'];
  /** The connection name. */
  name: Scalars['String']['output'];
  /** The connection organization ID. */
  organizationId: Scalars['String']['output'];
  /** The connection state. */
  state: ConnectionState;
  /** The connection strategy. */
  strategy: ConnectionStrategy;
  /** The flag indicating whether the email domain ownership has been verified successfully. */
  verified: Scalars['Boolean']['output'];
};

/** Represents an action to perform over a Connection. */
export type ConnectionAction = {
  __typename?: 'ConnectionAction';
  /** The action display name. */
  displayName: Scalars['String']['output'];
  /** The icon. */
  icon?: Maybe<Icon>;
  /** The action link. */
  link: Link;
  /** The action name. */
  name: Scalars['String']['output'];
};

/** Represents data related to Connection appearance. */
export type ConnectionAppearance = {
  __typename?: 'ConnectionAppearance';
  /** The connection actions. */
  actions: Array<ConnectionAction>;
};

/** The connection option interface. */
export type ConnectionOptions = OidcConnectionOptions | SamlpConnectionOptions;

/** The connection state. */
export enum ConnectionState {
  /** The ACTIVATING state. */
  Activating = 'ACTIVATING',
  /** The ACTIVE state. */
  Active = 'ACTIVE',
  /** The INACTIVE state. */
  Inactive = 'INACTIVE',
  /** The VERIFYING state. */
  Verifying = 'VERIFYING',
}

/** The connection strategy. */
export enum ConnectionStrategy {
  /** The OIDC strategy. */
  Oidc = 'OIDC',
  /** The SAMLP strategy. */
  Samlp = 'SAMLP',
}

/** Defines the connection verification details. */
export type ConnectionVerification = {
  __typename?: 'ConnectionVerification';
  methods: Array<ConnectionVerificationMethod>;
};

/** Defines the connection verification method. */
export type ConnectionVerificationMethod = {
  __typename?: 'ConnectionVerificationMethod';
  domain: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  record: Scalars['String']['output'];
};

/** A segment of a collection. */
export type ConnectionsCollectionSegment = {
  __typename?: 'ConnectionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Connection>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Defines consumption details for an entitlement's metric. */
export type Consumption = {
  __typename?: 'Consumption';
  /** The current consumption period. */
  currentPeriod: ConsumptionPeriod;
};

/** Defines a consumption period. */
export type ConsumptionPeriod = {
  __typename?: 'ConsumptionPeriod';
  /** The date, when the consumption period ends. */
  endDate: Scalars['Date']['output'];
  /** The date, when the consumption period starts. */
  startDate: Scalars['Date']['output'];
  /** A collection of usages in the period. */
  usages: Array<Usage>;
};

/** Personifies the current user and gives access to their data. */
export type ContextUser = {
  __typename?: 'ContextUser';
  /**
   * Gets a paginated collection of applications available to the user.
   *
   *
   * **Returns:**
   * An async task, which result is a collection of user applications.
   */
  applications?: Maybe<ContextUserApplicationsConnection>;
  /**
   * Gets the user's company.
   *
   *
   * **Returns:**
   * The company of the user.
   */
  company?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the created date for the user.
   *
   *
   * **Returns:**
   * The  created date of the user.
   */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** The user email. */
  email: Scalars['String']['output'];
  /**
   * Gets the email verified flag for the user.
   *
   *
   * **Returns:**
   * The email verified flag of the user.
   */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Gets the user's family name.
   *
   *
   * **Returns:**
   * The family name of the user.
   */
  familyName?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the user's given name.
   *
   *
   * **Returns:**
   * The given name of the user.
   */
  givenName?: Maybe<Scalars['String']['output']>;
  /** The user ID. */
  id: Scalars['String']['output'];
  /**
   * Gets the is external flag for the user.
   *
   *
   * **Returns:**
   * The is external flag of the user.
   */
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Gets the user's job role.
   *
   *
   * **Returns:**
   * The job role of the user.
   */
  jobRole?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the last login date for the user.
   *
   *
   * **Returns:**
   * The last login date of the user.
   */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Gets the user's nickname.
   *
   *
   * **Returns:**
   * The nickname of the user.
   */
  nickName?: Maybe<Scalars['String']['output']>;
  /**
   * Gets a paginated collection of user roles in the organization
   * sorted based on priority Organization Owner, Organization Admin, Organization User.
   *
   *
   * **Returns:**
   * The list of roles.
   */
  organizationRoles?: Maybe<OrganizationRolesConnection>;
  /**
   * Gets a paginated collection of organizations the context user is a member of.
   *
   *
   * **Returns:**
   * A collection of the user's organizations.
   */
  organizations?: Maybe<OrganizationsCollectionSegment>;
  /**
   * Gets the user's phone number.
   *
   *
   * **Returns:**
   * The phone number of the user.
   */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the user's picture.
   *
   *
   * **Returns:**
   * The picture of the user.
   */
  picture?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the user's preferred language.
   *
   *
   * **Returns:**
   * The preferred language of the user.
   */
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the sign up completed flag for the user.
   *
   *
   * **Returns:**
   * The sign up completed flag of the user.
   */
  signUpCompleted?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Gets the updated date for the user.
   *
   *
   * **Returns:**
   * The updated date of the user.
   */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

/** Personifies the current user and gives access to their data. */
export type ContextUserApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContextUserApplicationFilterInput>;
};

/** Personifies the current user and gives access to their data. */
export type ContextUserOrganizationRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Personifies the current user and gives access to their data. */
export type ContextUserOrganizationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines an application available for the context user. */
export type ContextUserApplication = {
  __typename?: 'ContextUserApplication';
  /**
   * Gets the User Application appearance.
   *
   *
   * **Returns:**
   * User Application appearance.
   */
  appearance?: Maybe<UserApplicationAppearance>;
  /**
   * Gets a paginated collection of user roles in the application.
   *
   *
   * **Returns:**
   * The list of roles.
   */
  assignedRoles?: Maybe<AssignedRolesConnection>;
  /** The display name of the application. */
  displayName: Scalars['String']['output'];
  /** The application ID. */
  id: Scalars['String']['output'];
  /** The application labels, returned from Inventory services. */
  labels: Array<KeyValuePairOfStringAndString>;
  /** The application name. */
  name: Scalars['String']['output'];
  /** The organization ID the application belongs to. */
  organizationId: Scalars['String']['output'];
  /**
   * Gets the product the application belongs to.
   *
   *
   * **Returns:**
   * An async task, which result is an instance of product.
   */
  product?: Maybe<Product>;
  /** The product code of provisioned application. */
  productCode: Scalars['String']['output'];
  /** The application state. */
  state: Scalars['String']['output'];
  /** The system ID, in scope of which the application has been provisioned. */
  systemId: Scalars['String']['output'];
};

/** Defines an application available for the context user. */
export type ContextUserApplicationAssignedRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines an application available for the context user. */
export type ContextUserApplicationFilterInput = {
  and?: InputMaybe<Array<ContextUserApplicationFilterInput>>;
  /** Gets the application roles assigned to the user. */
  assignedRoles?: InputMaybe<ListFilterInputTypeOfUserApplicationRoleFilterInput>;
  /** The display name of the application. */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** The application ID. */
  id?: InputMaybe<ApplicationIdFilterInput>;
  /** The application labels, returned from Inventory services. */
  labels?: InputMaybe<ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput>;
  /** The application name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContextUserApplicationFilterInput>>;
  /** The organization ID the application belongs to. */
  organizationId?: InputMaybe<OrganizationIdFilterInput>;
  /** The product code of provisioned application. */
  productCode?: InputMaybe<ProductCodeFilterInput>;
  /** The application state. */
  state?: InputMaybe<StringOperationFilterInput>;
  /** The system ID, in scope of which the application has been provisioned. */
  systemId?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type ContextUserApplicationsConnection = {
  __typename?: 'ContextUserApplicationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContextUserApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ContextUserApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContextUserApplicationsEdge = {
  __typename?: 'ContextUserApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ContextUserApplication;
};

/**
 * An error occurring on an attempt to update the current user, and the user cannot be updated.
 * For instance, the user is external.
 */
export type ContextUserCannotBeUpdatedError = Error & {
  __typename?: 'ContextUserCannotBeUpdatedError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Personifies the current user and gives access to their data. */
export type ContextUserDetails = {
  __typename?: 'ContextUserDetails';
  /** Company of the user. */
  company?: Maybe<Scalars['String']['output']>;
  /** Created date of the user. */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Boolean flag indicating whether email of the user has been verified. */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Family name of the user. */
  familyName?: Maybe<Scalars['String']['output']>;
  /** Given name of the user. */
  givenName?: Maybe<Scalars['String']['output']>;
  /** The user ID. */
  id: Scalars['String']['output'];
  /** Boolean flag indicating whether user is created using external provider. */
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  /** Job role of the user. */
  jobRole?: Maybe<Scalars['String']['output']>;
  /** Last login date of the user. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** Nick name of the user. */
  nickName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the user. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Picture of the user. */
  picture?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the user. */
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  /** Boolean flag indicating whether sign-up of the user has been completed. */
  signUpCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** Updated date of the user. */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

/** An error occurring on an attempt to update the current user, and the user is not found. */
export type ContextUserNotFoundError = Error & {
  __typename?: 'ContextUserNotFoundError';
  /** The message. */
  message: Scalars['String']['output'];
};

/** An unknown error that may appear during the current user update process. */
export type ContextUserUpdateUnknownError = Error & {
  __typename?: 'ContextUserUpdateUnknownError';
  /**
   * The message that may provide additional details about the error.
   * Most likely it will be either empty or contain an HTTP status code from Identity API.
   */
  message: Scalars['String']['output'];
};

/** An error occurring on the current user update, when one or more fields to update had invalid values. */
export type ContextUserUpdateValidationError = Error & {
  __typename?: 'ContextUserUpdateValidationError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** An error occurring on SSO connection creation, when there is already an existing verified connection with the same email domain. */
export type CreateConnectionConflictError = Error & {
  __typename?: 'CreateConnectionConflictError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type CreateConnectionError =
  | CreateConnectionConflictError
  | CreateConnectionValidationError;

/** The input object to create a new SSO connection from. */
export type CreateConnectionInput = {
  /** The display name of the SSO connection. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The email domain of users belonging to the external SSO provider. */
  emailDomain: Scalars['String']['input'];
  /** The options for the connection strategy. */
  options: CreateConnectionOptionsInput;
};

/** The connection options of one of the supported connection strategy types. */
export type CreateConnectionOptionsInput = {
  /** The options for OIDC strategy. */
  oidc?: InputMaybe<OidcOptionsInput>;
  /** The options for SAMLP strategy. */
  samlp?: InputMaybe<SamlpOptionsInput>;
};

export type CreateConnectionPayload = {
  __typename?: 'CreateConnectionPayload';
  connection?: Maybe<NonVerifiedConnection>;
  errors?: Maybe<Array<CreateConnectionError>>;
};

/** An error occurring on SSO connection creation, when one or more input fields have invalid values. */
export type CreateConnectionValidationError = Error & {
  __typename?: 'CreateConnectionValidationError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

/** An error occurring on deleting an organization connection by its ID due to invalid input parameters. */
export type DeleteConnectionBadRequestError = Error & {
  __typename?: 'DeleteConnectionBadRequestError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export type DeleteConnectionError =
  | DeleteConnectionBadRequestError
  | DeleteConnectionNotFoundError;

export type DeleteConnectionInput = {
  /** The ID of the organization connection to delete. */
  id: Scalars['String']['input'];
};

/** An error occurring on deleting an organization connection, when the connection ID was not found. */
export type DeleteConnectionNotFoundError = Error & {
  __typename?: 'DeleteConnectionNotFoundError';
  /** The ID of the connection to delete. */
  id: Scalars['String']['output'];
  /** The error message. */
  message: Scalars['String']['output'];
};

export type DeleteConnectionPayload = {
  __typename?: 'DeleteConnectionPayload';
  errors?: Maybe<Array<DeleteConnectionError>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type DeleteInvitationError = BadRequestError | NotFoundError;

export type DeleteInvitationInput = {
  /** The ID of the invitation to delete. */
  invitationId: Scalars['String']['input'];
};

export type DeleteInvitationPayload = {
  __typename?: 'DeleteInvitationPayload';
  errors?: Maybe<Array<DeleteInvitationError>>;
  invitationId?: Maybe<Scalars['String']['output']>;
};

/** Defines a deployment region. */
export type DeploymentRegion = {
  __typename?: 'DeploymentRegion';
  /** The description of the deployment region. */
  description: Scalars['String']['output'];
  /** The ID of the deployment region. */
  id: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type DeploymentRegionsConnection = {
  __typename?: 'DeploymentRegionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DeploymentRegionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DeploymentRegion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DeploymentRegionsEdge = {
  __typename?: 'DeploymentRegionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DeploymentRegion;
};

/** The detailed connection interface. */
export type DetailedConnection = NonVerifiedConnection | VerifiedConnection;

/** Defines an Entitlement of a Subscription. */
export type Entitlement = {
  __typename?: 'Entitlement';
  /** Gets the consumption for the entitlement. */
  consumption?: Maybe<Consumption>;
  /** The entitlement's end date within the contract. */
  endDate: Scalars['Date']['output'];
  /**
   * Gets the last reported DateTime for entitlement.
   *
   *
   * **Returns:**
   * Last update date of the metric.
   */
  lastReportedDate?: Maybe<Scalars['DateTime']['output']>;
  /** The metric limit type corresponding to the entitlement. */
  limitType: EntitlementLimitType;
  /** The metric name. */
  metric: Scalars['String']['output'];
  /** The name of the entitlement. */
  name: Scalars['String']['output'];
  /** The period type. */
  periodType: EntitlementPeriod;
  /** The quantity. */
  quantity?: Maybe<Scalars['Decimal']['output']>;
  /** The entitlement's start date within the contract. */
  startDate: Scalars['Date']['output'];
};

/** Defines an Entitlement of a Subscription. */
export type EntitlementFilterInput = {
  /** The account ID. */
  accountId?: InputMaybe<AccountIdFilterInput>;
  and?: InputMaybe<Array<EntitlementFilterInput>>;
  /** The entitlement's end date within the contract. */
  endDate?: InputMaybe<DateOperationFilterInput>;
  /** The metric limit type corresponding to the entitlement. */
  limitType?: InputMaybe<EntitlementLimitTypeOperationFilterInput>;
  /** The metric name. */
  metric?: InputMaybe<StringOperationFilterInput>;
  /** The name of the entitlement. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntitlementFilterInput>>;
  /** The entitlement's period end date. */
  periodEndDate?: InputMaybe<DateOperationFilterInput>;
  /** The entitlement's period start date. */
  periodStartDate?: InputMaybe<DateOperationFilterInput>;
  /** The period type. */
  periodType?: InputMaybe<EntitlementPeriodOperationFilterInput>;
  /** The quantity. */
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  /** The sellable product code. */
  sellableProductCode?: InputMaybe<SellableProductCodeFilterInput>;
  /** The entitlement's start date within the contract. */
  startDate?: InputMaybe<DateOperationFilterInput>;
  /** The Subscription ID. */
  subscriptionId?: InputMaybe<SubscriptionIdFilterInput>;
};

/** Defines the limit type of the metric represented by the entitlement. */
export enum EntitlementLimitType {
  /** The metric type with a hard limit, which cannot be exceeded. */
  Hard = 'HARD',
  /** The metric type with a soft limit, i.e. there can be overage of the metric. */
  Overage = 'OVERAGE',
  /** The metric type with a soft limit. */
  Soft = 'SOFT',
  /** The metric with no limits. */
  Unlimited = 'UNLIMITED',
}

export type EntitlementLimitTypeOperationFilterInput = {
  eq?: InputMaybe<EntitlementLimitType>;
  in?: InputMaybe<Array<EntitlementLimitType>>;
  neq?: InputMaybe<EntitlementLimitType>;
  nin?: InputMaybe<Array<EntitlementLimitType>>;
};

/** Defines a period type of an Entitlement in a Subscription. */
export enum EntitlementPeriod {
  /** The entitlement's period is a month. */
  Month = 'MONTH',
  /** The entitlement's period is a year. */
  Year = 'YEAR',
}

export type EntitlementPeriodOperationFilterInput = {
  eq?: InputMaybe<EntitlementPeriod>;
  in?: InputMaybe<Array<EntitlementPeriod>>;
  neq?: InputMaybe<EntitlementPeriod>;
  nin?: InputMaybe<Array<EntitlementPeriod>>;
};

/** A connection to a list of items. */
export type EntitlementsConnection = {
  __typename?: 'EntitlementsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EntitlementsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Entitlement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EntitlementsEdge = {
  __typename?: 'EntitlementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Entitlement;
};

export type Error = {
  message: Scalars['String']['output'];
};

/** Defines an icon. */
export type Icon = {
  __typename?: 'Icon';
  /** The source. */
  src: Scalars['String']['output'];
  /** The icon type. */
  type: Scalars['String']['output'];
};

/** Defines an icon. */
export type IconFilterInput = {
  and?: InputMaybe<Array<IconFilterInput>>;
  or?: InputMaybe<Array<IconFilterInput>>;
  /** The source. */
  src?: InputMaybe<StringOperationFilterInput>;
  /** The icon type. */
  type?: InputMaybe<IconTypeFilterInput>;
};

/** Defines icon type. */
export type IconTypeFilterInput = {
  and?: InputMaybe<Array<IconTypeFilterInput>>;
  or?: InputMaybe<Array<IconTypeFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Represents an invitation of a user to one or more roles in an organization. */
export type Invitation = {
  __typename?: 'Invitation';
  /**
   * Gets appearance data for the invitation.
   *
   *
   * **Returns:**
   * Invitation appearance data.
   */
  appearance: InvitationAppearance;
  /** The application roles to set to the invitee. */
  applicationRoles: Array<UserApplicationRole>;
  /** The date, when the invitation was created. */
  created: Scalars['DateTime']['output'];
  /** The date, when the invitation expires. */
  expires: Scalars['DateTime']['output'];
  /** The invitation ID. */
  id: Scalars['String']['output'];
  /** The invitee email. */
  inviteeEmail: Scalars['String']['output'];
  /** The inviter name. */
  inviterName: Scalars['String']['output'];
  /** The organization roles to set to the invitee. */
  organizationRoles: Array<UserOrganizationRole>;
};

/** Represents an action that can be performed on an Invitation. */
export type InvitationAction = {
  __typename?: 'InvitationAction';
  /** The action display name, i.e. user friendly name. */
  displayName: Scalars['String']['output'];
  /** The action link. */
  link: Link;
  /** The action name. */
  name: Scalars['String']['output'];
};

/** Represents data related to Invitation appearance. */
export type InvitationAppearance = {
  __typename?: 'InvitationAppearance';
  /** The collection of actions that can be performed on Invitation. */
  actions: Array<InvitationAction>;
};

/** The application specific role to assign to the invited organization member. */
export type InvitationApplicationRoleInput = {
  /** The application Id. */
  applicationId: Scalars['String']['input'];
  /** The product code of the application. */
  productCode: Scalars['String']['input'];
  /** The role name. */
  roleName: Scalars['String']['input'];
};

/** Represents an invitation of a user to one or more roles in an organization. */
export type InvitationFilterInput = {
  and?: InputMaybe<Array<InvitationFilterInput>>;
  /** The application roles to set to the invitee. */
  applicationRoles?: InputMaybe<ListFilterInputTypeOfUserApplicationRoleFilterInput>;
  /** The date, when the invitation was created. */
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The date, when the invitation expires. */
  expires?: InputMaybe<DateTimeOperationFilterInput>;
  /** The invitation ID. */
  id?: InputMaybe<InvitationIdFilterInput>;
  /** The invitee email. */
  inviteeEmail?: InputMaybe<StringOperationFilterInput>;
  /** The inviter name. */
  inviterName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InvitationFilterInput>>;
  /** The organization roles to set to the invitee. */
  organizationRoles?: InputMaybe<ListFilterInputTypeOfUserOrganizationRoleFilterInput>;
};

/** The ID of the invitation for an organization member. */
export type InvitationIdFilterInput = {
  and?: InputMaybe<Array<InvitationIdFilterInput>>;
  or?: InputMaybe<Array<InvitationIdFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** The organization role to assign to the invited organization member. */
export type InvitationOrganizationRoleInput = {
  /** The role name. */
  roleName: Scalars['String']['input'];
};

/** A segment of a collection. */
export type InvitationsCollectionSegment = {
  __typename?: 'InvitationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Invitation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type InviteOrganizationMemberError = BadRequestError | ConflictError;

/** The input for creating an organization member invitation. */
export type InviteOrganizationMemberInput = {
  /** The application specific role to assign to the invited organization member. */
  applicationRoles?: InputMaybe<Array<InvitationApplicationRoleInput>>;
  /** The email of the organization member being invited. */
  inviteeEmail: Scalars['String']['input'];
  /** The name of the organization member creating the invitation. */
  inviterName: Scalars['String']['input'];
  /** The organization role to assign to the invited organization member. */
  organizationRoles?: InputMaybe<Array<InvitationOrganizationRoleInput>>;
};

export type InviteOrganizationMemberPayload = {
  __typename?: 'InviteOrganizationMemberPayload';
  errors?: Maybe<Array<InviteOrganizationMemberError>>;
  invitation?: Maybe<Invitation>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairOfStringAndStringFilterInput = {
  and?: InputMaybe<Array<KeyValuePairOfStringAndStringFilterInput>>;
  key?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<KeyValuePairOfStringAndStringFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type KeyValuePairOfStringAndString__ = {
  __typename?: 'KeyValuePairOfStringAndString__';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type KeyValuePairOfStringAndString__Input = {
  key: Scalars['String']['input'];
  value: Array<Scalars['String']['input']>;
};

/** Defines a link to a particular resource. */
export type Link = {
  __typename?: 'Link';
  /** The path to the resource. */
  to: Scalars['String']['output'];
  /** The link type. */
  type: Scalars['String']['output'];
};

/** Defines a link to a particular resource. */
export type LinkFilterInput = {
  and?: InputMaybe<Array<LinkFilterInput>>;
  or?: InputMaybe<Array<LinkFilterInput>>;
  /** The path to the resource. */
  to?: InputMaybe<StringOperationFilterInput>;
  /** The link type. */
  type?: InputMaybe<LinkTypeFilterInput>;
};

/** Defines a link type. */
export type LinkTypeFilterInput = {
  and?: InputMaybe<Array<LinkTypeFilterInput>>;
  or?: InputMaybe<Array<LinkTypeFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfEntitlementFilterInput = {
  all?: InputMaybe<EntitlementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntitlementFilterInput>;
  some?: InputMaybe<EntitlementFilterInput>;
};

export type ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput = {
  all?: InputMaybe<KeyValuePairOfStringAndStringFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<KeyValuePairOfStringAndStringFilterInput>;
  some?: InputMaybe<KeyValuePairOfStringAndStringFilterInput>;
};

export type ListFilterInputTypeOfUserApplicationRoleFilterInput = {
  all?: InputMaybe<UserApplicationRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserApplicationRoleFilterInput>;
  some?: InputMaybe<UserApplicationRoleFilterInput>;
};

export type ListFilterInputTypeOfUserOrganizationRoleFilterInput = {
  all?: InputMaybe<UserOrganizationRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserOrganizationRoleFilterInput>;
  some?: InputMaybe<UserOrganizationRoleFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type MemberActionsConnection = {
  __typename?: 'MemberActionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MemberActionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrganizationMemberAction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MemberActionsEdge = {
  __typename?: 'MemberActionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OrganizationMemberAction;
};

/** A segment of a collection. */
export type MembersCollectionSegment = {
  __typename?: 'MembersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganizationMember>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Activates a verified SSO connection.
   *
   *
   * **Returns:**
   * A result with an activated/activating connection, in case of a success; otherwise, one of the errors.
   */
  activateConnection: ActivateConnectionPayload;
  /**
   * Creates a new organization SSO connection.
   *
   *
   * **Returns:**
   * A newly created connection or an error occurred during the creation.
   */
  createConnection: CreateConnectionPayload;
  /**
   * Deletes an organization connection.
   *
   *
   * **Returns:**
   * The deleted connection's ID or an error occurred while deleting the connection.
   */
  deleteConnection: DeleteConnectionPayload;
  /**
   * Deletes an invitation.
   *
   *
   * **Returns:**
   * The deleted invitation ID or errors faced during deletion.
   */
  deleteInvitation: DeleteInvitationPayload;
  /**
   * Invites an organization member.
   *
   *
   * **Returns:**
   * The created invitation or errors faced during creation.
   */
  inviteOrganizationMember: InviteOrganizationMemberPayload;
  /**
   * Provisions a product.
   *
   *
   * **Returns:**
   * The product that was provisioned or an error that occurred during the provisioning.
   */
  provisionProduct: ProvisionProductPayload;
  /**
   * Revokes the organization membership of the users, specified by the provided user IDs.
   *
   *
   * **Returns:**
   *
   * A mutation result containing the user IDs from the input, in case of a successful operation;
   * or an error, in case of a failure.
   */
  revokeMembership: RevokeMembershipPayload;
  /**
   * Updates an existing SSO connection of the organization.
   *
   *
   * **Returns:**
   * An updated connection or one of the possible errors occurred during the operation.
   */
  updateConnection: UpdateConnectionPayload;
  /**
   * Updates the current user details.
   *
   *
   * **Returns:**
   * Either a result of a successful operation or one of the errors.
   */
  updateContextUserDetails: UpdateContextUserDetailsPayload;
  /**
   * Updates organization member roles.
   *
   *
   * **Returns:**
   * The updated organization member roles or errors encountered during the update operation.
   */
  updateOrganizationMemberRoles: UpdateOrganizationMemberRolesPayload;
  /**
   * Verifies an organization connection.
   *
   *
   * **Returns:**
   * The verified/non-verified connection or an error occurred while verifying the connection.
   */
  verifyConnection: VerifyConnectionPayload;
};

export type MutationActivateConnectionArgs = {
  input: ActivateConnectionInput;
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationDeleteConnectionArgs = {
  input: DeleteConnectionInput;
};

export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};

export type MutationInviteOrganizationMemberArgs = {
  input: InviteOrganizationMemberInput;
};

export type MutationProvisionProductArgs = {
  input: ProvisionProductInput;
};

export type MutationRevokeMembershipArgs = {
  input: RevokeMembershipInput;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateContextUserDetailsArgs = {
  input: UpdateContextUserDetailsInput;
};

export type MutationUpdateOrganizationMemberRolesArgs = {
  input: UpdateOrganizationMemberRolesInput;
};

export type MutationVerifyConnectionArgs = {
  input: VerifyConnectionInput;
};

/** Defines a non verified connection. */
export type NonVerifiedConnection = {
  __typename?: 'NonVerifiedConnection';
  /** The connection account ID. */
  accountId?: Maybe<Scalars['String']['output']>;
  /** The creation date of the connection. */
  created: Scalars['DateTime']['output'];
  /** The connection display name. */
  displayName: Scalars['String']['output'];
  /** The connection email domain. */
  emailDomain: Scalars['String']['output'];
  /** The connection ID. */
  id: Scalars['String']['output'];
  /** The connection name. */
  name: Scalars['String']['output'];
  /** The connection options. */
  options: ConnectionOptions;
  /** The connection organization ID. */
  organizationId: Scalars['String']['output'];
  /** The connection state. */
  state: ConnectionState;
  /** The connection strategy. */
  strategy: ConnectionStrategy;
  /** The connection verification. */
  verification?: Maybe<ConnectionVerification>;
};

/** The error thrown when the requested resource does not exist on the server. */
export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The OIDC connection. */
export type OidcConnectionOptions = {
  __typename?: 'OidcConnectionOptions';
  /** The URL of the Authorization endpoint. */
  authorizationEndpoint?: Maybe<Scalars['String']['output']>;
  /** The client ID from the OIDC provider. */
  clientId?: Maybe<Scalars['String']['output']>;
  /** The URL of the Discovery endpoint. */
  discoveryUrl?: Maybe<Scalars['String']['output']>;
  /** The token issuer of OIDC provider. */
  issuer?: Maybe<Scalars['String']['output']>;
  /** The URL of the JSON Web Key Set endpoint. */
  jwksUri?: Maybe<Scalars['String']['output']>;
  /** The scope passed to OIDC provider. */
  scope?: Maybe<Scalars['String']['output']>;
  /** The URL of the Token endpoint. */
  tokenEndpoint?: Maybe<Scalars['String']['output']>;
  /** The type of communication with OIDC provider. */
  type?: Maybe<OidcType>;
  /** The URL of the User Info endpoint. */
  userInfoEndpoint?: Maybe<Scalars['String']['output']>;
};

/** The options for OIDC connection strategy. */
export type OidcOptionsInput = {
  /**
   * URL of the OAuth 2.0 Authorization endpoint. Required when discovery is off. Must
   * be a valid absolute URL.
   */
  authorizationEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of a client, registered on OIDC provider side, that will be passed to Authorization endpoint
   * of the external OIDC provider.
   */
  clientId: Scalars['String']['input'];
  /**
   * Secret of a client, registered on OIDC provider side, that will be passed to Authorization
   * endpoint of the external OIDC provider.
   */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** OpenID configuration endpoint of the external OIDC provider. Must be a valid absolute URL. */
  discoveryUrl?: InputMaybe<Scalars['String']['input']>;
  /**
   * URL using the https scheme with no query or fragment component that represents Issuer Identifier.
   * Required when discovery is off. Must be a valid absolute URL.
   */
  issuer?: InputMaybe<Scalars['String']['input']>;
  /**
   * URL of the JSON Web Key Set [JWK] document. Required when discovery is off. Must be a valid
   * absolute URL.
   */
  jwksUri?: InputMaybe<Scalars['String']['input']>;
  /** Scope to pass to the external OIDC provider. Must be space separated and contain openid. */
  scope: Scalars['String']['input'];
  /**
   * URL of the OAuth 2.0 Token endpoint. Required for BackChannel when discovery is off. Must
   * be a valid absolute URL.
   */
  tokenEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * The Channel type. Determines how the Authorization Server of SSO connection returns result
   * parameters from the Authorization Endpoint.
   */
  type: OidcType;
  /** URL of the User Info endpoint. Must be a valid absolute URL. */
  userInfoEndpoint?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The type of communication with OIDC provider.
 * Describes how the Authorization Server of SSO connection returns result parameters from the Authorization Endpoint.
 */
export enum OidcType {
  /**
   * The Back Channel type.
   * The Authorization Server will use response_type=code.
   */
  BackChannel = 'BACK_CHANNEL',
  /**
   * The Front Channel type.
   * The Authorization Server will use OIDC protocol with response_mode=form_post and response_type=id_token.
   */
  FrontChannel = 'FRONT_CHANNEL',
}

/** Defines an organization */
export type Organization = {
  __typename?: 'Organization';
  /** The account ID. */
  accountId?: Maybe<Scalars['String']['output']>;
  /**
   * Gets the Organization appearance.
   *
   *
   * **Returns:**
   * Organization appearance.
   */
  appearance?: Maybe<OrganizationAppearance>;
  /**
   * Gets a paginated collection of applications available to the organization.
   *
   *
   * **Returns:**
   * An async task, which result is a collection of organization applications.
   */
  applications?: Maybe<ApplicationsConnection>;
  /**
   * Gets the context organization connection by ID.
   *
   *
   * **Returns:**
   * The found connection details.
   */
  connectionById?: Maybe<DetailedConnection>;
  /**
   * Gets a paginated collection of connections of the context organization.
   *
   *
   * **Returns:**
   * A collection of the connections.
   */
  connections?: Maybe<ConnectionsCollectionSegment>;
  /** The organization display name. */
  displayName: Scalars['String']['output'];
  /** The organization ID. */
  id: Scalars['String']['output'];
  /**
   * Gets the invitations.
   *
   *
   * **Returns:**
   * A collection of the organization's invitations.
   */
  invitations?: Maybe<InvitationsCollectionSegment>;
  /**
   * Gets an organization member by their user ID.
   *
   *
   * **Returns:**
   * An organization member, if found; otherwise, null.
   */
  memberById?: Maybe<OrganizationMember>;
  /**
   * Gets a paginated collection of members of the organization.
   *
   *
   * **Returns:**
   * A collection of the organization members.
   */
  members?: Maybe<MembersCollectionSegment>;
  /** The organization name. */
  name: Scalars['String']['output'];
  /**
   * Gets a paginated collection of all available organization roles.
   *
   *
   * **Returns:**
   * An async task, which result is a collection of organization roles.
   */
  roles?: Maybe<OrganizationRolesConnection>;
  /**
   * Gets the organization type.
   *
   *
   * **Returns:**
   * The organization type.
   */
  type?: Maybe<OrganizationType>;
};

/** Defines an organization */
export type OrganizationApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationApplicationFilterInput>;
};

/** Defines an organization */
export type OrganizationConnectionByIdArgs = {
  id: Scalars['String']['input'];
};

/** Defines an organization */
export type OrganizationConnectionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines an organization */
export type OrganizationInvitationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvitationFilterInput>;
};

/** Defines an organization */
export type OrganizationMemberByIdArgs = {
  userId: Scalars['String']['input'];
};

/** Defines an organization */
export type OrganizationMembersArgs = {
  query?: InputMaybe<OrganizationMembersQueryInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines an organization */
export type OrganizationRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents an Organization appearance. */
export type OrganizationAppearance = {
  __typename?: 'OrganizationAppearance';
  /** Organization appearance, specific for Web client. */
  web: WebOrganizationAppearance;
};

/** Defines an application available for organization. */
export type OrganizationApplication = {
  __typename?: 'OrganizationApplication';
  /** The display name of the application. */
  displayName: Scalars['String']['output'];
  /** The application ID. */
  id: Scalars['String']['output'];
  /** The application labels, returned from Inventory services. */
  labels: Array<KeyValuePairOfStringAndString>;
  /** The application name. */
  name: Scalars['String']['output'];
  /**
   * Gets the organization the application belongs to.
   *
   *
   * **Returns:**
   * An async task, which result is an instance of organization.
   */
  organization?: Maybe<Organization>;
  /** The organization ID the application belongs to. */
  organizationId: Scalars['String']['output'];
  /**
   * Gets the product the application belongs to.
   *
   *
   * **Returns:**
   * An async task, which result is an instance of product.
   */
  product?: Maybe<Product>;
  /** The product code of provisioned application. */
  productCode: Scalars['String']['output'];
  /** The application state. */
  state: Scalars['String']['output'];
  /** The system ID, in scope of which the application has been provisioned. */
  systemId: Scalars['String']['output'];
};

/** Defines an application available for organization. */
export type OrganizationApplicationFilterInput = {
  and?: InputMaybe<Array<OrganizationApplicationFilterInput>>;
  /** The display name of the application. */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** The application ID. */
  id?: InputMaybe<ApplicationIdFilterInput>;
  /** The application labels, returned from Inventory services. */
  labels?: InputMaybe<ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput>;
  /** The application name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationApplicationFilterInput>>;
  /** The organization ID the application belongs to. */
  organizationId?: InputMaybe<OrganizationIdFilterInput>;
  /** The product code of provisioned application. */
  productCode?: InputMaybe<ProductCodeFilterInput>;
  /** The application state. */
  state?: InputMaybe<StringOperationFilterInput>;
  /** The system ID, in scope of which the application has been provisioned. */
  systemId?: InputMaybe<StringOperationFilterInput>;
};

/** Defines Organization ID. */
export type OrganizationIdFilterInput = {
  and?: InputMaybe<Array<OrganizationIdFilterInput>>;
  or?: InputMaybe<Array<OrganizationIdFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** A member of an organization. */
export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  /**
   * Gets the organization member appearance.
   *
   *
   * **Returns:**
   * The organization member appearance.
   */
  appearance?: Maybe<OrganizationMemberAppearance>;
  /**
   * Gets a paginated collection of the user applications the organization member has access to.
   *
   *
   * **Returns:**
   * A paginated collection of user applications.
   */
  applications?: Maybe<OrganizationMemberApplicationsConnection>;
  /** Company of the user. */
  company?: Maybe<Scalars['String']['output']>;
  /** Created date of the user. */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** The user email. */
  email: Scalars['String']['output'];
  /** Boolean flag indicating whether email of the user has been verified. */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Family name of the user. */
  familyName?: Maybe<Scalars['String']['output']>;
  /** Given name of the user. */
  givenName?: Maybe<Scalars['String']['output']>;
  /** The user ID. */
  id: Scalars['String']['output'];
  /** Boolean flag indicating whether user is created using external provider. */
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  /** Job role of the user. */
  jobRole?: Maybe<Scalars['String']['output']>;
  /** Last login date of the user. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** Nick name of the user. */
  nickName?: Maybe<Scalars['String']['output']>;
  /**
   * Gets a paginated collection of organization member roles in the organization.
   *
   *
   * **Returns:**
   * A paginated collection of roles.
   */
  organizationRoles?: Maybe<OrganizationRolesConnection>;
  /** Phone number of the user. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Picture of the user. */
  picture?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the user. */
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  /** Boolean flag indicating whether sign-up of the user has been completed. */
  signUpCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** Updated date of the user. */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

/** A member of an organization. */
export type OrganizationMemberApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A member of an organization. */
export type OrganizationMemberOrganizationRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines an Organization Member Action. */
export type OrganizationMemberAction = {
  __typename?: 'OrganizationMemberAction';
  /** The display name. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** The Action's link. */
  link: Link;
  /** The Action title/name. */
  name: Scalars['String']['output'];
};

/** Defines an Organization Member Action. */
export type OrganizationMemberActionFilterInput = {
  and?: InputMaybe<Array<OrganizationMemberActionFilterInput>>;
  /** The display name. */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** The Action's link. */
  link?: InputMaybe<LinkFilterInput>;
  /** The Action title/name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationMemberActionFilterInput>>;
};

/** Represents an Organization member appearance. */
export type OrganizationMemberAppearance = {
  __typename?: 'OrganizationMemberAppearance';
  /** The actions applicable to the organization member. */
  memberActions?: Maybe<MemberActionsConnection>;
};

/** Represents an Organization member appearance. */
export type OrganizationMemberAppearanceMemberActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationMemberActionFilterInput>;
};

/** The application specific role to add/delete from the organization member. */
export type OrganizationMemberApplicationRole = {
  __typename?: 'OrganizationMemberApplicationRole';
  applicationId: Scalars['String']['output'];
  productCode: Scalars['String']['output'];
  roleName: Scalars['String']['output'];
};

/** The application specific role to add/delete from the organization member. */
export type OrganizationMemberApplicationRoleInput = {
  applicationId: Scalars['String']['input'];
  productCode: Scalars['String']['input'];
  roleName: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type OrganizationMemberApplicationsConnection = {
  __typename?: 'OrganizationMemberApplicationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrganizationMemberApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationMemberApplicationsEdge = {
  __typename?: 'OrganizationMemberApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserApplication;
};

/** The organization role to add/delete from the organization member. */
export type OrganizationMemberOrganizationRole = {
  __typename?: 'OrganizationMemberOrganizationRole';
  /** The role name. */
  roleName: Scalars['String']['output'];
};

/** The organization role to add/delete from the organization member. */
export type OrganizationMemberOrganizationRoleInput = {
  /** The role name. */
  roleName: Scalars['String']['input'];
};

/** Defines a query for organization members. */
export type OrganizationMembersQueryInput = {
  /** Search only members with given name, family name or email containing the provided value. */
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type OrganizationRolesConnection = {
  __typename?: 'OrganizationRolesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrganizationRolesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserOrganizationRole>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationRolesEdge = {
  __typename?: 'OrganizationRolesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserOrganizationRole;
};

export enum OrganizationType {
  Customer = 'CUSTOMER',
  Internal = 'INTERNAL',
  Partner = 'PARTNER',
  Unspecified = 'UNSPECIFIED',
}

/** A segment of a collection. */
export type OrganizationsCollectionSegment = {
  __typename?: 'OrganizationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<UserOrganization>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** This is aggregation type for all types of personalized content. */
export type PersonalizedContent = {
  __typename?: 'PersonalizedContent';
  /**
   * Gets a collection of recommendation cards.
   *
   *
   * **Returns:**
   * An async task, which result is a collection of recommendation cards.
   */
  recommendationCards: Array<RecommendationCard>;
};

/** Defines a product. */
export type Product = {
  __typename?: 'Product';
  /** The product code. */
  code: Scalars['String']['output'];
  /**
   * Gets a collection of deployment regions assigned to the product.
   *
   *
   * **Returns:**
   * A collection of deployment regions.
   */
  deploymentRegions?: Maybe<DeploymentRegionsConnection>;
  /**
   * Gets a paginated collection of roles assigned to the product.
   *
   *
   * **Returns:**
   * A collection of product roles.
   */
  roles?: Maybe<RolesConnection>;
};

/** Defines a product. */
export type ProductDeploymentRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines a product. */
export type ProductRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Defines a Product code. */
export type ProductCodeFilterInput = {
  and?: InputMaybe<Array<ProductCodeFilterInput>>;
  or?: InputMaybe<Array<ProductCodeFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** The plan of the product. */
export enum ProductPlan {
  /** The TIER0 plan. */
  Tier0 = 'TIER0',
}

/** An error occurring on provisioning a product when a conflict occurs. */
export type ProductProvisioningConflictError = Error & {
  __typename?: 'ProductProvisioningConflictError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The error occurring on provisioning a product, when one or more input fields have invalid values. */
export type ProductProvisioningValidationError = Error & {
  __typename?: 'ProductProvisioningValidationError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The message. */
  message: Scalars['String']['output'];
};

/** Defines a product role. */
export type ProductRole = {
  __typename?: 'ProductRole';
  /** Name of the role. */
  name: Scalars['String']['output'];
  /** Code of the product the role belongs to. */
  productCode: Scalars['String']['output'];
};

/** Defines SAML protocol bindings used for authentication requests. */
export enum ProtocolBinding {
  /** The HTTP-POST protocol binding. */
  HttpPost = 'HTTP_POST',
  /** The HTTP-Redirect protocol binding. */
  HttpRedirect = 'HTTP_REDIRECT',
}

export type ProvisionProductError =
  | ProductProvisioningConflictError
  | ProductProvisioningValidationError
  | UnknownError;

/** The input object to provision a product. */
export type ProvisionProductInput = {
  /** The Connect product. */
  connect?: InputMaybe<ConnectProductInput>;
};

export type ProvisionProductPayload = {
  __typename?: 'ProvisionProductPayload';
  errors?: Maybe<Array<ProvisionProductError>>;
  product?: Maybe<ProvisionedProduct>;
};

/** Represents the provisioned Connect product. */
export type ProvisionedConnectProduct = {
  __typename?: 'ProvisionedConnectProduct';
  /** Indicates if the tenant records have audit logs enabled. */
  auditLogsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The display name of the tenant. */
  displayName: Scalars['String']['output'];
  /** The ID of the tenant. */
  id: Scalars['String']['output'];
  /** The name of the tenant. */
  name: Scalars['String']['output'];
  /** The product plan. */
  planId: ProductPlan;
  /** The deployment region id. */
  regionId: Scalars['String']['output'];
  /** The status of the provisioning. */
  status: ProvisionedProductStatus;
  /** The subscription ID for the provisioning. */
  subscriptionId: Scalars['String']['output'];
  /** Indicates whether the product was provisioned in trial mode. */
  trialMode: Scalars['Boolean']['output'];
};

/** The provisioned product interface. */
export type ProvisionedProduct = ProvisionedConnectProduct;

/** The status of the product provisioning. */
export enum ProvisionedProductStatus {
  /** The ACTIVE state. */
  Active = 'ACTIVE',
}

/** Defines a GraphQL query. */
export type Query = {
  __typename?: 'Query';
  /**
   * Gets the context organization data.
   *
   *
   * **Returns:**
   * The context organization.
   */
  organization?: Maybe<Organization>;
  /**
   * Gets the personalized content.
   *
   *
   * **Returns:**
   * Content node, aggregation type for personalized content.
   */
  personalizedContent?: Maybe<PersonalizedContent>;
  /**
   * Gets the products.
   *
   *
   * **Returns:**
   * An enumerable collection of products.
   */
  products: Array<Product>;
  /**
   * Gets the subscriptions available in the current context.
   * Organization Owner role have access to information connected to all existing subscriptions of AccountId assigned to
   * current, context organization.
   *
   *
   * **Returns:**
   * An enumerable collection of subscriptions
   */
  subscriptions?: Maybe<SubscriptionsConnection>;
  /**
   * Gets the current user data.
   *
   *
   * **Returns:**
   * The current user data.
   */
  user?: Maybe<ContextUser>;
};

/** Defines a GraphQL query. */
export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionFilterInput>;
};

/** The Recommendation card. */
export type RecommendationCard = {
  __typename?: 'RecommendationCard';
  cardId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  link?: Maybe<Link>;
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * An error occurring on revoking organization membership.
 * It may mean either invalid request parameters or members could not be found by the provided IDs.
 */
export type RevokeMembershipBadRequest = {
  __typename?: 'RevokeMembershipBadRequest';
  /** Optional additional information related to the error. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The user IDs provided in the operation. */
  members: Array<Scalars['String']['output']>;
  /** The message that potentially provides more details about the error. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Represents an error occurring on organization membership revocation. */
export type RevokeMembershipError =
  | RevokeMembershipBadRequest
  | RevokeMembershipUnknownError;

/** The input for the mutation to revoke organization membership. */
export type RevokeMembershipInput = {
  /** The user IDs to revoke membership for. */
  members: Array<Scalars['String']['input']>;
};

/** The payload returned by the mutation that revokes organization membership. */
export type RevokeMembershipPayload = {
  __typename?: 'RevokeMembershipPayload';
  /** The errors occurred during the operation execution. */
  errors?: Maybe<Array<RevokeMembershipError>>;
  /** The user IDs to revoke membership for. */
  members?: Maybe<Array<Scalars['String']['output']>>;
};

/** An unknown error occurring on revoking organization membership. */
export type RevokeMembershipUnknownError = {
  __typename?: 'RevokeMembershipUnknownError';
  /** The message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type RolesConnection = {
  __typename?: 'RolesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RolesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductRole>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RolesEdge = {
  __typename?: 'RolesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProductRole;
};

/** Defines the key pair used to sign and verify requests. */
export type SamlKeyCert = {
  __typename?: 'SamlKeyCert';
  cert: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

/** Defines the key pair used to sign and verify requests. */
export type SamlKeyCertInput = {
  cert: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

/** The SAMLP connection option. */
export type SamlpConnectionOptions = {
  __typename?: 'SamlpConnectionOptions';
  /** The key pair used to encrypt and decrypt responses. */
  decryptionKey?: Maybe<SamlKeyCert>;
  /** Toggles sign out requests to be forwarded to Identity Provider. */
  disableSignOut?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The attribute mappings applied to a user entity in the authentication responses from the
   * Identity Provider.
   */
  fieldsMap?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The URL of endpoint that returns SAML metadata document of Identity Provider. */
  metadataUrl?: Maybe<Scalars['String']['output']>;
  /** The XML content of SAML metadata document of Identity Provider. */
  metadataXml?: Maybe<Scalars['String']['output']>;
  /** The protocol binding used for authentication requests. */
  protocolBinding?: Maybe<ProtocolBinding>;
  /** The key pair used to sign and verify requests. */
  requestSigningKey?: Maybe<SamlKeyCert>;
  /** The XML template containing an AuthnRequest object. */
  requestTemplate?: Maybe<Scalars['String']['output']>;
  /** The Sign In URL of the Identity Provider to redirect to during login. */
  signInEndpoint?: Maybe<Scalars['String']['output']>;
  /** The Sign Out URL of the Identity Provider to redirect to during logout. */
  signOutEndpoint?: Maybe<Scalars['String']['output']>;
  /** Toggles the signing of authentication requests sent to Identity Provider. */
  signSamlRequest?: Maybe<Scalars['Boolean']['output']>;
  /** The certificate used to verify signature of Identity Provider responses. */
  signingCert?: Maybe<Scalars['String']['output']>;
};

/** The options for SAMLP connection strategy. */
export type SamlpOptionsInput = {
  /**
   * The key pair used to encrypt and decrypt responses.
   *
   *
   *
   *             {
   * "key": "-----BEGIN PRIVATE KEY-----\n...{your private key here}...\n-----END PRIVATE KEY-----",
   * "cert": "-----BEGIN CERTIFICATE-----\n...{your public key cert here}...\n-----END CERTIFICATE-----"
   *             }
   */
  decryptionKey?: InputMaybe<SamlKeyCertInput>;
  /**
   * Toggles sign out requests to be forwarded to Identity Provider.
   * Default value: true.
   */
  disableSignout?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Any attribute mappings to be applied to a user entity in the authentication responses from the Identity Provider.
   * JSON value contains a map, where:
   *
   *     Key is a string that specifies a user property on Sitecore side to map to.
   *     Value is a string array that contains properties of Identity Provider user to map from.
   *
   * Keys and values are case sensitive.
   * The following keys can be specified: email, name, given_name, family_name,
   * nickname, user_id.
   * Any added values extend the default profile mappings. E.g. { "email": ["Email"] } mapping results in two
   * properties being checked: email and Email.
   * The user_id key is a special case. It allows to customize default behavior of user ID resolving. Specify
   * there name of a custom attribute (from the SAML token) that will be treated as user ID.
   *
   *
   *
   *             {
   * "key": "user_id",
   * "value": [
   *     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
   *     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn",
   *     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
   * ]
   *             },
   *             {
   * "key": "email",
   * "value": ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]
   *             }
   */
  fieldsMap?: InputMaybe<Array<KeyValuePairOfStringAndString__Input>>;
  /**
   * URL of endpoint that returns SAML metadata document of Identity Provider. Must be a valid
   * absolute URL.
   */
  metadataUrl?: InputMaybe<Scalars['String']['input']>;
  /**
   * The XML content of SAML metadata document of Identity Provider. Cannot be passed together with metadataUrl. Note:
   * this property is never returned in responses. Must be a valid XML.
   */
  metadataXml?: InputMaybe<Scalars['String']['input']>;
  /**
   * The protocol binding used for authentication requests.
   * Possible values:
   *
   *
   *         urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect
   *
   *
   *         urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST (default value)
   */
  protocolBinding?: InputMaybe<ProtocolBinding>;
  /**
   * The key pair used to sign and verify requests.
   * Required when SignSAMLRequest is true or DisableSignout is false (as
   * logout requests are always signed).
   *
   *
   *
   *             {
   * "key": "-----BEGIN PRIVATE KEY-----\n...{your private key here}...\n-----END PRIVATE KEY-----",
   * "cert": "-----BEGIN CERTIFICATE-----\n...{your public key cert here}...\n-----END CERTIFICATE-----"
   *             }
   */
  requestSigningKey?: InputMaybe<SamlKeyCertInput>;
  /**
   * When Auth0 sends the authentication request to the Identity Provider, the request body contains an AuthnRequest
   * object. You can customize the XML template used for this object.
   *
   *
   *
   *         <samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol"
   *             Destination="@@Destination@@"
   *             ID="@@ID@@"
   *             IssueInstant="@@IssueInstant@@"
   *             ProtocolBinding="@@ProtocolBinding@@" Version="2.0">
   *             <saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion">@@Issuer@@</saml:Issuer>
   *         </samlp:AuthnRequest>
   *
   *
   *
   * Variables can be placed into the template using the @@VariableName@@ syntax.
   * The following variables are available:
   *
   *
   *         ID - The transaction ID.
   *
   *
   *         IssueInstant - The transaction date timestamp.
   *
   *
   *         Issuer - The entity ID of the SP in urn format.
   *
   *
   *         ProtocolBinding - The protocol binding type.
   *
   *
   *         AssertionConsumerServiceURL -
   *         The URL where the IdP sends the response after the user signs in.
   *         Include the ProtocolBinding attribute in the request template if you use this.
   *         This variable can only be used if SAML request is signed (signSAMLRequest is true).
   *
   *
   *         Destination -
   *         The URL where Sitecore sends the request. This should be the Sign In URL configured for the
   *         connection.
   *
   *
   *         LoginHint - The username or email of the user logging in.
   *
   *
   *         ProviderName - The name of the application that initiated the request.
   */
  requestTemplate?: InputMaybe<Scalars['String']['input']>;
  /**
   * The Sign In URL of the Identity Provider to redirect to during login.
   * Must be a valid absolute URL.
   * Cannot be passed together with metadataUrl or metadataXml.
   * Is not required for connection creation, but required for connection activation (when neither metadataUrl
   * nor metadataXml is passed).
   */
  signInEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * The Sign Out URL of the Identity Provider to redirect to during logout.
   * Must be a valid absolute URL.
   * Cannot be passed together with metadataUrl or metadataXml.
   * Required when disableSignout is false and neither metadataUrl nor metadataXml is passed.
   */
  signOutEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * Toggles the signing of authentication requests sent to Identity Provider.
   * Cannot be passed together with metadataUrl or metadataXml.
   * Default value: false.
   */
  signSAMLRequest?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Certificate used to verify signature of Identity Provider responses.
   * Cannot be passed together with metadataUrl or metadataXml.
   * Is not required for connection creation, but required for connection activation (when neither metadataUrl
   * nor  metadataXml is passed).
   * Validation: X509 certificate in Base64 format.
   */
  signingCert?: InputMaybe<Scalars['String']['input']>;
};

/** Defines a Sellable Product Code, which a particular Subscription is connected to. */
export type SellableProductCodeFilterInput = {
  and?: InputMaybe<Array<SellableProductCodeFilterInput>>;
  or?: InputMaybe<Array<SellableProductCodeFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Defines a Subscription. This type aggregates data about organization entitlements according to signed contracts and purchases. */
export type Subscription = {
  __typename?: 'Subscription';
  /** The account ID. */
  accountId: Scalars['String']['output'];
  /** The contract end date. */
  contractEnd: Scalars['Date']['output'];
  /** The contract start date. */
  contractStart: Scalars['Date']['output'];
  /** The subscription edition type. */
  editionType: Scalars['String']['output'];
  /**
   * Gets the entitlements for the subscription.
   *
   *
   * **Returns:**
   * A collection of entitlements for the current subscription.
   */
  entitlements?: Maybe<EntitlementsConnection>;
  /** The collection of Products names included into the Subscription. Multiple Products imply a bundle. */
  productNames: Array<Scalars['String']['output']>;
  /** The product code, the subscription is related to. */
  sellableProductCode: Scalars['String']['output'];
  /** The subscription ID. */
  subscriptionId: Scalars['String']['output'];
};

/** Defines a Subscription. This type aggregates data about organization entitlements according to signed contracts and purchases. */
export type SubscriptionEntitlementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntitlementFilterInput>;
};

/** Defines a Subscription. This type aggregates data about organization entitlements according to signed contracts and purchases. */
export type SubscriptionFilterInput = {
  /** The account ID. */
  accountId?: InputMaybe<AccountIdFilterInput>;
  and?: InputMaybe<Array<SubscriptionFilterInput>>;
  /** The contract end date. */
  contractEnd?: InputMaybe<DateOperationFilterInput>;
  /** The contract start date. */
  contractStart?: InputMaybe<DateOperationFilterInput>;
  /** The subscription edition type. */
  editionType?: InputMaybe<StringOperationFilterInput>;
  /** The entitlements included into the Subscription. */
  entitlements?: InputMaybe<ListFilterInputTypeOfEntitlementFilterInput>;
  or?: InputMaybe<Array<SubscriptionFilterInput>>;
  /** The collection of Products names included into the Subscription. Multiple Products imply a bundle. */
  productNames?: InputMaybe<ListStringOperationFilterInput>;
  /** The product code, the subscription is related to. */
  sellableProductCode?: InputMaybe<SellableProductCodeFilterInput>;
  /** The subscription ID. */
  subscriptionId?: InputMaybe<SubscriptionIdFilterInput>;
};

/** The subscription ID. */
export type SubscriptionIdFilterInput = {
  and?: InputMaybe<Array<SubscriptionIdFilterInput>>;
  or?: InputMaybe<Array<SubscriptionIdFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type SubscriptionsConnection = {
  __typename?: 'SubscriptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SubscriptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Subscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubscriptionsEdge = {
  __typename?: 'SubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Subscription;
};

/** An unexpected server error occurring on executing a mutation. */
export type UnknownError = Error & {
  __typename?: 'UnknownError';
  /** The message. */
  message: Scalars['String']['output'];
};

export type UpdateConnectionError =
  | UpdateConnectionNotFoundError
  | UpdateConnectionValidationError;

/** The input object to update a connection. */
export type UpdateConnectionInput = {
  /** The connection's display name. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the connection to update. */
  id: Scalars['String']['input'];
  /** The connection's options. */
  options?: InputMaybe<UpdateConnectionOptionsInput>;
};

/** The error occuring on an attempt to update an organization connection, when the connection could not be found by ID. */
export type UpdateConnectionNotFoundError = Error & {
  __typename?: 'UpdateConnectionNotFoundError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The message. */
  message: Scalars['String']['output'];
};

/** The connection options of one of the supported connection strategy types. */
export type UpdateConnectionOptionsInput = {
  /** The options for OIDC strategy. */
  oidc?: InputMaybe<UpdateOidcOptionsInput>;
  /** The options for SAMLP strategy. */
  samlp?: InputMaybe<SamlpOptionsInput>;
};

export type UpdateConnectionPayload = {
  __typename?: 'UpdateConnectionPayload';
  connection?: Maybe<DetailedConnection>;
  errors?: Maybe<Array<UpdateConnectionError>>;
};

/** The error occurring on SSO connection update, when one or more input fields have invalid values. */
export type UpdateConnectionValidationError = Error & {
  __typename?: 'UpdateConnectionValidationError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The message. */
  message: Scalars['String']['output'];
};

export type UpdateContextUserDetailsError =
  | ContextUserCannotBeUpdatedError
  | ContextUserNotFoundError
  | ContextUserUpdateUnknownError
  | ContextUserUpdateValidationError;

/** The input data containing user profile fields to update. */
export type UpdateContextUserDetailsInput = {
  /** The company. */
  company?: InputMaybe<Scalars['String']['input']>;
  /** The family name, */
  familyName?: InputMaybe<Scalars['String']['input']>;
  /** The given name. */
  givenName?: InputMaybe<Scalars['String']['input']>;
  /** The job role. */
  jobRole?: InputMaybe<Scalars['String']['input']>;
  /** The nick name. */
  nickName?: InputMaybe<Scalars['String']['input']>;
  /** The phone number. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The preferred language. */
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContextUserDetailsPayload = {
  __typename?: 'UpdateContextUserDetailsPayload';
  contextUserDetails?: Maybe<ContextUserDetails>;
  errors?: Maybe<Array<UpdateContextUserDetailsError>>;
};

/** The options for OIDC connection strategy. */
export type UpdateOidcOptionsInput = {
  /**
   * URL of the OAuth 2.0 Authorization endpoint. Required when discovery is off. Must
   * be a valid absolute URL.
   */
  authorizationEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of a client, registered on OIDC provider side, that will be passed to Authorization endpoint
   * of the external OIDC provider.
   */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Secret of a client, registered on OIDC provider side, that will be passed to Authorization
   * endpoint of the external OIDC provider.
   */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** OpenID configuration endpoint of the external OIDC provider. Must be a valid absolute URL. */
  discoveryUrl?: InputMaybe<Scalars['String']['input']>;
  /**
   * URL using the https scheme with no query or fragment component that represents Issuer Identifier.
   * Required when discovery is off. Must be a valid absolute URL.
   */
  issuer?: InputMaybe<Scalars['String']['input']>;
  /**
   * URL of the JSON Web Key Set [JWK] document. Required when discovery is off. Must be a valid
   * absolute URL.
   */
  jwksUri?: InputMaybe<Scalars['String']['input']>;
  /** Scope to pass to the external OIDC provider. Must be space separated and contain openid. */
  scope?: InputMaybe<Scalars['String']['input']>;
  /**
   * URL of the OAuth 2.0 Token endpoint. Required for BackChannel when discovery is off. Must
   * be a valid absolute URL.
   */
  tokenEndpoint?: InputMaybe<Scalars['String']['input']>;
  /**
   * The Channel type. Determines how the Authorization Server of SSO connection returns result
   * parameters from the Authorization Endpoint.
   */
  type?: InputMaybe<OidcType>;
  /** URL of the User Info endpoint. Must be a valid absolute URL. */
  userInfoEndpoint?: InputMaybe<Scalars['String']['input']>;
};

/** An error occurring on validation of request parameters when updating organization member roles. */
export type UpdateOrgMemberRolesBadRequest = {
  __typename?: 'UpdateOrgMemberRolesBadRequest';
  /** Optional additional information related to the error. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The message that potentially provides more details about the error. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Represents an error occurring on updating organization member roles. */
export type UpdateOrgMemberRolesError =
  | UpdateOrgMemberRolesBadRequest
  | UpdateOrgMemberRolesNotFound
  | UpdateOrgMemberRolesUnknownError;

/** An error occurring on updating organization member roles, when user could not be found by the provided ID. */
export type UpdateOrgMemberRolesNotFound = {
  __typename?: 'UpdateOrgMemberRolesNotFound';
  /** The message that potentially provides more details about the error. */
  message?: Maybe<Scalars['String']['output']>;
  /** The ID of the user whose roles should be updated. */
  userId: Scalars['String']['output'];
};

/** An error occurring on updating organization member roles. */
export type UpdateOrgMemberRolesUnknownError = {
  __typename?: 'UpdateOrgMemberRolesUnknownError';
  /** The message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** The input for adding/deleting roles to an organization member. */
export type UpdateOrganizationMemberRolesInput = {
  /** The application roles to add to the organization member. */
  applicationRolesToAdd?: InputMaybe<
    Array<OrganizationMemberApplicationRoleInput>
  >;
  /** The application roles to remove from the organization member. */
  applicationRolesToDelete?: InputMaybe<
    Array<OrganizationMemberApplicationRoleInput>
  >;
  /** The organization roles to add to the organization member. */
  organizationRolesToAdd?: InputMaybe<
    Array<OrganizationMemberOrganizationRoleInput>
  >;
  /** The organization roles to remove from the organization member. */
  organizationRolesToDelete?: InputMaybe<
    Array<OrganizationMemberOrganizationRoleInput>
  >;
  /** The user id of the organization member to update roles for. */
  userId: Scalars['String']['input'];
};

/** The payload returned by the mutation that updates organization member roles. */
export type UpdateOrganizationMemberRolesPayload = {
  __typename?: 'UpdateOrganizationMemberRolesPayload';
  assignedApplicationRoles?: Maybe<Array<OrganizationMemberApplicationRole>>;
  assignedOrganizationRoles?: Maybe<Array<OrganizationMemberOrganizationRole>>;
  deletedApplicationRoles?: Maybe<Array<OrganizationMemberApplicationRole>>;
  deletedOrganizationRoles?: Maybe<Array<OrganizationMemberOrganizationRole>>;
  errors?: Maybe<Array<UpdateOrgMemberRolesError>>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** Defines a usage of a particular metric during a consumption period. */
export type Usage = {
  __typename?: 'Usage';
  /** The unique identifier that corresponds to a part of a metric, which the usage is reported for. */
  reportedFor?: Maybe<Scalars['String']['output']>;
  /** The value of the usage. */
  value: Scalars['Decimal']['output'];
};

/** Defines an application available for a user. */
export type UserApplication = {
  __typename?: 'UserApplication';
  /**
   * Gets a paginated collection of user roles in the application.
   *
   *
   * **Returns:**
   * The list of roles.
   */
  assignedRoles?: Maybe<AssignedRolesConnection>;
  /** The display name of the application. */
  displayName: Scalars['String']['output'];
  /** The application ID. */
  id: Scalars['String']['output'];
  /** The application labels, returned from Inventory services. */
  labels: Array<KeyValuePairOfStringAndString>;
  /** The application name. */
  name: Scalars['String']['output'];
  /** The organization ID the application belongs to. */
  organizationId: Scalars['String']['output'];
  /**
   * Gets the product the application belongs to.
   *
   *
   * **Returns:**
   * An async task, which result is an instance of product.
   */
  product?: Maybe<Product>;
  /** The product code of provisioned application. */
  productCode: Scalars['String']['output'];
  /** The application state. */
  state: Scalars['String']['output'];
  /** The system ID, in scope of which the application has been provisioned. */
  systemId: Scalars['String']['output'];
};

/** Defines an application available for a user. */
export type UserApplicationAssignedRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a User Application appearance. */
export type UserApplicationAppearance = {
  __typename?: 'UserApplicationAppearance';
  /** User Application appearance for Web */
  web: WebUserApplicationAppearance;
};

/** Defines a user role for a particular application. */
export type UserApplicationRole = {
  __typename?: 'UserApplicationRole';
  /** The application ID. */
  applicationId: Scalars['String']['output'];
  /** The organization ID the application belongs to. */
  organizationId: Scalars['String']['output'];
  /** The product code of provisioned application. */
  productCode: Scalars['String']['output'];
  /** The role name. */
  role: Scalars['String']['output'];
};

/** Defines a user role for a particular application. */
export type UserApplicationRoleFilterInput = {
  and?: InputMaybe<Array<UserApplicationRoleFilterInput>>;
  /** The application ID. */
  applicationId?: InputMaybe<ApplicationIdFilterInput>;
  or?: InputMaybe<Array<UserApplicationRoleFilterInput>>;
  /** The organization ID the application belongs to. */
  organizationId?: InputMaybe<OrganizationIdFilterInput>;
  /** The product code of provisioned application. */
  productCode?: InputMaybe<ProductCodeFilterInput>;
  /** The role name. */
  role?: InputMaybe<StringOperationFilterInput>;
};

/** The organization a user is a member of. */
export type UserOrganization = {
  __typename?: 'UserOrganization';
  /** The account ID of the organization. */
  accountId?: Maybe<Scalars['String']['output']>;
  /** The email ID of the organization creator. */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** The default language of the organization. */
  defaultLanguage?: Maybe<Scalars['String']['output']>;
  /** The organization display name. */
  displayName: Scalars['String']['output'];
  /** The organization ID. */
  id: Scalars['String']['output'];
  /** Boolean flag indicating whether the organization requires multi factor authentication. */
  mfaRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The organization name. */
  name: Scalars['String']['output'];
  /** The phone number of the organization. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The region of the organization. */
  region?: Maybe<Scalars['String']['output']>;
  /** The type of the organization. */
  type?: Maybe<OrganizationType>;
};

/** Encapsulates a user role in a particular organization. */
export type UserOrganizationRole = {
  __typename?: 'UserOrganizationRole';
  /**
   * Gets the description of the organization role.
   *
   *
   * **Returns:**
   * The role description.
   */
  description?: Maybe<Scalars['String']['output']>;
  /** The role name in the current scope. */
  name: Scalars['String']['output'];
  /** The organization ID. */
  organizationId: Scalars['String']['output'];
};

/** Encapsulates a user role in a particular organization. */
export type UserOrganizationRoleFilterInput = {
  and?: InputMaybe<Array<UserOrganizationRoleFilterInput>>;
  /** The role name in the current scope. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserOrganizationRoleFilterInput>>;
  /** The organization ID. */
  organizationId?: InputMaybe<OrganizationIdFilterInput>;
};

/** Defines a verified connection. */
export type VerifiedConnection = {
  __typename?: 'VerifiedConnection';
  /** The connection account ID. */
  accountId?: Maybe<Scalars['String']['output']>;
  /** The creation date of the connection. */
  created: Scalars['DateTime']['output'];
  /** The connection display name. */
  displayName: Scalars['String']['output'];
  /** The connection email domain. */
  emailDomain: Scalars['String']['output'];
  /** The connection ID. */
  id: Scalars['String']['output'];
  /** The connection name. */
  name: Scalars['String']['output'];
  /** The connection */
  options: ConnectionOptions;
  /** The connection organization ID. */
  organizationId: Scalars['String']['output'];
  /** The connection state. */
  state: ConnectionState;
  /** The connection strategy. */
  strategy: ConnectionStrategy;
};

/** An error occurring on verifying an organization connection by its ID due to invalid input parameters. */
export type VerifyConnectionBadRequestError = Error & {
  __typename?: 'VerifyConnectionBadRequestError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** An error occurring on verifying an organization connection, when there is already an existing verified connection with the same email domain. */
export type VerifyConnectionConflictError = Error & {
  __typename?: 'VerifyConnectionConflictError';
  /** The error details. */
  errorDetails?: Maybe<Array<KeyValuePairOfStringAndString__>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type VerifyConnectionError =
  | UnknownError
  | VerifyConnectionBadRequestError
  | VerifyConnectionConflictError
  | VerifyConnectionNotFoundError;

export type VerifyConnectionInput = {
  /** The ID of the organization connection to verify. */
  id: Scalars['String']['input'];
};

/** An error occurring on verifying an organization connection, when the connection ID was not found. */
export type VerifyConnectionNotFoundError = Error & {
  __typename?: 'VerifyConnectionNotFoundError';
  /** The ID of the connection to verify. */
  id: Scalars['String']['output'];
  /** The error message. */
  message: Scalars['String']['output'];
};

export type VerifyConnectionPayload = {
  __typename?: 'VerifyConnectionPayload';
  connection?: Maybe<DetailedConnection>;
  errors?: Maybe<Array<VerifyConnectionError>>;
};

/** Represents an Organization appearance for Web. */
export type WebOrganizationAppearance = {
  __typename?: 'WebOrganizationAppearance';
  /** The actions applicable to the organization. */
  actions?: Maybe<ActionsConnection>;
};

/** Represents an Organization appearance for Web. */
export type WebOrganizationAppearanceActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActionFilterInput>;
};

/** Represents a User Application appearance for Web. */
export type WebUserApplicationAppearance = {
  __typename?: 'WebUserApplicationAppearance';
  /** The actions applicable to the application. */
  actions?: Maybe<ActionsConnection>;
};

/** Represents a User Application appearance for Web. */
export type WebUserApplicationAppearanceActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActionFilterInput>;
};

export type GetUserAppAccessQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  env?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserAppAccessQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ContextUser';
    applications?: {
      __typename?: 'ContextUserApplicationsConnection';
      nodes?: Array<{
        __typename?: 'ContextUserApplication';
        assignedRoles?: {
          __typename?: 'AssignedRolesConnection';
          nodes?: Array<{
            __typename?: 'UserApplicationRole';
            applicationId: string;
            organizationId: string;
            role: string;
            productCode: string;
          }> | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetUserOrgAccessQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUserOrgAccessQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ContextUser';
    organizationRoles?: {
      __typename?: 'OrganizationRolesConnection';
      nodes?: Array<{
        __typename?: 'UserOrganizationRole';
        organizationId: string;
        name: string;
      }> | null;
    } | null;
  } | null;
};

export type GetUserOrgDepsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserOrgDepsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    displayName: string;
    accountId?: string | null;
    type?: OrganizationType | null;
  } | null;
  user?: {
    __typename?: 'ContextUser';
    applications?: {
      __typename?: 'ContextUserApplicationsConnection';
      nodes?: Array<{
        __typename?: 'ContextUserApplication';
        assignedRoles?: {
          __typename?: 'AssignedRolesConnection';
          nodes?: Array<{
            __typename?: 'UserApplicationRole';
            applicationId: string;
            organizationId: string;
            role: string;
            productCode: string;
          }> | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetHelpQuickActionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetHelpQuickActionsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    appearance?: {
      __typename?: 'OrganizationAppearance';
      web: {
        __typename?: 'WebOrganizationAppearance';
        actions?: {
          __typename?: 'ActionsConnection';
          nodes?: Array<{
            __typename?: 'Action';
            name: string;
            color: string;
            displayName?: string | null;
            location?: Array<string> | null;
            category?: string | null;
            description?: string | null;
            icon: { __typename?: 'Icon'; src: string; type: string };
            link: { __typename?: 'Link'; to: string; type: string };
          }> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type GetQuickLinksQueryVariables = Exact<{ [key: string]: never }>;

export type GetQuickLinksQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    appearance?: {
      __typename?: 'OrganizationAppearance';
      web: {
        __typename?: 'WebOrganizationAppearance';
        actions?: {
          __typename?: 'ActionsConnection';
          nodes?: Array<{
            __typename?: 'Action';
            name: string;
            color: string;
            displayName?: string | null;
            location?: Array<string> | null;
            description?: string | null;
            category?: string | null;
            icon: { __typename?: 'Icon'; src: string; type: string };
            link: { __typename?: 'Link'; to: string; type: string };
          }> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type GetTenantsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  env?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTenantsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ContextUser';
    applications?: {
      __typename?: 'ContextUserApplicationsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'ContextUserApplicationsEdge';
        cursor: string;
        node: {
          __typename?: 'ContextUserApplication';
          id: string;
          appearance?: {
            __typename?: 'UserApplicationAppearance';
            web: {
              __typename?: 'WebUserApplicationAppearance';
              actions?: {
                __typename?: 'ActionsConnection';
                nodes?: Array<{
                  __typename?: 'Action';
                  name: string;
                  displayName?: string | null;
                  icon: { __typename?: 'Icon'; src: string; type: string };
                }> | null;
              } | null;
            };
          } | null;
        };
      }> | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      nodes?: Array<{
        __typename?: 'ContextUserApplication';
        id: string;
        name: string;
        displayName: string;
        organizationId: string;
        state: string;
        systemId: string;
        productCode: string;
        labels: Array<{
          __typename?: 'KeyValuePairOfStringAndString';
          key: string;
          value: string;
        }>;
        appearance?: {
          __typename?: 'UserApplicationAppearance';
          web: {
            __typename?: 'WebUserApplicationAppearance';
            actions?: {
              __typename?: 'ActionsConnection';
              nodes?: Array<{
                __typename?: 'Action';
                name: string;
                color: string;
                displayName?: string | null;
                location?: Array<string> | null;
                category?: string | null;
                description?: string | null;
                link: { __typename?: 'Link'; type: string; to: string };
                icon: { __typename?: 'Icon'; src: string; type: string };
              }> | null;
            } | null;
          };
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type RevokeMembershipMutationVariables = Exact<{
  input: RevokeMembershipInput;
}>;

export type RevokeMembershipMutation = {
  __typename?: 'Mutation';
  revokeMembership: {
    __typename?: 'RevokeMembershipPayload';
    members?: Array<string> | null;
    errors?: Array<
      | {
          __typename?: 'RevokeMembershipBadRequest';
          message?: string | null;
          errorDetails?: Array<{
            __typename?: 'KeyValuePairOfStringAndString__';
            key: string;
            value: Array<string>;
          }> | null;
        }
      | { __typename?: 'RevokeMembershipUnknownError'; message?: string | null }
    > | null;
  };
};

export type DeleteInvitationMutationVariables = Exact<{
  invitationId: Scalars['String']['input'];
}>;

export type DeleteInvitationMutation = {
  __typename?: 'Mutation';
  deleteInvitation: {
    __typename?: 'DeleteInvitationPayload';
    errors?: Array<
      | { __typename?: 'BadRequestError'; message: string }
      | { __typename?: 'NotFoundError'; message: string }
    > | null;
  };
};

export type InviteOrganizationMemberMutationVariables = Exact<{
  inviterName: Scalars['String']['input'];
  inviteeEmail: Scalars['String']['input'];
  organizationRoles?: InputMaybe<
    Array<InvitationOrganizationRoleInput> | InvitationOrganizationRoleInput
  >;
  applicationRoles?: InputMaybe<
    Array<InvitationApplicationRoleInput> | InvitationApplicationRoleInput
  >;
}>;

export type InviteOrganizationMemberMutation = {
  __typename?: 'Mutation';
  inviteOrganizationMember: {
    __typename?: 'InviteOrganizationMemberPayload';
    invitation?: {
      __typename?: 'Invitation';
      id: string;
      inviteeEmail: string;
    } | null;
    errors?: Array<
      | { __typename?: 'BadRequestError'; message: string }
      | { __typename?: 'ConflictError'; message: string }
    > | null;
  };
};

export type GetInvitationsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetInvitationsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    invitations?: {
      __typename?: 'InvitationsCollectionSegment';
      totalCount: number;
      pageInfo: {
        __typename?: 'CollectionSegmentInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
      items?: Array<{
        __typename?: 'Invitation';
        id: string;
        inviterName: string;
        inviteeEmail: string;
        expires: any;
        appearance: {
          __typename?: 'InvitationAppearance';
          actions: Array<{
            __typename?: 'InvitationAction';
            name: string;
            displayName: string;
            link: { __typename?: 'Link'; to: string; type: string };
          }>;
        };
        organizationRoles: Array<{
          __typename?: 'UserOrganizationRole';
          organizationId: string;
          name: string;
          description?: string | null;
        }>;
        applicationRoles: Array<{
          __typename?: 'UserApplicationRole';
          applicationId: string;
          organizationId: string;
          role: string;
          productCode: string;
        }>;
      }> | null;
    } | null;
  } | null;
};

export type GetInvitationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetInvitationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    applications?: {
      __typename?: 'ApplicationsConnection';
      nodes?: Array<{
        __typename?: 'OrganizationApplication';
        id: string;
        name: string;
        displayName: string;
        productCode: string;
      }> | null;
    } | null;
    invitations?: {
      __typename?: 'InvitationsCollectionSegment';
      totalCount: number;
      items?: Array<{
        __typename?: 'Invitation';
        id: string;
        inviterName: string;
        inviteeEmail: string;
        expires: any;
        organizationRoles: Array<{
          __typename?: 'UserOrganizationRole';
          organizationId: string;
          name: string;
          description?: string | null;
        }>;
        applicationRoles: Array<{
          __typename?: 'UserApplicationRole';
          applicationId: string;
          organizationId: string;
          role: string;
          productCode: string;
        }>;
      }> | null;
    } | null;
  } | null;
};

export type GetOrganizationTenantsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  env?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrganizationTenantsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    applications?: {
      __typename?: 'ApplicationsConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'OrganizationApplication';
        id: string;
        name: string;
        displayName: string;
        organizationId: string;
        state: string;
        systemId: string;
        productCode: string;
        labels: Array<{
          __typename?: 'KeyValuePairOfStringAndString';
          key: string;
          value: string;
        }>;
        product?: {
          __typename?: 'Product';
          code: string;
          roles?: {
            __typename?: 'RolesConnection';
            totalCount: number;
            nodes?: Array<{
              __typename?: 'ProductRole';
              productCode: string;
              name: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetMembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMembersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    members?: {
      __typename?: 'MembersCollectionSegment';
      totalCount: number;
      items?: Array<{
        __typename?: 'OrganizationMember';
        id: string;
        emailVerified?: boolean | null;
        created?: any | null;
        updated?: any | null;
        lastLogin?: any | null;
        signUpCompleted?: boolean | null;
        isExternal?: boolean | null;
        givenName?: string | null;
        familyName?: string | null;
        nickName?: string | null;
        phoneNumber?: string | null;
        company?: string | null;
        jobRole?: string | null;
        preferredLanguage?: string | null;
        picture?: string | null;
        email: string;
        organizationRoles?: {
          __typename?: 'OrganizationRolesConnection';
          nodes?: Array<{
            __typename?: 'UserOrganizationRole';
            description?: string | null;
            name: string;
            organizationId: string;
          }> | null;
        } | null;
        appearance?: {
          __typename?: 'OrganizationMemberAppearance';
          memberActions?: {
            __typename?: 'MemberActionsConnection';
            totalCount: number;
            nodes?: Array<{
              __typename?: 'OrganizationMemberAction';
              displayName?: string | null;
              name: string;
              link: { __typename?: 'Link'; to: string; type: string };
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetOrganizationMemberQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetOrganizationMemberQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    memberById?: {
      __typename?: 'OrganizationMember';
      id: string;
      emailVerified?: boolean | null;
      created?: any | null;
      updated?: any | null;
      lastLogin?: any | null;
      signUpCompleted?: boolean | null;
      isExternal?: boolean | null;
      givenName?: string | null;
      familyName?: string | null;
      nickName?: string | null;
      phoneNumber?: string | null;
      company?: string | null;
      jobRole?: string | null;
      preferredLanguage?: string | null;
      picture?: string | null;
      email: string;
      applications?: {
        __typename?: 'OrganizationMemberApplicationsConnection';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'UserApplication';
          id: string;
          name: string;
          displayName: string;
          organizationId: string;
          state: string;
          systemId: string;
          productCode: string;
          assignedRoles?: {
            __typename?: 'AssignedRolesConnection';
            totalCount: number;
            nodes?: Array<{
              __typename?: 'UserApplicationRole';
              applicationId: string;
              organizationId: string;
              role: string;
              productCode: string;
            }> | null;
          } | null;
        }> | null;
      } | null;
      organizationRoles?: {
        __typename?: 'OrganizationRolesConnection';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'UserOrganizationRole';
          organizationId: string;
          name: string;
          description?: string | null;
        }> | null;
      } | null;
      appearance?: {
        __typename?: 'OrganizationMemberAppearance';
        memberActions?: {
          __typename?: 'MemberActionsConnection';
          totalCount: number;
          nodes?: Array<{
            __typename?: 'OrganizationMemberAction';
            name: string;
            displayName?: string | null;
            link: { __typename?: 'Link'; to: string; type: string };
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateOrganizationMemberRolesMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  organizationRolesToAdd?: InputMaybe<
    | Array<OrganizationMemberOrganizationRoleInput>
    | OrganizationMemberOrganizationRoleInput
  >;
  organizationRolesToDelete?: InputMaybe<
    | Array<OrganizationMemberOrganizationRoleInput>
    | OrganizationMemberOrganizationRoleInput
  >;
  applicationRolesToAdd?: InputMaybe<
    | Array<OrganizationMemberApplicationRoleInput>
    | OrganizationMemberApplicationRoleInput
  >;
  applicationRolesToDelete?: InputMaybe<
    | Array<OrganizationMemberApplicationRoleInput>
    | OrganizationMemberApplicationRoleInput
  >;
}>;

export type UpdateOrganizationMemberRolesMutation = {
  __typename?: 'Mutation';
  updateOrganizationMemberRoles: {
    __typename?: 'UpdateOrganizationMemberRolesPayload';
    userId?: string | null;
    errors?: Array<
      | {
          __typename?: 'UpdateOrgMemberRolesBadRequest';
          message?: string | null;
          errorDetails?: Array<{
            __typename?: 'KeyValuePairOfStringAndString__';
            key: string;
            value: Array<string>;
          }> | null;
        }
      | {
          __typename?: 'UpdateOrgMemberRolesNotFound';
          message?: string | null;
          userId: string;
        }
      | {
          __typename?: 'UpdateOrgMemberRolesUnknownError';
          message?: string | null;
        }
    > | null;
  };
};

export type GetMemberDetailsOrganizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberDetailsOrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    applications?: {
      __typename?: 'ApplicationsConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'OrganizationApplication';
        id: string;
        name: string;
        displayName: string;
        organizationId: string;
        state: string;
        systemId: string;
        productCode: string;
      }> | null;
    } | null;
  } | null;
};

export type GetMemberDetailsUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberDetailsUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ContextUser';
    applications?: {
      __typename?: 'ContextUserApplicationsConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'ContextUserApplication';
        id: string;
        name: string;
        displayName: string;
        organizationId: string;
        state: string;
        systemId: string;
        productCode: string;
        appearance?: {
          __typename?: 'UserApplicationAppearance';
          web: {
            __typename?: 'WebUserApplicationAppearance';
            actions?: {
              __typename?: 'ActionsConnection';
              nodes?: Array<{
                __typename?: 'Action';
                name: string;
                displayName?: string | null;
                color: string;
                icon: { __typename?: 'Icon'; src: string; type: string };
              }> | null;
            } | null;
          };
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetMemberDetailsProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberDetailsProductsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    roles?: {
      __typename?: 'RolesConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'ProductRole';
        productCode: string;
        name: string;
      }> | null;
    } | null;
  }>;
};

export type MemberFragment = {
  __typename?: 'OrganizationMember';
  familyName?: string | null;
  givenName?: string | null;
  email: string;
  lastLogin?: any | null;
};

export type UpdateContextUserDetailsMutationVariables = Exact<{
  input: UpdateContextUserDetailsInput;
}>;

export type UpdateContextUserDetailsMutation = {
  __typename?: 'Mutation';
  updateContextUserDetails: {
    __typename?: 'UpdateContextUserDetailsPayload';
    errors?: Array<
      | { __typename?: 'ContextUserCannotBeUpdatedError'; message: string }
      | { __typename?: 'ContextUserNotFoundError'; message: string }
      | { __typename?: 'ContextUserUpdateUnknownError'; message: string }
      | {
          __typename?: 'ContextUserUpdateValidationError';
          message: string;
          errorDetails?: Array<{
            __typename?: 'KeyValuePairOfStringAndString__';
            key: string;
            value: Array<string>;
          }> | null;
        }
    > | null;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ContextUser';
    id: string;
    email: string;
    emailVerified?: boolean | null;
    created?: any | null;
    updated?: any | null;
    lastLogin?: any | null;
    signUpCompleted?: boolean | null;
    givenName?: string | null;
    familyName?: string | null;
    nickName?: string | null;
    phoneNumber?: string | null;
    company?: string | null;
    jobRole?: string | null;
    preferredLanguage?: string | null;
    isExternal?: boolean | null;
  } | null;
};

export type GetRecommendationCardsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRecommendationCardsQuery = {
  __typename?: 'Query';
  personalizedContent?: {
    __typename?: 'PersonalizedContent';
    recommendationCards: Array<{
      __typename?: 'RecommendationCard';
      cardId: string;
      title?: string | null;
      description?: string | null;
      link?: { __typename?: 'Link'; to: string; type: string } | null;
      icon?: { __typename?: 'Icon'; src: string; type: string } | null;
    }>;
  } | null;
};

export type GetSubscriptionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSubscriptionsQuery = {
  __typename?: 'Query';
  subscriptions?: {
    __typename?: 'SubscriptionsConnection';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Subscription';
      subscriptionId: string;
      accountId: string;
      contractStart: any;
      contractEnd: any;
      editionType: string;
      productNames: Array<string>;
      sellableProductCode: string;
      entitlements?: {
        __typename?: 'EntitlementsConnection';
        nodes?: Array<{
          __typename?: 'Entitlement';
          startDate: any;
          endDate: any;
          name: string;
          quantity?: any | null;
          consumption?: {
            __typename?: 'Consumption';
            currentPeriod: {
              __typename?: 'ConsumptionPeriod';
              usages: Array<{ __typename?: 'Usage'; value: any }>;
            };
          } | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetSubscriptionQueryVariables = Exact<{
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetSubscriptionQuery = {
  __typename?: 'Query';
  subscriptions?: {
    __typename?: 'SubscriptionsConnection';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Subscription';
      subscriptionId: string;
      accountId: string;
      contractStart: any;
      contractEnd: any;
      editionType: string;
      productNames: Array<string>;
      sellableProductCode: string;
      entitlements?: {
        __typename?: 'EntitlementsConnection';
        nodes?: Array<{
          __typename?: 'Entitlement';
          startDate: any;
          endDate: any;
          name: string;
          quantity?: any | null;
          periodType: EntitlementPeriod;
          lastReportedDate?: any | null;
          metric: string;
          limitType: EntitlementLimitType;
          consumption?: {
            __typename?: 'Consumption';
            currentPeriod: {
              __typename?: 'ConsumptionPeriod';
              endDate: any;
              startDate: any;
              usages: Array<{
                __typename?: 'Usage';
                value: any;
                reportedFor?: string | null;
              }>;
            };
          } | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCountOfProductQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetCountOfProductQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    applications?: {
      __typename?: 'ApplicationsConnection';
      totalCount: number;
    } | null;
  } | null;
};

export type GetDeploymentRegionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDeploymentRegionsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    code: string;
    deploymentRegions?: {
      __typename?: 'DeploymentRegionsConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'DeploymentRegion';
        id: string;
        description: string;
      }> | null;
    } | null;
  }>;
};

export type ProvisionConnectMutationVariables = Exact<{
  name: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  regiondId: Scalars['String']['input'];
  auditLogsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProvisionConnectMutation = {
  __typename?: 'Mutation';
  provisionProduct: {
    __typename?: 'ProvisionProductPayload';
    errors?: Array<
      | { __typename?: 'ProductProvisioningConflictError'; message: string }
      | {
          __typename?: 'ProductProvisioningValidationError';
          message: string;
          errorDetails?: Array<{
            __typename?: 'KeyValuePairOfStringAndString__';
            key: string;
            value: Array<string>;
          }> | null;
        }
      | { __typename?: 'UnknownError'; message: string }
    > | null;
  };
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  ActivateConnectionError:
    | ActivateConnectionBadRequestError
    | ActivateConnectionConflictError
    | ActivateConnectionNotFoundError
    | UnknownError;
  ConnectionOptions: OidcConnectionOptions | SamlpConnectionOptions;
  CreateConnectionError:
    | CreateConnectionConflictError
    | CreateConnectionValidationError;
  DeleteConnectionError:
    | DeleteConnectionBadRequestError
    | DeleteConnectionNotFoundError;
  DeleteInvitationError: BadRequestError | NotFoundError;
  DetailedConnection:
    | (Omit<NonVerifiedConnection, 'options'> & {
        options: _RefType['ConnectionOptions'];
      })
    | (Omit<VerifiedConnection, 'options'> & {
        options: _RefType['ConnectionOptions'];
      });
  InviteOrganizationMemberError: BadRequestError | ConflictError;
  ProvisionProductError:
    | ProductProvisioningConflictError
    | ProductProvisioningValidationError
    | UnknownError;
  ProvisionedProduct: ProvisionedConnectProduct;
  RevokeMembershipError:
    | RevokeMembershipBadRequest
    | RevokeMembershipUnknownError;
  UpdateConnectionError:
    | UpdateConnectionNotFoundError
    | UpdateConnectionValidationError;
  UpdateContextUserDetailsError:
    | ContextUserCannotBeUpdatedError
    | ContextUserNotFoundError
    | ContextUserUpdateUnknownError
    | ContextUserUpdateValidationError;
  UpdateOrgMemberRolesError:
    | UpdateOrgMemberRolesBadRequest
    | UpdateOrgMemberRolesNotFound
    | UpdateOrgMemberRolesUnknownError;
  VerifyConnectionError:
    | UnknownError
    | VerifyConnectionBadRequestError
    | VerifyConnectionConflictError
    | VerifyConnectionNotFoundError;
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> =
  {
    Error:
      | ActivateConnectionBadRequestError
      | ActivateConnectionConflictError
      | ActivateConnectionNotFoundError
      | BadRequestError
      | ConflictError
      | ContextUserCannotBeUpdatedError
      | ContextUserNotFoundError
      | ContextUserUpdateUnknownError
      | ContextUserUpdateValidationError
      | CreateConnectionConflictError
      | CreateConnectionValidationError
      | DeleteConnectionBadRequestError
      | DeleteConnectionNotFoundError
      | NotFoundError
      | ProductProvisioningConflictError
      | ProductProvisioningValidationError
      | UnknownError
      | UpdateConnectionNotFoundError
      | UpdateConnectionValidationError
      | VerifyConnectionBadRequestError
      | VerifyConnectionConflictError
      | VerifyConnectionNotFoundError;
  };

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountIdFilterInput: AccountIdFilterInput;
  Action: ResolverTypeWrapper<Action>;
  ActionCategoryFilterInput: ActionCategoryFilterInput;
  ActionFilterInput: ActionFilterInput;
  ActionsConnection: ResolverTypeWrapper<ActionsConnection>;
  ActionsEdge: ResolverTypeWrapper<ActionsEdge>;
  ActivateConnectionBadRequestError: ResolverTypeWrapper<ActivateConnectionBadRequestError>;
  ActivateConnectionConflictError: ResolverTypeWrapper<ActivateConnectionConflictError>;
  ActivateConnectionError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ActivateConnectionError']
  >;
  ActivateConnectionInput: ActivateConnectionInput;
  ActivateConnectionNotFoundError: ResolverTypeWrapper<ActivateConnectionNotFoundError>;
  ActivateConnectionPayload: ResolverTypeWrapper<
    Omit<ActivateConnectionPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['ActivateConnectionError']>>;
    }
  >;
  ApplicationIdFilterInput: ApplicationIdFilterInput;
  ApplicationsConnection: ResolverTypeWrapper<ApplicationsConnection>;
  ApplicationsEdge: ResolverTypeWrapper<ApplicationsEdge>;
  ApplyPolicy: ApplyPolicy;
  AssignedRolesConnection: ResolverTypeWrapper<AssignedRolesConnection>;
  AssignedRolesEdge: ResolverTypeWrapper<AssignedRolesEdge>;
  BadRequestError: ResolverTypeWrapper<BadRequestError>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CollectionSegmentInfo: ResolverTypeWrapper<CollectionSegmentInfo>;
  ColorFilterInput: ColorFilterInput;
  ConflictError: ResolverTypeWrapper<ConflictError>;
  ConnectProductInput: ConnectProductInput;
  Connection: ResolverTypeWrapper<Connection>;
  ConnectionAction: ResolverTypeWrapper<ConnectionAction>;
  ConnectionAppearance: ResolverTypeWrapper<ConnectionAppearance>;
  ConnectionOptions: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ConnectionOptions']
  >;
  ConnectionState: ConnectionState;
  ConnectionStrategy: ConnectionStrategy;
  ConnectionVerification: ResolverTypeWrapper<ConnectionVerification>;
  ConnectionVerificationMethod: ResolverTypeWrapper<ConnectionVerificationMethod>;
  ConnectionsCollectionSegment: ResolverTypeWrapper<ConnectionsCollectionSegment>;
  Consumption: ResolverTypeWrapper<Consumption>;
  ConsumptionPeriod: ResolverTypeWrapper<ConsumptionPeriod>;
  ContextUser: ResolverTypeWrapper<ContextUser>;
  ContextUserApplication: ResolverTypeWrapper<ContextUserApplication>;
  ContextUserApplicationFilterInput: ContextUserApplicationFilterInput;
  ContextUserApplicationsConnection: ResolverTypeWrapper<ContextUserApplicationsConnection>;
  ContextUserApplicationsEdge: ResolverTypeWrapper<ContextUserApplicationsEdge>;
  ContextUserCannotBeUpdatedError: ResolverTypeWrapper<ContextUserCannotBeUpdatedError>;
  ContextUserDetails: ResolverTypeWrapper<ContextUserDetails>;
  ContextUserNotFoundError: ResolverTypeWrapper<ContextUserNotFoundError>;
  ContextUserUpdateUnknownError: ResolverTypeWrapper<ContextUserUpdateUnknownError>;
  ContextUserUpdateValidationError: ResolverTypeWrapper<ContextUserUpdateValidationError>;
  CreateConnectionConflictError: ResolverTypeWrapper<CreateConnectionConflictError>;
  CreateConnectionError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateConnectionError']
  >;
  CreateConnectionInput: CreateConnectionInput;
  CreateConnectionOptionsInput: CreateConnectionOptionsInput;
  CreateConnectionPayload: ResolverTypeWrapper<
    Omit<CreateConnectionPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['CreateConnectionError']>>;
    }
  >;
  CreateConnectionValidationError: ResolverTypeWrapper<CreateConnectionValidationError>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateOperationFilterInput: DateOperationFilterInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: ResolverTypeWrapper<Scalars['Decimal']['output']>;
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DeleteConnectionBadRequestError: ResolverTypeWrapper<DeleteConnectionBadRequestError>;
  DeleteConnectionError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DeleteConnectionError']
  >;
  DeleteConnectionInput: DeleteConnectionInput;
  DeleteConnectionNotFoundError: ResolverTypeWrapper<DeleteConnectionNotFoundError>;
  DeleteConnectionPayload: ResolverTypeWrapper<
    Omit<DeleteConnectionPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['DeleteConnectionError']>>;
    }
  >;
  DeleteInvitationError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DeleteInvitationError']
  >;
  DeleteInvitationInput: DeleteInvitationInput;
  DeleteInvitationPayload: ResolverTypeWrapper<
    Omit<DeleteInvitationPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['DeleteInvitationError']>>;
    }
  >;
  DeploymentRegion: ResolverTypeWrapper<DeploymentRegion>;
  DeploymentRegionsConnection: ResolverTypeWrapper<DeploymentRegionsConnection>;
  DeploymentRegionsEdge: ResolverTypeWrapper<DeploymentRegionsEdge>;
  DetailedConnection: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DetailedConnection']
  >;
  Entitlement: ResolverTypeWrapper<Entitlement>;
  EntitlementFilterInput: EntitlementFilterInput;
  EntitlementLimitType: EntitlementLimitType;
  EntitlementLimitTypeOperationFilterInput: EntitlementLimitTypeOperationFilterInput;
  EntitlementPeriod: EntitlementPeriod;
  EntitlementPeriodOperationFilterInput: EntitlementPeriodOperationFilterInput;
  EntitlementsConnection: ResolverTypeWrapper<EntitlementsConnection>;
  EntitlementsEdge: ResolverTypeWrapper<EntitlementsEdge>;
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>;
  Icon: ResolverTypeWrapper<Icon>;
  IconFilterInput: IconFilterInput;
  IconTypeFilterInput: IconTypeFilterInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Invitation: ResolverTypeWrapper<Invitation>;
  InvitationAction: ResolverTypeWrapper<InvitationAction>;
  InvitationAppearance: ResolverTypeWrapper<InvitationAppearance>;
  InvitationApplicationRoleInput: InvitationApplicationRoleInput;
  InvitationFilterInput: InvitationFilterInput;
  InvitationIdFilterInput: InvitationIdFilterInput;
  InvitationOrganizationRoleInput: InvitationOrganizationRoleInput;
  InvitationsCollectionSegment: ResolverTypeWrapper<InvitationsCollectionSegment>;
  InviteOrganizationMemberError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['InviteOrganizationMemberError']
  >;
  InviteOrganizationMemberInput: InviteOrganizationMemberInput;
  InviteOrganizationMemberPayload: ResolverTypeWrapper<
    Omit<InviteOrganizationMemberPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['InviteOrganizationMemberError']>>;
    }
  >;
  KeyValuePairOfStringAndString: ResolverTypeWrapper<KeyValuePairOfStringAndString>;
  KeyValuePairOfStringAndStringFilterInput: KeyValuePairOfStringAndStringFilterInput;
  KeyValuePairOfStringAndString__: ResolverTypeWrapper<KeyValuePairOfStringAndString__>;
  KeyValuePairOfStringAndString__Input: KeyValuePairOfStringAndString__Input;
  Link: ResolverTypeWrapper<Link>;
  LinkFilterInput: LinkFilterInput;
  LinkTypeFilterInput: LinkTypeFilterInput;
  ListFilterInputTypeOfEntitlementFilterInput: ListFilterInputTypeOfEntitlementFilterInput;
  ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput: ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput;
  ListFilterInputTypeOfUserApplicationRoleFilterInput: ListFilterInputTypeOfUserApplicationRoleFilterInput;
  ListFilterInputTypeOfUserOrganizationRoleFilterInput: ListFilterInputTypeOfUserOrganizationRoleFilterInput;
  ListStringOperationFilterInput: ListStringOperationFilterInput;
  MemberActionsConnection: ResolverTypeWrapper<MemberActionsConnection>;
  MemberActionsEdge: ResolverTypeWrapper<MemberActionsEdge>;
  MembersCollectionSegment: ResolverTypeWrapper<MembersCollectionSegment>;
  MultiplierPath: ResolverTypeWrapper<Scalars['MultiplierPath']['output']>;
  Mutation: ResolverTypeWrapper<{}>;
  NonVerifiedConnection: ResolverTypeWrapper<
    Omit<NonVerifiedConnection, 'options'> & {
      options: ResolversTypes['ConnectionOptions'];
    }
  >;
  NotFoundError: ResolverTypeWrapper<NotFoundError>;
  OidcConnectionOptions: ResolverTypeWrapper<OidcConnectionOptions>;
  OidcOptionsInput: OidcOptionsInput;
  OidcType: OidcType;
  Organization: ResolverTypeWrapper<
    Omit<Organization, 'connectionById'> & {
      connectionById?: Maybe<ResolversTypes['DetailedConnection']>;
    }
  >;
  OrganizationAppearance: ResolverTypeWrapper<OrganizationAppearance>;
  OrganizationApplication: ResolverTypeWrapper<OrganizationApplication>;
  OrganizationApplicationFilterInput: OrganizationApplicationFilterInput;
  OrganizationIdFilterInput: OrganizationIdFilterInput;
  OrganizationMember: ResolverTypeWrapper<OrganizationMember>;
  OrganizationMemberAction: ResolverTypeWrapper<OrganizationMemberAction>;
  OrganizationMemberActionFilterInput: OrganizationMemberActionFilterInput;
  OrganizationMemberAppearance: ResolverTypeWrapper<OrganizationMemberAppearance>;
  OrganizationMemberApplicationRole: ResolverTypeWrapper<OrganizationMemberApplicationRole>;
  OrganizationMemberApplicationRoleInput: OrganizationMemberApplicationRoleInput;
  OrganizationMemberApplicationsConnection: ResolverTypeWrapper<OrganizationMemberApplicationsConnection>;
  OrganizationMemberApplicationsEdge: ResolverTypeWrapper<OrganizationMemberApplicationsEdge>;
  OrganizationMemberOrganizationRole: ResolverTypeWrapper<OrganizationMemberOrganizationRole>;
  OrganizationMemberOrganizationRoleInput: OrganizationMemberOrganizationRoleInput;
  OrganizationMembersQueryInput: OrganizationMembersQueryInput;
  OrganizationRolesConnection: ResolverTypeWrapper<OrganizationRolesConnection>;
  OrganizationRolesEdge: ResolverTypeWrapper<OrganizationRolesEdge>;
  OrganizationType: OrganizationType;
  OrganizationsCollectionSegment: ResolverTypeWrapper<OrganizationsCollectionSegment>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PersonalizedContent: ResolverTypeWrapper<PersonalizedContent>;
  Product: ResolverTypeWrapper<Product>;
  ProductCodeFilterInput: ProductCodeFilterInput;
  ProductPlan: ProductPlan;
  ProductProvisioningConflictError: ResolverTypeWrapper<ProductProvisioningConflictError>;
  ProductProvisioningValidationError: ResolverTypeWrapper<ProductProvisioningValidationError>;
  ProductRole: ResolverTypeWrapper<ProductRole>;
  ProtocolBinding: ProtocolBinding;
  ProvisionProductError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ProvisionProductError']
  >;
  ProvisionProductInput: ProvisionProductInput;
  ProvisionProductPayload: ResolverTypeWrapper<
    Omit<ProvisionProductPayload, 'errors' | 'product'> & {
      errors?: Maybe<Array<ResolversTypes['ProvisionProductError']>>;
      product?: Maybe<ResolversTypes['ProvisionedProduct']>;
    }
  >;
  ProvisionedConnectProduct: ResolverTypeWrapper<ProvisionedConnectProduct>;
  ProvisionedProduct: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ProvisionedProduct']
  >;
  ProvisionedProductStatus: ProvisionedProductStatus;
  Query: ResolverTypeWrapper<{}>;
  RecommendationCard: ResolverTypeWrapper<RecommendationCard>;
  RevokeMembershipBadRequest: ResolverTypeWrapper<RevokeMembershipBadRequest>;
  RevokeMembershipError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['RevokeMembershipError']
  >;
  RevokeMembershipInput: RevokeMembershipInput;
  RevokeMembershipPayload: ResolverTypeWrapper<
    Omit<RevokeMembershipPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['RevokeMembershipError']>>;
    }
  >;
  RevokeMembershipUnknownError: ResolverTypeWrapper<RevokeMembershipUnknownError>;
  RolesConnection: ResolverTypeWrapper<RolesConnection>;
  RolesEdge: ResolverTypeWrapper<RolesEdge>;
  SamlKeyCert: ResolverTypeWrapper<SamlKeyCert>;
  SamlKeyCertInput: SamlKeyCertInput;
  SamlpConnectionOptions: ResolverTypeWrapper<SamlpConnectionOptions>;
  SamlpOptionsInput: SamlpOptionsInput;
  SellableProductCodeFilterInput: SellableProductCodeFilterInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringOperationFilterInput: StringOperationFilterInput;
  Subscription: ResolverTypeWrapper<Subscription>;
  SubscriptionFilterInput: SubscriptionFilterInput;
  SubscriptionIdFilterInput: SubscriptionIdFilterInput;
  SubscriptionsConnection: ResolverTypeWrapper<SubscriptionsConnection>;
  SubscriptionsEdge: ResolverTypeWrapper<SubscriptionsEdge>;
  UnknownError: ResolverTypeWrapper<UnknownError>;
  UpdateConnectionError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateConnectionError']
  >;
  UpdateConnectionInput: UpdateConnectionInput;
  UpdateConnectionNotFoundError: ResolverTypeWrapper<UpdateConnectionNotFoundError>;
  UpdateConnectionOptionsInput: UpdateConnectionOptionsInput;
  UpdateConnectionPayload: ResolverTypeWrapper<
    Omit<UpdateConnectionPayload, 'connection' | 'errors'> & {
      connection?: Maybe<ResolversTypes['DetailedConnection']>;
      errors?: Maybe<Array<ResolversTypes['UpdateConnectionError']>>;
    }
  >;
  UpdateConnectionValidationError: ResolverTypeWrapper<UpdateConnectionValidationError>;
  UpdateContextUserDetailsError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateContextUserDetailsError']
  >;
  UpdateContextUserDetailsInput: UpdateContextUserDetailsInput;
  UpdateContextUserDetailsPayload: ResolverTypeWrapper<
    Omit<UpdateContextUserDetailsPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['UpdateContextUserDetailsError']>>;
    }
  >;
  UpdateOidcOptionsInput: UpdateOidcOptionsInput;
  UpdateOrgMemberRolesBadRequest: ResolverTypeWrapper<UpdateOrgMemberRolesBadRequest>;
  UpdateOrgMemberRolesError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateOrgMemberRolesError']
  >;
  UpdateOrgMemberRolesNotFound: ResolverTypeWrapper<UpdateOrgMemberRolesNotFound>;
  UpdateOrgMemberRolesUnknownError: ResolverTypeWrapper<UpdateOrgMemberRolesUnknownError>;
  UpdateOrganizationMemberRolesInput: UpdateOrganizationMemberRolesInput;
  UpdateOrganizationMemberRolesPayload: ResolverTypeWrapper<
    Omit<UpdateOrganizationMemberRolesPayload, 'errors'> & {
      errors?: Maybe<Array<ResolversTypes['UpdateOrgMemberRolesError']>>;
    }
  >;
  Usage: ResolverTypeWrapper<Usage>;
  UserApplication: ResolverTypeWrapper<UserApplication>;
  UserApplicationAppearance: ResolverTypeWrapper<UserApplicationAppearance>;
  UserApplicationRole: ResolverTypeWrapper<UserApplicationRole>;
  UserApplicationRoleFilterInput: UserApplicationRoleFilterInput;
  UserOrganization: ResolverTypeWrapper<UserOrganization>;
  UserOrganizationRole: ResolverTypeWrapper<UserOrganizationRole>;
  UserOrganizationRoleFilterInput: UserOrganizationRoleFilterInput;
  VerifiedConnection: ResolverTypeWrapper<
    Omit<VerifiedConnection, 'options'> & {
      options: ResolversTypes['ConnectionOptions'];
    }
  >;
  VerifyConnectionBadRequestError: ResolverTypeWrapper<VerifyConnectionBadRequestError>;
  VerifyConnectionConflictError: ResolverTypeWrapper<VerifyConnectionConflictError>;
  VerifyConnectionError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['VerifyConnectionError']
  >;
  VerifyConnectionInput: VerifyConnectionInput;
  VerifyConnectionNotFoundError: ResolverTypeWrapper<VerifyConnectionNotFoundError>;
  VerifyConnectionPayload: ResolverTypeWrapper<
    Omit<VerifyConnectionPayload, 'connection' | 'errors'> & {
      connection?: Maybe<ResolversTypes['DetailedConnection']>;
      errors?: Maybe<Array<ResolversTypes['VerifyConnectionError']>>;
    }
  >;
  WebOrganizationAppearance: ResolverTypeWrapper<WebOrganizationAppearance>;
  WebUserApplicationAppearance: ResolverTypeWrapper<WebUserApplicationAppearance>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountIdFilterInput: AccountIdFilterInput;
  Action: Action;
  ActionCategoryFilterInput: ActionCategoryFilterInput;
  ActionFilterInput: ActionFilterInput;
  ActionsConnection: ActionsConnection;
  ActionsEdge: ActionsEdge;
  ActivateConnectionBadRequestError: ActivateConnectionBadRequestError;
  ActivateConnectionConflictError: ActivateConnectionConflictError;
  ActivateConnectionError: ResolversUnionTypes<ResolversParentTypes>['ActivateConnectionError'];
  ActivateConnectionInput: ActivateConnectionInput;
  ActivateConnectionNotFoundError: ActivateConnectionNotFoundError;
  ActivateConnectionPayload: Omit<ActivateConnectionPayload, 'errors'> & {
    errors?: Maybe<Array<ResolversParentTypes['ActivateConnectionError']>>;
  };
  ApplicationIdFilterInput: ApplicationIdFilterInput;
  ApplicationsConnection: ApplicationsConnection;
  ApplicationsEdge: ApplicationsEdge;
  AssignedRolesConnection: AssignedRolesConnection;
  AssignedRolesEdge: AssignedRolesEdge;
  BadRequestError: BadRequestError;
  Boolean: Scalars['Boolean']['output'];
  CollectionSegmentInfo: CollectionSegmentInfo;
  ColorFilterInput: ColorFilterInput;
  ConflictError: ConflictError;
  ConnectProductInput: ConnectProductInput;
  Connection: Connection;
  ConnectionAction: ConnectionAction;
  ConnectionAppearance: ConnectionAppearance;
  ConnectionOptions: ResolversUnionTypes<ResolversParentTypes>['ConnectionOptions'];
  ConnectionVerification: ConnectionVerification;
  ConnectionVerificationMethod: ConnectionVerificationMethod;
  ConnectionsCollectionSegment: ConnectionsCollectionSegment;
  Consumption: Consumption;
  ConsumptionPeriod: ConsumptionPeriod;
  ContextUser: ContextUser;
  ContextUserApplication: ContextUserApplication;
  ContextUserApplicationFilterInput: ContextUserApplicationFilterInput;
  ContextUserApplicationsConnection: ContextUserApplicationsConnection;
  ContextUserApplicationsEdge: ContextUserApplicationsEdge;
  ContextUserCannotBeUpdatedError: ContextUserCannotBeUpdatedError;
  ContextUserDetails: ContextUserDetails;
  ContextUserNotFoundError: ContextUserNotFoundError;
  ContextUserUpdateUnknownError: ContextUserUpdateUnknownError;
  ContextUserUpdateValidationError: ContextUserUpdateValidationError;
  CreateConnectionConflictError: CreateConnectionConflictError;
  CreateConnectionError: ResolversUnionTypes<ResolversParentTypes>['CreateConnectionError'];
  CreateConnectionInput: CreateConnectionInput;
  CreateConnectionOptionsInput: CreateConnectionOptionsInput;
  CreateConnectionPayload: Omit<CreateConnectionPayload, 'errors'> & {
    errors?: Maybe<Array<ResolversParentTypes['CreateConnectionError']>>;
  };
  CreateConnectionValidationError: CreateConnectionValidationError;
  Date: Scalars['Date']['output'];
  DateOperationFilterInput: DateOperationFilterInput;
  DateTime: Scalars['DateTime']['output'];
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: Scalars['Decimal']['output'];
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DeleteConnectionBadRequestError: DeleteConnectionBadRequestError;
  DeleteConnectionError: ResolversUnionTypes<ResolversParentTypes>['DeleteConnectionError'];
  DeleteConnectionInput: DeleteConnectionInput;
  DeleteConnectionNotFoundError: DeleteConnectionNotFoundError;
  DeleteConnectionPayload: Omit<DeleteConnectionPayload, 'errors'> & {
    errors?: Maybe<Array<ResolversParentTypes['DeleteConnectionError']>>;
  };
  DeleteInvitationError: ResolversUnionTypes<ResolversParentTypes>['DeleteInvitationError'];
  DeleteInvitationInput: DeleteInvitationInput;
  DeleteInvitationPayload: Omit<DeleteInvitationPayload, 'errors'> & {
    errors?: Maybe<Array<ResolversParentTypes['DeleteInvitationError']>>;
  };
  DeploymentRegion: DeploymentRegion;
  DeploymentRegionsConnection: DeploymentRegionsConnection;
  DeploymentRegionsEdge: DeploymentRegionsEdge;
  DetailedConnection: ResolversUnionTypes<ResolversParentTypes>['DetailedConnection'];
  Entitlement: Entitlement;
  EntitlementFilterInput: EntitlementFilterInput;
  EntitlementLimitTypeOperationFilterInput: EntitlementLimitTypeOperationFilterInput;
  EntitlementPeriodOperationFilterInput: EntitlementPeriodOperationFilterInput;
  EntitlementsConnection: EntitlementsConnection;
  EntitlementsEdge: EntitlementsEdge;
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error'];
  Icon: Icon;
  IconFilterInput: IconFilterInput;
  IconTypeFilterInput: IconTypeFilterInput;
  Int: Scalars['Int']['output'];
  Invitation: Invitation;
  InvitationAction: InvitationAction;
  InvitationAppearance: InvitationAppearance;
  InvitationApplicationRoleInput: InvitationApplicationRoleInput;
  InvitationFilterInput: InvitationFilterInput;
  InvitationIdFilterInput: InvitationIdFilterInput;
  InvitationOrganizationRoleInput: InvitationOrganizationRoleInput;
  InvitationsCollectionSegment: InvitationsCollectionSegment;
  InviteOrganizationMemberError: ResolversUnionTypes<ResolversParentTypes>['InviteOrganizationMemberError'];
  InviteOrganizationMemberInput: InviteOrganizationMemberInput;
  InviteOrganizationMemberPayload: Omit<
    InviteOrganizationMemberPayload,
    'errors'
  > & {
    errors?: Maybe<
      Array<ResolversParentTypes['InviteOrganizationMemberError']>
    >;
  };
  KeyValuePairOfStringAndString: KeyValuePairOfStringAndString;
  KeyValuePairOfStringAndStringFilterInput: KeyValuePairOfStringAndStringFilterInput;
  KeyValuePairOfStringAndString__: KeyValuePairOfStringAndString__;
  KeyValuePairOfStringAndString__Input: KeyValuePairOfStringAndString__Input;
  Link: Link;
  LinkFilterInput: LinkFilterInput;
  LinkTypeFilterInput: LinkTypeFilterInput;
  ListFilterInputTypeOfEntitlementFilterInput: ListFilterInputTypeOfEntitlementFilterInput;
  ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput: ListFilterInputTypeOfKeyValuePairOfStringAndStringFilterInput;
  ListFilterInputTypeOfUserApplicationRoleFilterInput: ListFilterInputTypeOfUserApplicationRoleFilterInput;
  ListFilterInputTypeOfUserOrganizationRoleFilterInput: ListFilterInputTypeOfUserOrganizationRoleFilterInput;
  ListStringOperationFilterInput: ListStringOperationFilterInput;
  MemberActionsConnection: MemberActionsConnection;
  MemberActionsEdge: MemberActionsEdge;
  MembersCollectionSegment: MembersCollectionSegment;
  MultiplierPath: Scalars['MultiplierPath']['output'];
  Mutation: {};
  NonVerifiedConnection: Omit<NonVerifiedConnection, 'options'> & {
    options: ResolversParentTypes['ConnectionOptions'];
  };
  NotFoundError: NotFoundError;
  OidcConnectionOptions: OidcConnectionOptions;
  OidcOptionsInput: OidcOptionsInput;
  Organization: Omit<Organization, 'connectionById'> & {
    connectionById?: Maybe<ResolversParentTypes['DetailedConnection']>;
  };
  OrganizationAppearance: OrganizationAppearance;
  OrganizationApplication: OrganizationApplication;
  OrganizationApplicationFilterInput: OrganizationApplicationFilterInput;
  OrganizationIdFilterInput: OrganizationIdFilterInput;
  OrganizationMember: OrganizationMember;
  OrganizationMemberAction: OrganizationMemberAction;
  OrganizationMemberActionFilterInput: OrganizationMemberActionFilterInput;
  OrganizationMemberAppearance: OrganizationMemberAppearance;
  OrganizationMemberApplicationRole: OrganizationMemberApplicationRole;
  OrganizationMemberApplicationRoleInput: OrganizationMemberApplicationRoleInput;
  OrganizationMemberApplicationsConnection: OrganizationMemberApplicationsConnection;
  OrganizationMemberApplicationsEdge: OrganizationMemberApplicationsEdge;
  OrganizationMemberOrganizationRole: OrganizationMemberOrganizationRole;
  OrganizationMemberOrganizationRoleInput: OrganizationMemberOrganizationRoleInput;
  OrganizationMembersQueryInput: OrganizationMembersQueryInput;
  OrganizationRolesConnection: OrganizationRolesConnection;
  OrganizationRolesEdge: OrganizationRolesEdge;
  OrganizationsCollectionSegment: OrganizationsCollectionSegment;
  PageInfo: PageInfo;
  PersonalizedContent: PersonalizedContent;
  Product: Product;
  ProductCodeFilterInput: ProductCodeFilterInput;
  ProductProvisioningConflictError: ProductProvisioningConflictError;
  ProductProvisioningValidationError: ProductProvisioningValidationError;
  ProductRole: ProductRole;
  ProvisionProductError: ResolversUnionTypes<ResolversParentTypes>['ProvisionProductError'];
  ProvisionProductInput: ProvisionProductInput;
  ProvisionProductPayload: Omit<
    ProvisionProductPayload,
    'errors' | 'product'
  > & {
    errors?: Maybe<Array<ResolversParentTypes['ProvisionProductError']>>;
    product?: Maybe<ResolversParentTypes['ProvisionedProduct']>;
  };
  ProvisionedConnectProduct: ProvisionedConnectProduct;
  ProvisionedProduct: ResolversUnionTypes<ResolversParentTypes>['ProvisionedProduct'];
  Query: {};
  RecommendationCard: RecommendationCard;
  RevokeMembershipBadRequest: RevokeMembershipBadRequest;
  RevokeMembershipError: ResolversUnionTypes<ResolversParentTypes>['RevokeMembershipError'];
  RevokeMembershipInput: RevokeMembershipInput;
  RevokeMembershipPayload: Omit<RevokeMembershipPayload, 'errors'> & {
    errors?: Maybe<Array<ResolversParentTypes['RevokeMembershipError']>>;
  };
  RevokeMembershipUnknownError: RevokeMembershipUnknownError;
  RolesConnection: RolesConnection;
  RolesEdge: RolesEdge;
  SamlKeyCert: SamlKeyCert;
  SamlKeyCertInput: SamlKeyCertInput;
  SamlpConnectionOptions: SamlpConnectionOptions;
  SamlpOptionsInput: SamlpOptionsInput;
  SellableProductCodeFilterInput: SellableProductCodeFilterInput;
  String: Scalars['String']['output'];
  StringOperationFilterInput: StringOperationFilterInput;
  Subscription: Subscription;
  SubscriptionFilterInput: SubscriptionFilterInput;
  SubscriptionIdFilterInput: SubscriptionIdFilterInput;
  SubscriptionsConnection: SubscriptionsConnection;
  SubscriptionsEdge: SubscriptionsEdge;
  UnknownError: UnknownError;
  UpdateConnectionError: ResolversUnionTypes<ResolversParentTypes>['UpdateConnectionError'];
  UpdateConnectionInput: UpdateConnectionInput;
  UpdateConnectionNotFoundError: UpdateConnectionNotFoundError;
  UpdateConnectionOptionsInput: UpdateConnectionOptionsInput;
  UpdateConnectionPayload: Omit<
    UpdateConnectionPayload,
    'connection' | 'errors'
  > & {
    connection?: Maybe<ResolversParentTypes['DetailedConnection']>;
    errors?: Maybe<Array<ResolversParentTypes['UpdateConnectionError']>>;
  };
  UpdateConnectionValidationError: UpdateConnectionValidationError;
  UpdateContextUserDetailsError: ResolversUnionTypes<ResolversParentTypes>['UpdateContextUserDetailsError'];
  UpdateContextUserDetailsInput: UpdateContextUserDetailsInput;
  UpdateContextUserDetailsPayload: Omit<
    UpdateContextUserDetailsPayload,
    'errors'
  > & {
    errors?: Maybe<
      Array<ResolversParentTypes['UpdateContextUserDetailsError']>
    >;
  };
  UpdateOidcOptionsInput: UpdateOidcOptionsInput;
  UpdateOrgMemberRolesBadRequest: UpdateOrgMemberRolesBadRequest;
  UpdateOrgMemberRolesError: ResolversUnionTypes<ResolversParentTypes>['UpdateOrgMemberRolesError'];
  UpdateOrgMemberRolesNotFound: UpdateOrgMemberRolesNotFound;
  UpdateOrgMemberRolesUnknownError: UpdateOrgMemberRolesUnknownError;
  UpdateOrganizationMemberRolesInput: UpdateOrganizationMemberRolesInput;
  UpdateOrganizationMemberRolesPayload: Omit<
    UpdateOrganizationMemberRolesPayload,
    'errors'
  > & {
    errors?: Maybe<Array<ResolversParentTypes['UpdateOrgMemberRolesError']>>;
  };
  Usage: Usage;
  UserApplication: UserApplication;
  UserApplicationAppearance: UserApplicationAppearance;
  UserApplicationRole: UserApplicationRole;
  UserApplicationRoleFilterInput: UserApplicationRoleFilterInput;
  UserOrganization: UserOrganization;
  UserOrganizationRole: UserOrganizationRole;
  UserOrganizationRoleFilterInput: UserOrganizationRoleFilterInput;
  VerifiedConnection: Omit<VerifiedConnection, 'options'> & {
    options: ResolversParentTypes['ConnectionOptions'];
  };
  VerifyConnectionBadRequestError: VerifyConnectionBadRequestError;
  VerifyConnectionConflictError: VerifyConnectionConflictError;
  VerifyConnectionError: ResolversUnionTypes<ResolversParentTypes>['VerifyConnectionError'];
  VerifyConnectionInput: VerifyConnectionInput;
  VerifyConnectionNotFoundError: VerifyConnectionNotFoundError;
  VerifyConnectionPayload: Omit<
    VerifyConnectionPayload,
    'connection' | 'errors'
  > & {
    connection?: Maybe<ResolversParentTypes['DetailedConnection']>;
    errors?: Maybe<Array<ResolversParentTypes['VerifyConnectionError']>>;
  };
  WebOrganizationAppearance: WebOrganizationAppearance;
  WebUserApplicationAppearance: WebUserApplicationAppearance;
};

export type CostDirectiveArgs = {
  complexity?: Scalars['Int']['input'];
  defaultMultiplier?: Maybe<Scalars['Int']['input']>;
  multipliers?: Maybe<Array<Scalars['MultiplierPath']['input']>>;
};

export type CostDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CostDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OneOfDirectiveArgs = {};

export type OneOfDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = OneOfDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Action'] = ResolversParentTypes['Action'],
> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  icon?: Resolver<ResolversTypes['Icon'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  location?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActionsConnection'] = ResolversParentTypes['ActionsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['ActionsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['Action']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActionsEdge'] = ResolversParentTypes['ActionsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Action'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateConnectionBadRequestErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivateConnectionBadRequestError'] = ResolversParentTypes['ActivateConnectionBadRequestError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateConnectionConflictErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivateConnectionConflictError'] = ResolversParentTypes['ActivateConnectionConflictError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivateConnectionError'] = ResolversParentTypes['ActivateConnectionError'],
> = {
  __resolveType: TypeResolveFn<
    | 'ActivateConnectionBadRequestError'
    | 'ActivateConnectionConflictError'
    | 'ActivateConnectionNotFoundError'
    | 'UnknownError',
    ParentType,
    ContextType
  >;
};

export type ActivateConnectionNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivateConnectionNotFoundError'] = ResolversParentTypes['ActivateConnectionNotFoundError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivateConnectionPayload'] = ResolversParentTypes['ActivateConnectionPayload'],
> = {
  connection?: Resolver<
    Maybe<ResolversTypes['VerifiedConnection']>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['ActivateConnectionError']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationsConnection'] = ResolversParentTypes['ApplicationsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['ApplicationsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationApplication']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationsEdge'] = ResolversParentTypes['ApplicationsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['OrganizationApplication'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedRolesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssignedRolesConnection'] = ResolversParentTypes['AssignedRolesConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['AssignedRolesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['UserApplicationRole']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedRolesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssignedRolesEdge'] = ResolversParentTypes['AssignedRolesEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['UserApplicationRole'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadRequestErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BadRequestError'] = ResolversParentTypes['BadRequestError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionSegmentInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectionSegmentInfo'] = ResolversParentTypes['CollectionSegmentInfo'],
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConflictErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConflictError'] = ResolversParentTypes['ConflictError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Connection'] = ResolversParentTypes['Connection'],
> = {
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  appearance?: Resolver<
    ResolversTypes['ConnectionAppearance'],
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['ConnectionState'], ParentType, ContextType>;
  strategy?: Resolver<
    ResolversTypes['ConnectionStrategy'],
    ParentType,
    ContextType
  >;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionAction'] = ResolversParentTypes['ConnectionAction'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['Icon']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionAppearance'] = ResolversParentTypes['ConnectionAppearance'],
> = {
  actions?: Resolver<
    Array<ResolversTypes['ConnectionAction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionOptions'] = ResolversParentTypes['ConnectionOptions'],
> = {
  __resolveType: TypeResolveFn<
    'OidcConnectionOptions' | 'SamlpConnectionOptions',
    ParentType,
    ContextType
  >;
};

export type ConnectionVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionVerification'] = ResolversParentTypes['ConnectionVerification'],
> = {
  methods?: Resolver<
    Array<ResolversTypes['ConnectionVerificationMethod']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionVerificationMethodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionVerificationMethod'] = ResolversParentTypes['ConnectionVerificationMethod'],
> = {
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  record?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionsCollectionSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionsCollectionSegment'] = ResolversParentTypes['ConnectionsCollectionSegment'],
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['Connection']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['CollectionSegmentInfo'],
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Consumption'] = ResolversParentTypes['Consumption'],
> = {
  currentPeriod?: Resolver<
    ResolversTypes['ConsumptionPeriod'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumptionPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConsumptionPeriod'] = ResolversParentTypes['ConsumptionPeriod'],
> = {
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  usages?: Resolver<Array<ResolversTypes['Usage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUser'] = ResolversParentTypes['ContextUser'],
> = {
  applications?: Resolver<
    Maybe<ResolversTypes['ContextUserApplicationsConnection']>,
    ParentType,
    ContextType,
    Partial<ContextUserApplicationsArgs>
  >;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  familyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  givenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isExternal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  jobRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastLogin?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationRoles?: Resolver<
    Maybe<ResolversTypes['OrganizationRolesConnection']>,
    ParentType,
    ContextType,
    Partial<ContextUserOrganizationRolesArgs>
  >;
  organizations?: Resolver<
    Maybe<ResolversTypes['OrganizationsCollectionSegment']>,
    ParentType,
    ContextType,
    Partial<ContextUserOrganizationsArgs>
  >;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signUpCompleted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  updated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserApplication'] = ResolversParentTypes['ContextUserApplication'],
> = {
  appearance?: Resolver<
    Maybe<ResolversTypes['UserApplicationAppearance']>,
    ParentType,
    ContextType
  >;
  assignedRoles?: Resolver<
    Maybe<ResolversTypes['AssignedRolesConnection']>,
    ParentType,
    ContextType,
    Partial<ContextUserApplicationAssignedRolesArgs>
  >;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<
    Array<ResolversTypes['KeyValuePairOfStringAndString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserApplicationsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserApplicationsConnection'] = ResolversParentTypes['ContextUserApplicationsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['ContextUserApplicationsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['ContextUserApplication']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserApplicationsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserApplicationsEdge'] = ResolversParentTypes['ContextUserApplicationsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['ContextUserApplication'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserCannotBeUpdatedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserCannotBeUpdatedError'] = ResolversParentTypes['ContextUserCannotBeUpdatedError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserDetails'] = ResolversParentTypes['ContextUserDetails'],
> = {
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  emailVerified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  familyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  givenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isExternal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  jobRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastLogin?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signUpCompleted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  updated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserNotFoundError'] = ResolversParentTypes['ContextUserNotFoundError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserUpdateUnknownErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserUpdateUnknownError'] = ResolversParentTypes['ContextUserUpdateUnknownError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextUserUpdateValidationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContextUserUpdateValidationError'] = ResolversParentTypes['ContextUserUpdateValidationError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateConnectionConflictErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateConnectionConflictError'] = ResolversParentTypes['CreateConnectionConflictError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateConnectionError'] = ResolversParentTypes['CreateConnectionError'],
> = {
  __resolveType: TypeResolveFn<
    'CreateConnectionConflictError' | 'CreateConnectionValidationError',
    ParentType,
    ContextType
  >;
};

export type CreateConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateConnectionPayload'] = ResolversParentTypes['CreateConnectionPayload'],
> = {
  connection?: Resolver<
    Maybe<ResolversTypes['NonVerifiedConnection']>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['CreateConnectionError']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateConnectionValidationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateConnectionValidationError'] = ResolversParentTypes['CreateConnectionValidationError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal';
}

export type DeleteConnectionBadRequestErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteConnectionBadRequestError'] = ResolversParentTypes['DeleteConnectionBadRequestError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteConnectionError'] = ResolversParentTypes['DeleteConnectionError'],
> = {
  __resolveType: TypeResolveFn<
    'DeleteConnectionBadRequestError' | 'DeleteConnectionNotFoundError',
    ParentType,
    ContextType
  >;
};

export type DeleteConnectionNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteConnectionNotFoundError'] = ResolversParentTypes['DeleteConnectionNotFoundError'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteConnectionPayload'] = ResolversParentTypes['DeleteConnectionPayload'],
> = {
  errors?: Resolver<
    Maybe<Array<ResolversTypes['DeleteConnectionError']>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteInvitationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteInvitationError'] = ResolversParentTypes['DeleteInvitationError'],
> = {
  __resolveType: TypeResolveFn<
    'BadRequestError' | 'NotFoundError',
    ParentType,
    ContextType
  >;
};

export type DeleteInvitationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteInvitationPayload'] = ResolversParentTypes['DeleteInvitationPayload'],
> = {
  errors?: Resolver<
    Maybe<Array<ResolversTypes['DeleteInvitationError']>>,
    ParentType,
    ContextType
  >;
  invitationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeploymentRegionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeploymentRegion'] = ResolversParentTypes['DeploymentRegion'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeploymentRegionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeploymentRegionsConnection'] = ResolversParentTypes['DeploymentRegionsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['DeploymentRegionsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['DeploymentRegion']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeploymentRegionsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeploymentRegionsEdge'] = ResolversParentTypes['DeploymentRegionsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DeploymentRegion'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailedConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailedConnection'] = ResolversParentTypes['DetailedConnection'],
> = {
  __resolveType: TypeResolveFn<
    'NonVerifiedConnection' | 'VerifiedConnection',
    ParentType,
    ContextType
  >;
};

export type EntitlementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Entitlement'] = ResolversParentTypes['Entitlement'],
> = {
  consumption?: Resolver<
    Maybe<ResolversTypes['Consumption']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  lastReportedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  limitType?: Resolver<
    ResolversTypes['EntitlementLimitType'],
    ParentType,
    ContextType
  >;
  metric?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  periodType?: Resolver<
    ResolversTypes['EntitlementPeriod'],
    ParentType,
    ContextType
  >;
  quantity?: Resolver<
    Maybe<ResolversTypes['Decimal']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementsConnection'] = ResolversParentTypes['EntitlementsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['EntitlementsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['Entitlement']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementsEdge'] = ResolversParentTypes['EntitlementsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Entitlement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error'],
> = {
  __resolveType: TypeResolveFn<
    | 'ActivateConnectionBadRequestError'
    | 'ActivateConnectionConflictError'
    | 'ActivateConnectionNotFoundError'
    | 'BadRequestError'
    | 'ConflictError'
    | 'ContextUserCannotBeUpdatedError'
    | 'ContextUserNotFoundError'
    | 'ContextUserUpdateUnknownError'
    | 'ContextUserUpdateValidationError'
    | 'CreateConnectionConflictError'
    | 'CreateConnectionValidationError'
    | 'DeleteConnectionBadRequestError'
    | 'DeleteConnectionNotFoundError'
    | 'NotFoundError'
    | 'ProductProvisioningConflictError'
    | 'ProductProvisioningValidationError'
    | 'UnknownError'
    | 'UpdateConnectionNotFoundError'
    | 'UpdateConnectionValidationError'
    | 'VerifyConnectionBadRequestError'
    | 'VerifyConnectionConflictError'
    | 'VerifyConnectionNotFoundError',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type IconResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Icon'] = ResolversParentTypes['Icon'],
> = {
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Invitation'] = ResolversParentTypes['Invitation'],
> = {
  appearance?: Resolver<
    ResolversTypes['InvitationAppearance'],
    ParentType,
    ContextType
  >;
  applicationRoles?: Resolver<
    Array<ResolversTypes['UserApplicationRole']>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inviteeEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inviterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationRoles?: Resolver<
    Array<ResolversTypes['UserOrganizationRole']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvitationAction'] = ResolversParentTypes['InvitationAction'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvitationAppearance'] = ResolversParentTypes['InvitationAppearance'],
> = {
  actions?: Resolver<
    Array<ResolversTypes['InvitationAction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationsCollectionSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvitationsCollectionSegment'] = ResolversParentTypes['InvitationsCollectionSegment'],
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['Invitation']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['CollectionSegmentInfo'],
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteOrganizationMemberErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteOrganizationMemberError'] = ResolversParentTypes['InviteOrganizationMemberError'],
> = {
  __resolveType: TypeResolveFn<
    'BadRequestError' | 'ConflictError',
    ParentType,
    ContextType
  >;
};

export type InviteOrganizationMemberPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteOrganizationMemberPayload'] = ResolversParentTypes['InviteOrganizationMemberPayload'],
> = {
  errors?: Resolver<
    Maybe<Array<ResolversTypes['InviteOrganizationMemberError']>>,
    ParentType,
    ContextType
  >;
  invitation?: Resolver<
    Maybe<ResolversTypes['Invitation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValuePairOfStringAndStringResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeyValuePairOfStringAndString'] = ResolversParentTypes['KeyValuePairOfStringAndString'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValuePairOfStringAndString__Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeyValuePairOfStringAndString__'] = ResolversParentTypes['KeyValuePairOfStringAndString__'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link'],
> = {
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberActionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MemberActionsConnection'] = ResolversParentTypes['MemberActionsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['MemberActionsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberAction']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberActionsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MemberActionsEdge'] = ResolversParentTypes['MemberActionsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['OrganizationMemberAction'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembersCollectionSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembersCollectionSegment'] = ResolversParentTypes['MembersCollectionSegment'],
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMember']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['CollectionSegmentInfo'],
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MultiplierPathScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['MultiplierPath'], any> {
  name: 'MultiplierPath';
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  activateConnection?: Resolver<
    ResolversTypes['ActivateConnectionPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationActivateConnectionArgs, 'input'>
  >;
  createConnection?: Resolver<
    ResolversTypes['CreateConnectionPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateConnectionArgs, 'input'>
  >;
  deleteConnection?: Resolver<
    ResolversTypes['DeleteConnectionPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteConnectionArgs, 'input'>
  >;
  deleteInvitation?: Resolver<
    ResolversTypes['DeleteInvitationPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteInvitationArgs, 'input'>
  >;
  inviteOrganizationMember?: Resolver<
    ResolversTypes['InviteOrganizationMemberPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationInviteOrganizationMemberArgs, 'input'>
  >;
  provisionProduct?: Resolver<
    ResolversTypes['ProvisionProductPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationProvisionProductArgs, 'input'>
  >;
  revokeMembership?: Resolver<
    ResolversTypes['RevokeMembershipPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationRevokeMembershipArgs, 'input'>
  >;
  updateConnection?: Resolver<
    ResolversTypes['UpdateConnectionPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateConnectionArgs, 'input'>
  >;
  updateContextUserDetails?: Resolver<
    ResolversTypes['UpdateContextUserDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContextUserDetailsArgs, 'input'>
  >;
  updateOrganizationMemberRoles?: Resolver<
    ResolversTypes['UpdateOrganizationMemberRolesPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOrganizationMemberRolesArgs, 'input'>
  >;
  verifyConnection?: Resolver<
    ResolversTypes['VerifyConnectionPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationVerifyConnectionArgs, 'input'>
  >;
};

export type NonVerifiedConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonVerifiedConnection'] = ResolversParentTypes['NonVerifiedConnection'],
> = {
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<
    ResolversTypes['ConnectionOptions'],
    ParentType,
    ContextType
  >;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['ConnectionState'], ParentType, ContextType>;
  strategy?: Resolver<
    ResolversTypes['ConnectionStrategy'],
    ParentType,
    ContextType
  >;
  verification?: Resolver<
    Maybe<ResolversTypes['ConnectionVerification']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotFoundError'] = ResolversParentTypes['NotFoundError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OidcConnectionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OidcConnectionOptions'] = ResolversParentTypes['OidcConnectionOptions'],
> = {
  authorizationEndpoint?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discoveryUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  issuer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jwksUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenEndpoint?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['OidcType']>, ParentType, ContextType>;
  userInfoEndpoint?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization'],
> = {
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  appearance?: Resolver<
    Maybe<ResolversTypes['OrganizationAppearance']>,
    ParentType,
    ContextType
  >;
  applications?: Resolver<
    Maybe<ResolversTypes['ApplicationsConnection']>,
    ParentType,
    ContextType,
    Partial<OrganizationApplicationsArgs>
  >;
  connectionById?: Resolver<
    Maybe<ResolversTypes['DetailedConnection']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationConnectionByIdArgs, 'id'>
  >;
  connections?: Resolver<
    Maybe<ResolversTypes['ConnectionsCollectionSegment']>,
    ParentType,
    ContextType,
    Partial<OrganizationConnectionsArgs>
  >;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invitations?: Resolver<
    Maybe<ResolversTypes['InvitationsCollectionSegment']>,
    ParentType,
    ContextType,
    Partial<OrganizationInvitationsArgs>
  >;
  memberById?: Resolver<
    Maybe<ResolversTypes['OrganizationMember']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationMemberByIdArgs, 'userId'>
  >;
  members?: Resolver<
    Maybe<ResolversTypes['MembersCollectionSegment']>,
    ParentType,
    ContextType,
    Partial<OrganizationMembersArgs>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<
    Maybe<ResolversTypes['OrganizationRolesConnection']>,
    ParentType,
    ContextType,
    Partial<OrganizationRolesArgs>
  >;
  type?: Resolver<
    Maybe<ResolversTypes['OrganizationType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationAppearance'] = ResolversParentTypes['OrganizationAppearance'],
> = {
  web?: Resolver<
    ResolversTypes['WebOrganizationAppearance'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationApplication'] = ResolversParentTypes['OrganizationApplication'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<
    Array<ResolversTypes['KeyValuePairOfStringAndString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organization?: Resolver<
    Maybe<ResolversTypes['Organization']>,
    ParentType,
    ContextType
  >;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMember'] = ResolversParentTypes['OrganizationMember'],
> = {
  appearance?: Resolver<
    Maybe<ResolversTypes['OrganizationMemberAppearance']>,
    ParentType,
    ContextType
  >;
  applications?: Resolver<
    Maybe<ResolversTypes['OrganizationMemberApplicationsConnection']>,
    ParentType,
    ContextType,
    Partial<OrganizationMemberApplicationsArgs>
  >;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  familyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  givenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isExternal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  jobRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastLogin?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationRoles?: Resolver<
    Maybe<ResolversTypes['OrganizationRolesConnection']>,
    ParentType,
    ContextType,
    Partial<OrganizationMemberOrganizationRolesArgs>
  >;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signUpCompleted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  updated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberAction'] = ResolversParentTypes['OrganizationMemberAction'],
> = {
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberAppearance'] = ResolversParentTypes['OrganizationMemberAppearance'],
> = {
  memberActions?: Resolver<
    Maybe<ResolversTypes['MemberActionsConnection']>,
    ParentType,
    ContextType,
    Partial<OrganizationMemberAppearanceMemberActionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberApplicationRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberApplicationRole'] = ResolversParentTypes['OrganizationMemberApplicationRole'],
> = {
  applicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberApplicationsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberApplicationsConnection'] = ResolversParentTypes['OrganizationMemberApplicationsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberApplicationsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['UserApplication']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberApplicationsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberApplicationsEdge'] = ResolversParentTypes['OrganizationMemberApplicationsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['UserApplication'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMemberOrganizationRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationMemberOrganizationRole'] = ResolversParentTypes['OrganizationMemberOrganizationRole'],
> = {
  roleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationRolesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationRolesConnection'] = ResolversParentTypes['OrganizationRolesConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationRolesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['UserOrganizationRole']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationRolesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationRolesEdge'] = ResolversParentTypes['OrganizationRolesEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['UserOrganizationRole'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsCollectionSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsCollectionSegment'] = ResolversParentTypes['OrganizationsCollectionSegment'],
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['UserOrganization']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['CollectionSegmentInfo'],
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  endCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonalizedContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PersonalizedContent'] = ResolversParentTypes['PersonalizedContent'],
> = {
  recommendationCards?: Resolver<
    Array<ResolversTypes['RecommendationCard']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deploymentRegions?: Resolver<
    Maybe<ResolversTypes['DeploymentRegionsConnection']>,
    ParentType,
    ContextType,
    Partial<ProductDeploymentRegionsArgs>
  >;
  roles?: Resolver<
    Maybe<ResolversTypes['RolesConnection']>,
    ParentType,
    ContextType,
    Partial<ProductRolesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductProvisioningConflictErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductProvisioningConflictError'] = ResolversParentTypes['ProductProvisioningConflictError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductProvisioningValidationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductProvisioningValidationError'] = ResolversParentTypes['ProductProvisioningValidationError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductRole'] = ResolversParentTypes['ProductRole'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvisionProductErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProvisionProductError'] = ResolversParentTypes['ProvisionProductError'],
> = {
  __resolveType: TypeResolveFn<
    | 'ProductProvisioningConflictError'
    | 'ProductProvisioningValidationError'
    | 'UnknownError',
    ParentType,
    ContextType
  >;
};

export type ProvisionProductPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProvisionProductPayload'] = ResolversParentTypes['ProvisionProductPayload'],
> = {
  errors?: Resolver<
    Maybe<Array<ResolversTypes['ProvisionProductError']>>,
    ParentType,
    ContextType
  >;
  product?: Resolver<
    Maybe<ResolversTypes['ProvisionedProduct']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvisionedConnectProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProvisionedConnectProduct'] = ResolversParentTypes['ProvisionedConnectProduct'],
> = {
  auditLogsEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['ProductPlan'], ParentType, ContextType>;
  regionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['ProvisionedProductStatus'],
    ParentType,
    ContextType
  >;
  subscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trialMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvisionedProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProvisionedProduct'] = ResolversParentTypes['ProvisionedProduct'],
> = {
  __resolveType: TypeResolveFn<
    'ProvisionedConnectProduct',
    ParentType,
    ContextType
  >;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  organization?: Resolver<
    Maybe<ResolversTypes['Organization']>,
    ParentType,
    ContextType
  >;
  personalizedContent?: Resolver<
    Maybe<ResolversTypes['PersonalizedContent']>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType
  >;
  subscriptions?: Resolver<
    Maybe<ResolversTypes['SubscriptionsConnection']>,
    ParentType,
    ContextType,
    Partial<QuerySubscriptionsArgs>
  >;
  user?: Resolver<
    Maybe<ResolversTypes['ContextUser']>,
    ParentType,
    ContextType
  >;
};

export type RecommendationCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendationCard'] = ResolversParentTypes['RecommendationCard'],
> = {
  cardId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  icon?: Resolver<Maybe<ResolversTypes['Icon']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevokeMembershipBadRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevokeMembershipBadRequest'] = ResolversParentTypes['RevokeMembershipBadRequest'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  members?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevokeMembershipErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevokeMembershipError'] = ResolversParentTypes['RevokeMembershipError'],
> = {
  __resolveType: TypeResolveFn<
    'RevokeMembershipBadRequest' | 'RevokeMembershipUnknownError',
    ParentType,
    ContextType
  >;
};

export type RevokeMembershipPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevokeMembershipPayload'] = ResolversParentTypes['RevokeMembershipPayload'],
> = {
  errors?: Resolver<
    Maybe<Array<ResolversTypes['RevokeMembershipError']>>,
    ParentType,
    ContextType
  >;
  members?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevokeMembershipUnknownErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevokeMembershipUnknownError'] = ResolversParentTypes['RevokeMembershipUnknownError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RolesConnection'] = ResolversParentTypes['RolesConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['RolesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['ProductRole']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RolesEdge'] = ResolversParentTypes['RolesEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductRole'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SamlKeyCertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SamlKeyCert'] = ResolversParentTypes['SamlKeyCert'],
> = {
  cert?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SamlpConnectionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SamlpConnectionOptions'] = ResolversParentTypes['SamlpConnectionOptions'],
> = {
  decryptionKey?: Resolver<
    Maybe<ResolversTypes['SamlKeyCert']>,
    ParentType,
    ContextType
  >;
  disableSignOut?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  fieldsMap?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  metadataUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  metadataXml?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  protocolBinding?: Resolver<
    Maybe<ResolversTypes['ProtocolBinding']>,
    ParentType,
    ContextType
  >;
  requestSigningKey?: Resolver<
    Maybe<ResolversTypes['SamlKeyCert']>,
    ParentType,
    ContextType
  >;
  requestTemplate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signInEndpoint?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signOutEndpoint?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signSamlRequest?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  signingCert?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractEnd?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  contractStart?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  editionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entitlements?: Resolver<
    Maybe<ResolversTypes['EntitlementsConnection']>,
    ParentType,
    ContextType,
    Partial<SubscriptionEntitlementsArgs>
  >;
  productNames?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sellableProductCode?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  subscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionsConnection'] = ResolversParentTypes['SubscriptionsConnection'],
> = {
  edges?: Resolver<
    Maybe<Array<ResolversTypes['SubscriptionsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Maybe<Array<ResolversTypes['Subscription']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionsEdge'] = ResolversParentTypes['SubscriptionsEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnknownErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnknownError'] = ResolversParentTypes['UnknownError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateConnectionError'] = ResolversParentTypes['UpdateConnectionError'],
> = {
  __resolveType: TypeResolveFn<
    'UpdateConnectionNotFoundError' | 'UpdateConnectionValidationError',
    ParentType,
    ContextType
  >;
};

export type UpdateConnectionNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateConnectionNotFoundError'] = ResolversParentTypes['UpdateConnectionNotFoundError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateConnectionPayload'] = ResolversParentTypes['UpdateConnectionPayload'],
> = {
  connection?: Resolver<
    Maybe<ResolversTypes['DetailedConnection']>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['UpdateConnectionError']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateConnectionValidationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateConnectionValidationError'] = ResolversParentTypes['UpdateConnectionValidationError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateContextUserDetailsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateContextUserDetailsError'] = ResolversParentTypes['UpdateContextUserDetailsError'],
> = {
  __resolveType: TypeResolveFn<
    | 'ContextUserCannotBeUpdatedError'
    | 'ContextUserNotFoundError'
    | 'ContextUserUpdateUnknownError'
    | 'ContextUserUpdateValidationError',
    ParentType,
    ContextType
  >;
};

export type UpdateContextUserDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateContextUserDetailsPayload'] = ResolversParentTypes['UpdateContextUserDetailsPayload'],
> = {
  contextUserDetails?: Resolver<
    Maybe<ResolversTypes['ContextUserDetails']>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['UpdateContextUserDetailsError']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrgMemberRolesBadRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateOrgMemberRolesBadRequest'] = ResolversParentTypes['UpdateOrgMemberRolesBadRequest'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrgMemberRolesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateOrgMemberRolesError'] = ResolversParentTypes['UpdateOrgMemberRolesError'],
> = {
  __resolveType: TypeResolveFn<
    | 'UpdateOrgMemberRolesBadRequest'
    | 'UpdateOrgMemberRolesNotFound'
    | 'UpdateOrgMemberRolesUnknownError',
    ParentType,
    ContextType
  >;
};

export type UpdateOrgMemberRolesNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateOrgMemberRolesNotFound'] = ResolversParentTypes['UpdateOrgMemberRolesNotFound'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrgMemberRolesUnknownErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateOrgMemberRolesUnknownError'] = ResolversParentTypes['UpdateOrgMemberRolesUnknownError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrganizationMemberRolesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateOrganizationMemberRolesPayload'] = ResolversParentTypes['UpdateOrganizationMemberRolesPayload'],
> = {
  assignedApplicationRoles?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberApplicationRole']>>,
    ParentType,
    ContextType
  >;
  assignedOrganizationRoles?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberOrganizationRole']>>,
    ParentType,
    ContextType
  >;
  deletedApplicationRoles?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberApplicationRole']>>,
    ParentType,
    ContextType
  >;
  deletedOrganizationRoles?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationMemberOrganizationRole']>>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['UpdateOrgMemberRolesError']>>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Usage'] = ResolversParentTypes['Usage'],
> = {
  reportedFor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserApplication'] = ResolversParentTypes['UserApplication'],
> = {
  assignedRoles?: Resolver<
    Maybe<ResolversTypes['AssignedRolesConnection']>,
    ParentType,
    ContextType,
    Partial<UserApplicationAssignedRolesArgs>
  >;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<
    Array<ResolversTypes['KeyValuePairOfStringAndString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserApplicationAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserApplicationAppearance'] = ResolversParentTypes['UserApplicationAppearance'],
> = {
  web?: Resolver<
    ResolversTypes['WebUserApplicationAppearance'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserApplicationRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserApplicationRole'] = ResolversParentTypes['UserApplicationRole'],
> = {
  applicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserOrganization'] = ResolversParentTypes['UserOrganization'],
> = {
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  defaultLanguage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mfaRequired?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['OrganizationType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserOrganizationRole'] = ResolversParentTypes['UserOrganizationRole'],
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifiedConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifiedConnection'] = ResolversParentTypes['VerifiedConnection'],
> = {
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<
    ResolversTypes['ConnectionOptions'],
    ParentType,
    ContextType
  >;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['ConnectionState'], ParentType, ContextType>;
  strategy?: Resolver<
    ResolversTypes['ConnectionStrategy'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyConnectionBadRequestErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyConnectionBadRequestError'] = ResolversParentTypes['VerifyConnectionBadRequestError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyConnectionConflictErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyConnectionConflictError'] = ResolversParentTypes['VerifyConnectionConflictError'],
> = {
  errorDetails?: Resolver<
    Maybe<Array<ResolversTypes['KeyValuePairOfStringAndString__']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyConnectionError'] = ResolversParentTypes['VerifyConnectionError'],
> = {
  __resolveType: TypeResolveFn<
    | 'UnknownError'
    | 'VerifyConnectionBadRequestError'
    | 'VerifyConnectionConflictError'
    | 'VerifyConnectionNotFoundError',
    ParentType,
    ContextType
  >;
};

export type VerifyConnectionNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyConnectionNotFoundError'] = ResolversParentTypes['VerifyConnectionNotFoundError'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyConnectionPayload'] = ResolversParentTypes['VerifyConnectionPayload'],
> = {
  connection?: Resolver<
    Maybe<ResolversTypes['DetailedConnection']>,
    ParentType,
    ContextType
  >;
  errors?: Resolver<
    Maybe<Array<ResolversTypes['VerifyConnectionError']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebOrganizationAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WebOrganizationAppearance'] = ResolversParentTypes['WebOrganizationAppearance'],
> = {
  actions?: Resolver<
    Maybe<ResolversTypes['ActionsConnection']>,
    ParentType,
    ContextType,
    Partial<WebOrganizationAppearanceActionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebUserApplicationAppearanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WebUserApplicationAppearance'] = ResolversParentTypes['WebUserApplicationAppearance'],
> = {
  actions?: Resolver<
    Maybe<ResolversTypes['ActionsConnection']>,
    ParentType,
    ContextType,
    Partial<WebUserApplicationAppearanceActionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Action?: ActionResolvers<ContextType>;
  ActionsConnection?: ActionsConnectionResolvers<ContextType>;
  ActionsEdge?: ActionsEdgeResolvers<ContextType>;
  ActivateConnectionBadRequestError?: ActivateConnectionBadRequestErrorResolvers<ContextType>;
  ActivateConnectionConflictError?: ActivateConnectionConflictErrorResolvers<ContextType>;
  ActivateConnectionError?: ActivateConnectionErrorResolvers<ContextType>;
  ActivateConnectionNotFoundError?: ActivateConnectionNotFoundErrorResolvers<ContextType>;
  ActivateConnectionPayload?: ActivateConnectionPayloadResolvers<ContextType>;
  ApplicationsConnection?: ApplicationsConnectionResolvers<ContextType>;
  ApplicationsEdge?: ApplicationsEdgeResolvers<ContextType>;
  AssignedRolesConnection?: AssignedRolesConnectionResolvers<ContextType>;
  AssignedRolesEdge?: AssignedRolesEdgeResolvers<ContextType>;
  BadRequestError?: BadRequestErrorResolvers<ContextType>;
  CollectionSegmentInfo?: CollectionSegmentInfoResolvers<ContextType>;
  ConflictError?: ConflictErrorResolvers<ContextType>;
  Connection?: ConnectionResolvers<ContextType>;
  ConnectionAction?: ConnectionActionResolvers<ContextType>;
  ConnectionAppearance?: ConnectionAppearanceResolvers<ContextType>;
  ConnectionOptions?: ConnectionOptionsResolvers<ContextType>;
  ConnectionVerification?: ConnectionVerificationResolvers<ContextType>;
  ConnectionVerificationMethod?: ConnectionVerificationMethodResolvers<ContextType>;
  ConnectionsCollectionSegment?: ConnectionsCollectionSegmentResolvers<ContextType>;
  Consumption?: ConsumptionResolvers<ContextType>;
  ConsumptionPeriod?: ConsumptionPeriodResolvers<ContextType>;
  ContextUser?: ContextUserResolvers<ContextType>;
  ContextUserApplication?: ContextUserApplicationResolvers<ContextType>;
  ContextUserApplicationsConnection?: ContextUserApplicationsConnectionResolvers<ContextType>;
  ContextUserApplicationsEdge?: ContextUserApplicationsEdgeResolvers<ContextType>;
  ContextUserCannotBeUpdatedError?: ContextUserCannotBeUpdatedErrorResolvers<ContextType>;
  ContextUserDetails?: ContextUserDetailsResolvers<ContextType>;
  ContextUserNotFoundError?: ContextUserNotFoundErrorResolvers<ContextType>;
  ContextUserUpdateUnknownError?: ContextUserUpdateUnknownErrorResolvers<ContextType>;
  ContextUserUpdateValidationError?: ContextUserUpdateValidationErrorResolvers<ContextType>;
  CreateConnectionConflictError?: CreateConnectionConflictErrorResolvers<ContextType>;
  CreateConnectionError?: CreateConnectionErrorResolvers<ContextType>;
  CreateConnectionPayload?: CreateConnectionPayloadResolvers<ContextType>;
  CreateConnectionValidationError?: CreateConnectionValidationErrorResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Decimal?: GraphQLScalarType;
  DeleteConnectionBadRequestError?: DeleteConnectionBadRequestErrorResolvers<ContextType>;
  DeleteConnectionError?: DeleteConnectionErrorResolvers<ContextType>;
  DeleteConnectionNotFoundError?: DeleteConnectionNotFoundErrorResolvers<ContextType>;
  DeleteConnectionPayload?: DeleteConnectionPayloadResolvers<ContextType>;
  DeleteInvitationError?: DeleteInvitationErrorResolvers<ContextType>;
  DeleteInvitationPayload?: DeleteInvitationPayloadResolvers<ContextType>;
  DeploymentRegion?: DeploymentRegionResolvers<ContextType>;
  DeploymentRegionsConnection?: DeploymentRegionsConnectionResolvers<ContextType>;
  DeploymentRegionsEdge?: DeploymentRegionsEdgeResolvers<ContextType>;
  DetailedConnection?: DetailedConnectionResolvers<ContextType>;
  Entitlement?: EntitlementResolvers<ContextType>;
  EntitlementsConnection?: EntitlementsConnectionResolvers<ContextType>;
  EntitlementsEdge?: EntitlementsEdgeResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  Icon?: IconResolvers<ContextType>;
  Invitation?: InvitationResolvers<ContextType>;
  InvitationAction?: InvitationActionResolvers<ContextType>;
  InvitationAppearance?: InvitationAppearanceResolvers<ContextType>;
  InvitationsCollectionSegment?: InvitationsCollectionSegmentResolvers<ContextType>;
  InviteOrganizationMemberError?: InviteOrganizationMemberErrorResolvers<ContextType>;
  InviteOrganizationMemberPayload?: InviteOrganizationMemberPayloadResolvers<ContextType>;
  KeyValuePairOfStringAndString?: KeyValuePairOfStringAndStringResolvers<ContextType>;
  KeyValuePairOfStringAndString__?: KeyValuePairOfStringAndString__Resolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  MemberActionsConnection?: MemberActionsConnectionResolvers<ContextType>;
  MemberActionsEdge?: MemberActionsEdgeResolvers<ContextType>;
  MembersCollectionSegment?: MembersCollectionSegmentResolvers<ContextType>;
  MultiplierPath?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  NonVerifiedConnection?: NonVerifiedConnectionResolvers<ContextType>;
  NotFoundError?: NotFoundErrorResolvers<ContextType>;
  OidcConnectionOptions?: OidcConnectionOptionsResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationAppearance?: OrganizationAppearanceResolvers<ContextType>;
  OrganizationApplication?: OrganizationApplicationResolvers<ContextType>;
  OrganizationMember?: OrganizationMemberResolvers<ContextType>;
  OrganizationMemberAction?: OrganizationMemberActionResolvers<ContextType>;
  OrganizationMemberAppearance?: OrganizationMemberAppearanceResolvers<ContextType>;
  OrganizationMemberApplicationRole?: OrganizationMemberApplicationRoleResolvers<ContextType>;
  OrganizationMemberApplicationsConnection?: OrganizationMemberApplicationsConnectionResolvers<ContextType>;
  OrganizationMemberApplicationsEdge?: OrganizationMemberApplicationsEdgeResolvers<ContextType>;
  OrganizationMemberOrganizationRole?: OrganizationMemberOrganizationRoleResolvers<ContextType>;
  OrganizationRolesConnection?: OrganizationRolesConnectionResolvers<ContextType>;
  OrganizationRolesEdge?: OrganizationRolesEdgeResolvers<ContextType>;
  OrganizationsCollectionSegment?: OrganizationsCollectionSegmentResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PersonalizedContent?: PersonalizedContentResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductProvisioningConflictError?: ProductProvisioningConflictErrorResolvers<ContextType>;
  ProductProvisioningValidationError?: ProductProvisioningValidationErrorResolvers<ContextType>;
  ProductRole?: ProductRoleResolvers<ContextType>;
  ProvisionProductError?: ProvisionProductErrorResolvers<ContextType>;
  ProvisionProductPayload?: ProvisionProductPayloadResolvers<ContextType>;
  ProvisionedConnectProduct?: ProvisionedConnectProductResolvers<ContextType>;
  ProvisionedProduct?: ProvisionedProductResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RecommendationCard?: RecommendationCardResolvers<ContextType>;
  RevokeMembershipBadRequest?: RevokeMembershipBadRequestResolvers<ContextType>;
  RevokeMembershipError?: RevokeMembershipErrorResolvers<ContextType>;
  RevokeMembershipPayload?: RevokeMembershipPayloadResolvers<ContextType>;
  RevokeMembershipUnknownError?: RevokeMembershipUnknownErrorResolvers<ContextType>;
  RolesConnection?: RolesConnectionResolvers<ContextType>;
  RolesEdge?: RolesEdgeResolvers<ContextType>;
  SamlKeyCert?: SamlKeyCertResolvers<ContextType>;
  SamlpConnectionOptions?: SamlpConnectionOptionsResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionsConnection?: SubscriptionsConnectionResolvers<ContextType>;
  SubscriptionsEdge?: SubscriptionsEdgeResolvers<ContextType>;
  UnknownError?: UnknownErrorResolvers<ContextType>;
  UpdateConnectionError?: UpdateConnectionErrorResolvers<ContextType>;
  UpdateConnectionNotFoundError?: UpdateConnectionNotFoundErrorResolvers<ContextType>;
  UpdateConnectionPayload?: UpdateConnectionPayloadResolvers<ContextType>;
  UpdateConnectionValidationError?: UpdateConnectionValidationErrorResolvers<ContextType>;
  UpdateContextUserDetailsError?: UpdateContextUserDetailsErrorResolvers<ContextType>;
  UpdateContextUserDetailsPayload?: UpdateContextUserDetailsPayloadResolvers<ContextType>;
  UpdateOrgMemberRolesBadRequest?: UpdateOrgMemberRolesBadRequestResolvers<ContextType>;
  UpdateOrgMemberRolesError?: UpdateOrgMemberRolesErrorResolvers<ContextType>;
  UpdateOrgMemberRolesNotFound?: UpdateOrgMemberRolesNotFoundResolvers<ContextType>;
  UpdateOrgMemberRolesUnknownError?: UpdateOrgMemberRolesUnknownErrorResolvers<ContextType>;
  UpdateOrganizationMemberRolesPayload?: UpdateOrganizationMemberRolesPayloadResolvers<ContextType>;
  Usage?: UsageResolvers<ContextType>;
  UserApplication?: UserApplicationResolvers<ContextType>;
  UserApplicationAppearance?: UserApplicationAppearanceResolvers<ContextType>;
  UserApplicationRole?: UserApplicationRoleResolvers<ContextType>;
  UserOrganization?: UserOrganizationResolvers<ContextType>;
  UserOrganizationRole?: UserOrganizationRoleResolvers<ContextType>;
  VerifiedConnection?: VerifiedConnectionResolvers<ContextType>;
  VerifyConnectionBadRequestError?: VerifyConnectionBadRequestErrorResolvers<ContextType>;
  VerifyConnectionConflictError?: VerifyConnectionConflictErrorResolvers<ContextType>;
  VerifyConnectionError?: VerifyConnectionErrorResolvers<ContextType>;
  VerifyConnectionNotFoundError?: VerifyConnectionNotFoundErrorResolvers<ContextType>;
  VerifyConnectionPayload?: VerifyConnectionPayloadResolvers<ContextType>;
  WebOrganizationAppearance?: WebOrganizationAppearanceResolvers<ContextType>;
  WebUserApplicationAppearance?: WebUserApplicationAppearanceResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  cost?: CostDirectiveResolver<any, any, ContextType>;
  oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
};

export const MemberFragmentDoc = gql`
  fragment Member on OrganizationMember {
    familyName
    givenName
    email
    lastLogin
  }
`;
export const GetUserAppAccessDocument = gql`
  query GetUserAppAccess($first: Int, $env: String) {
    user {
      applications(
        first: $first
        where: {
          labels: { some: { key: { eq: "Environment" }, value: { eq: $env } } }
        }
      ) {
        nodes {
          assignedRoles(first: $first) {
            nodes {
              applicationId
              organizationId
              role
              productCode
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserAppAccessQuery__
 *
 * To run a query within a React component, call `useGetUserAppAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppAccessQuery({
 *   variables: {
 *      first: // value for 'first'
 *      env: // value for 'env'
 *   },
 * });
 */
export function useGetUserAppAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAppAccessQuery,
    GetUserAppAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAppAccessQuery, GetUserAppAccessQueryVariables>(
    GetUserAppAccessDocument,
    options,
  );
}
export function useGetUserAppAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAppAccessQuery,
    GetUserAppAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAppAccessQuery,
    GetUserAppAccessQueryVariables
  >(GetUserAppAccessDocument, options);
}
export function useGetUserAppAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserAppAccessQuery,
    GetUserAppAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserAppAccessQuery,
    GetUserAppAccessQueryVariables
  >(GetUserAppAccessDocument, options);
}
export type GetUserAppAccessQueryHookResult = ReturnType<
  typeof useGetUserAppAccessQuery
>;
export type GetUserAppAccessLazyQueryHookResult = ReturnType<
  typeof useGetUserAppAccessLazyQuery
>;
export type GetUserAppAccessSuspenseQueryHookResult = ReturnType<
  typeof useGetUserAppAccessSuspenseQuery
>;
export type GetUserAppAccessQueryResult = Apollo.QueryResult<
  GetUserAppAccessQuery,
  GetUserAppAccessQueryVariables
>;
export const GetUserOrgAccessDocument = gql`
  query GetUserOrgAccess($first: Int) {
    user {
      organizationRoles(first: $first) {
        nodes {
          organizationId
          name
        }
      }
    }
  }
`;

/**
 * __useGetUserOrgAccessQuery__
 *
 * To run a query within a React component, call `useGetUserOrgAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrgAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrgAccessQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetUserOrgAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOrgAccessQuery,
    GetUserOrgAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserOrgAccessQuery, GetUserOrgAccessQueryVariables>(
    GetUserOrgAccessDocument,
    options,
  );
}
export function useGetUserOrgAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOrgAccessQuery,
    GetUserOrgAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserOrgAccessQuery,
    GetUserOrgAccessQueryVariables
  >(GetUserOrgAccessDocument, options);
}
export function useGetUserOrgAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserOrgAccessQuery,
    GetUserOrgAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserOrgAccessQuery,
    GetUserOrgAccessQueryVariables
  >(GetUserOrgAccessDocument, options);
}
export type GetUserOrgAccessQueryHookResult = ReturnType<
  typeof useGetUserOrgAccessQuery
>;
export type GetUserOrgAccessLazyQueryHookResult = ReturnType<
  typeof useGetUserOrgAccessLazyQuery
>;
export type GetUserOrgAccessSuspenseQueryHookResult = ReturnType<
  typeof useGetUserOrgAccessSuspenseQuery
>;
export type GetUserOrgAccessQueryResult = Apollo.QueryResult<
  GetUserOrgAccessQuery,
  GetUserOrgAccessQueryVariables
>;
export const GetUserOrgDepsDocument = gql`
  query GetUserOrgDeps {
    organization {
      id
      name
      displayName
      accountId
      type
    }
    user {
      applications {
        nodes {
          assignedRoles {
            nodes {
              applicationId
              organizationId
              role
              productCode
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserOrgDepsQuery__
 *
 * To run a query within a React component, call `useGetUserOrgDepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrgDepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrgDepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOrgDepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOrgDepsQuery,
    GetUserOrgDepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserOrgDepsQuery, GetUserOrgDepsQueryVariables>(
    GetUserOrgDepsDocument,
    options,
  );
}
export function useGetUserOrgDepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOrgDepsQuery,
    GetUserOrgDepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserOrgDepsQuery, GetUserOrgDepsQueryVariables>(
    GetUserOrgDepsDocument,
    options,
  );
}
export function useGetUserOrgDepsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserOrgDepsQuery,
    GetUserOrgDepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserOrgDepsQuery,
    GetUserOrgDepsQueryVariables
  >(GetUserOrgDepsDocument, options);
}
export type GetUserOrgDepsQueryHookResult = ReturnType<
  typeof useGetUserOrgDepsQuery
>;
export type GetUserOrgDepsLazyQueryHookResult = ReturnType<
  typeof useGetUserOrgDepsLazyQuery
>;
export type GetUserOrgDepsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserOrgDepsSuspenseQuery
>;
export type GetUserOrgDepsQueryResult = Apollo.QueryResult<
  GetUserOrgDepsQuery,
  GetUserOrgDepsQueryVariables
>;
export const GetHelpQuickActionsDocument = gql`
  query GetHelpQuickActions($first: Int) {
    organization {
      appearance {
        web {
          actions(first: $first) {
            nodes {
              name
              color
              displayName
              location
              category
              description
              icon {
                src
                type
              }
              link {
                to
                type
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetHelpQuickActionsQuery__
 *
 * To run a query within a React component, call `useGetHelpQuickActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpQuickActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpQuickActionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetHelpQuickActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >(GetHelpQuickActionsDocument, options);
}
export function useGetHelpQuickActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >(GetHelpQuickActionsDocument, options);
}
export function useGetHelpQuickActionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHelpQuickActionsQuery,
    GetHelpQuickActionsQueryVariables
  >(GetHelpQuickActionsDocument, options);
}
export type GetHelpQuickActionsQueryHookResult = ReturnType<
  typeof useGetHelpQuickActionsQuery
>;
export type GetHelpQuickActionsLazyQueryHookResult = ReturnType<
  typeof useGetHelpQuickActionsLazyQuery
>;
export type GetHelpQuickActionsSuspenseQueryHookResult = ReturnType<
  typeof useGetHelpQuickActionsSuspenseQuery
>;
export type GetHelpQuickActionsQueryResult = Apollo.QueryResult<
  GetHelpQuickActionsQuery,
  GetHelpQuickActionsQueryVariables
>;
export const GetQuickLinksDocument = gql`
  query GetQuickLinks {
    organization {
      appearance {
        web {
          actions(where: { category: { value: { eq: "Portal Actions" } } }) {
            nodes {
              name
              color
              displayName
              location
              description
              icon {
                src
                type
              }
              link {
                to
                type
              }
              category
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetQuickLinksQuery__
 *
 * To run a query within a React component, call `useGetQuickLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuickLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetQuickLinksQuery,
    GetQuickLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuickLinksQuery, GetQuickLinksQueryVariables>(
    GetQuickLinksDocument,
    options,
  );
}
export function useGetQuickLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuickLinksQuery,
    GetQuickLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuickLinksQuery, GetQuickLinksQueryVariables>(
    GetQuickLinksDocument,
    options,
  );
}
export function useGetQuickLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetQuickLinksQuery,
    GetQuickLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetQuickLinksQuery,
    GetQuickLinksQueryVariables
  >(GetQuickLinksDocument, options);
}
export type GetQuickLinksQueryHookResult = ReturnType<
  typeof useGetQuickLinksQuery
>;
export type GetQuickLinksLazyQueryHookResult = ReturnType<
  typeof useGetQuickLinksLazyQuery
>;
export type GetQuickLinksSuspenseQueryHookResult = ReturnType<
  typeof useGetQuickLinksSuspenseQuery
>;
export type GetQuickLinksQueryResult = Apollo.QueryResult<
  GetQuickLinksQuery,
  GetQuickLinksQueryVariables
>;
export const GetTenantsDocument = gql`
  query GetTenants($first: Int, $after: String, $env: String) {
    user {
      applications(
        first: $first
        after: $after
        where: {
          labels: { some: { key: { eq: "Environment" }, value: { eq: $env } } }
        }
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            appearance {
              web {
                actions(
                  first: 1
                  where: { category: { value: { eq: "Direct Links" } } }
                ) {
                  nodes {
                    name
                    icon {
                      src
                      type
                    }
                    displayName
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
          labels {
            key
            value
          }
          appearance {
            web {
              actions {
                nodes {
                  name
                  color
                  displayName
                  location
                  category
                  description
                  link {
                    type
                    to
                  }
                  icon {
                    src
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      env: // value for 'env'
 *   },
 * });
 */
export function useGetTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options,
  );
}
export function useGetTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options,
  );
}
export function useGetTenantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options,
  );
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<
  typeof useGetTenantsLazyQuery
>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<
  typeof useGetTenantsSuspenseQuery
>;
export type GetTenantsQueryResult = Apollo.QueryResult<
  GetTenantsQuery,
  GetTenantsQueryVariables
>;
export const RevokeMembershipDocument = gql`
  mutation RevokeMembership($input: RevokeMembershipInput!) {
    revokeMembership(input: $input) {
      members
      errors {
        ... on RevokeMembershipBadRequest {
          message
          errorDetails {
            key
            value
          }
        }
        ... on RevokeMembershipUnknownError {
          message
        }
      }
    }
  }
`;
export type RevokeMembershipMutationFn = Apollo.MutationFunction<
  RevokeMembershipMutation,
  RevokeMembershipMutationVariables
>;

/**
 * __useRevokeMembershipMutation__
 *
 * To run a mutation, you first call `useRevokeMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeMembershipMutation, { data, loading, error }] = useRevokeMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeMembershipMutation,
    RevokeMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeMembershipMutation,
    RevokeMembershipMutationVariables
  >(RevokeMembershipDocument, options);
}
export type RevokeMembershipMutationHookResult = ReturnType<
  typeof useRevokeMembershipMutation
>;
export type RevokeMembershipMutationResult =
  Apollo.MutationResult<RevokeMembershipMutation>;
export type RevokeMembershipMutationOptions = Apollo.BaseMutationOptions<
  RevokeMembershipMutation,
  RevokeMembershipMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation DeleteInvitation($invitationId: String!) {
    deleteInvitation(input: { invitationId: $invitationId }) {
      errors {
        ... on BadRequestError {
          message
        }
        ... on NotFoundError {
          message
        }
      }
    }
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >(DeleteInvitationDocument, options);
}
export type DeleteInvitationMutationHookResult = ReturnType<
  typeof useDeleteInvitationMutation
>;
export type DeleteInvitationMutationResult =
  Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const InviteOrganizationMemberDocument = gql`
  mutation InviteOrganizationMember(
    $inviterName: String!
    $inviteeEmail: String!
    $organizationRoles: [InvitationOrganizationRoleInput!]
    $applicationRoles: [InvitationApplicationRoleInput!]
  ) {
    inviteOrganizationMember(
      input: {
        inviterName: $inviterName
        inviteeEmail: $inviteeEmail
        organizationRoles: $organizationRoles
        applicationRoles: $applicationRoles
      }
    ) {
      invitation {
        id
        inviteeEmail
      }
      errors {
        ... on BadRequestError {
          message
        }
        ... on ConflictError {
          message
        }
      }
    }
  }
`;
export type InviteOrganizationMemberMutationFn = Apollo.MutationFunction<
  InviteOrganizationMemberMutation,
  InviteOrganizationMemberMutationVariables
>;

/**
 * __useInviteOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useInviteOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrganizationMemberMutation, { data, loading, error }] = useInviteOrganizationMemberMutation({
 *   variables: {
 *      inviterName: // value for 'inviterName'
 *      inviteeEmail: // value for 'inviteeEmail'
 *      organizationRoles: // value for 'organizationRoles'
 *      applicationRoles: // value for 'applicationRoles'
 *   },
 * });
 */
export function useInviteOrganizationMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteOrganizationMemberMutation,
    InviteOrganizationMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteOrganizationMemberMutation,
    InviteOrganizationMemberMutationVariables
  >(InviteOrganizationMemberDocument, options);
}
export type InviteOrganizationMemberMutationHookResult = ReturnType<
  typeof useInviteOrganizationMemberMutation
>;
export type InviteOrganizationMemberMutationResult =
  Apollo.MutationResult<InviteOrganizationMemberMutation>;
export type InviteOrganizationMemberMutationOptions =
  Apollo.BaseMutationOptions<
    InviteOrganizationMemberMutation,
    InviteOrganizationMemberMutationVariables
  >;
export const GetInvitationsDocument = gql`
  query GetInvitations($take: Int, $skip: Int) {
    organization {
      id
      invitations(take: $take, skip: $skip) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        items {
          id
          inviterName
          inviteeEmail
          expires
          appearance {
            actions {
              name
              displayName
              link {
                to
                type
              }
            }
          }
          organizationRoles {
            organizationId
            name
            description
          }
          applicationRoles {
            applicationId
            organizationId
            role
            productCode
          }
        }
      }
    }
  }
`;

/**
 * __useGetInvitationsQuery__
 *
 * To run a query within a React component, call `useGetInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvitationsQuery,
    GetInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(
    GetInvitationsDocument,
    options,
  );
}
export function useGetInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitationsQuery,
    GetInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(
    GetInvitationsDocument,
    options,
  );
}
export function useGetInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvitationsQuery,
    GetInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInvitationsQuery,
    GetInvitationsQueryVariables
  >(GetInvitationsDocument, options);
}
export type GetInvitationsQueryHookResult = ReturnType<
  typeof useGetInvitationsQuery
>;
export type GetInvitationsLazyQueryHookResult = ReturnType<
  typeof useGetInvitationsLazyQuery
>;
export type GetInvitationsSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitationsSuspenseQuery
>;
export type GetInvitationsQueryResult = Apollo.QueryResult<
  GetInvitationsQuery,
  GetInvitationsQueryVariables
>;
export const GetInvitationDocument = gql`
  query GetInvitation($id: String) {
    organization {
      id
      applications(first: 100) {
        nodes {
          id
          name
          displayName
          productCode
        }
      }
      invitations(where: { id: { value: { eq: $id } } }) {
        totalCount
        items {
          id
          inviterName
          inviteeEmail
          expires
          organizationRoles {
            organizationId
            name
            description
          }
          applicationRoles {
            applicationId
            organizationId
            role
            productCode
          }
        }
      }
    }
  }
`;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvitationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options,
  );
}
export function useGetInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options,
  );
}
export function useGetInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >(GetInvitationDocument, options);
}
export type GetInvitationQueryHookResult = ReturnType<
  typeof useGetInvitationQuery
>;
export type GetInvitationLazyQueryHookResult = ReturnType<
  typeof useGetInvitationLazyQuery
>;
export type GetInvitationSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitationSuspenseQuery
>;
export type GetInvitationQueryResult = Apollo.QueryResult<
  GetInvitationQuery,
  GetInvitationQueryVariables
>;
export const GetOrganizationTenantsDocument = gql`
  query GetOrganizationTenants($first: Int, $env: String) {
    organization {
      id
      applications(
        first: $first
        where: {
          labels: { some: { key: { eq: "Environment" }, value: { eq: $env } } }
        }
      ) {
        totalCount
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
          labels {
            key
            value
          }
          product {
            code
            roles(first: 100) {
              totalCount
              nodes {
                productCode
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationTenantsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTenantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      env: // value for 'env'
 *   },
 * });
 */
export function useGetOrganizationTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >(GetOrganizationTenantsDocument, options);
}
export function useGetOrganizationTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >(GetOrganizationTenantsDocument, options);
}
export function useGetOrganizationTenantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationTenantsQuery,
    GetOrganizationTenantsQueryVariables
  >(GetOrganizationTenantsDocument, options);
}
export type GetOrganizationTenantsQueryHookResult = ReturnType<
  typeof useGetOrganizationTenantsQuery
>;
export type GetOrganizationTenantsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationTenantsLazyQuery
>;
export type GetOrganizationTenantsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationTenantsSuspenseQuery
>;
export type GetOrganizationTenantsQueryResult = Apollo.QueryResult<
  GetOrganizationTenantsQuery,
  GetOrganizationTenantsQueryVariables
>;
export const GetMembersDocument = gql`
  query GetMembers($query: String, $take: Int, $skip: Int) {
    organization {
      id
      members(query: { nameOrEmail: $query }, take: $take, skip: $skip) {
        totalCount
        items {
          id
          emailVerified
          created
          updated
          lastLogin
          signUpCompleted
          isExternal
          givenName
          familyName
          nickName
          phoneNumber
          company
          jobRole
          preferredLanguage
          picture
          email
          organizationRoles(first: 25) {
            nodes {
              description
              name
              organizationId
            }
          }
          appearance {
            memberActions {
              totalCount
              nodes {
                link {
                  to
                  type
                }
                displayName
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMembersQuery,
    GetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersQuery,
    GetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export function useGetMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMembersQuery,
    GetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<
  typeof useGetMembersLazyQuery
>;
export type GetMembersSuspenseQueryHookResult = ReturnType<
  typeof useGetMembersSuspenseQuery
>;
export type GetMembersQueryResult = Apollo.QueryResult<
  GetMembersQuery,
  GetMembersQueryVariables
>;
export const GetOrganizationMemberDocument = gql`
  query GetOrganizationMember($id: String!) {
    organization {
      id
      memberById(userId: $id) {
        id
        emailVerified
        created
        updated
        lastLogin
        signUpCompleted
        isExternal
        givenName
        familyName
        nickName
        phoneNumber
        company
        jobRole
        preferredLanguage
        picture
        email
        applications(first: 100) {
          totalCount
          nodes {
            id
            name
            displayName
            organizationId
            state
            systemId
            productCode
            assignedRoles(first: 100) {
              totalCount
              nodes {
                applicationId
                organizationId
                role
                productCode
              }
            }
          }
        }
        organizationRoles {
          totalCount
          nodes {
            organizationId
            name
            description
          }
        }
        appearance {
          memberActions {
            totalCount
            nodes {
              name
              displayName
              link {
                to
                type
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationMemberQuery__
 *
 * To run a query within a React component, call `useGetOrganizationMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  > &
    (
      | { variables: GetOrganizationMemberQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  >(GetOrganizationMemberDocument, options);
}
export function useGetOrganizationMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  >(GetOrganizationMemberDocument, options);
}
export function useGetOrganizationMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationMemberQuery,
    GetOrganizationMemberQueryVariables
  >(GetOrganizationMemberDocument, options);
}
export type GetOrganizationMemberQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberQuery
>;
export type GetOrganizationMemberLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberLazyQuery
>;
export type GetOrganizationMemberSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberSuspenseQuery
>;
export type GetOrganizationMemberQueryResult = Apollo.QueryResult<
  GetOrganizationMemberQuery,
  GetOrganizationMemberQueryVariables
>;
export const UpdateOrganizationMemberRolesDocument = gql`
  mutation UpdateOrganizationMemberRoles(
    $userId: String!
    $organizationRolesToAdd: [OrganizationMemberOrganizationRoleInput!]
    $organizationRolesToDelete: [OrganizationMemberOrganizationRoleInput!]
    $applicationRolesToAdd: [OrganizationMemberApplicationRoleInput!]
    $applicationRolesToDelete: [OrganizationMemberApplicationRoleInput!]
  ) {
    updateOrganizationMemberRoles(
      input: {
        applicationRolesToDelete: $applicationRolesToDelete
        applicationRolesToAdd: $applicationRolesToAdd
        organizationRolesToDelete: $organizationRolesToDelete
        organizationRolesToAdd: $organizationRolesToAdd
        userId: $userId
      }
    ) {
      userId
      errors {
        ... on UpdateOrgMemberRolesNotFound {
          message
          userId
        }
        ... on UpdateOrgMemberRolesBadRequest {
          message
          errorDetails {
            key
            value
          }
        }
        ... on UpdateOrgMemberRolesUnknownError {
          message
        }
      }
    }
  }
`;
export type UpdateOrganizationMemberRolesMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMemberRolesMutation,
  UpdateOrganizationMemberRolesMutationVariables
>;

/**
 * __useUpdateOrganizationMemberRolesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMemberRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMemberRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMemberRolesMutation, { data, loading, error }] = useUpdateOrganizationMemberRolesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationRolesToAdd: // value for 'organizationRolesToAdd'
 *      organizationRolesToDelete: // value for 'organizationRolesToDelete'
 *      applicationRolesToAdd: // value for 'applicationRolesToAdd'
 *      applicationRolesToDelete: // value for 'applicationRolesToDelete'
 *   },
 * });
 */
export function useUpdateOrganizationMemberRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMemberRolesMutation,
    UpdateOrganizationMemberRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMemberRolesMutation,
    UpdateOrganizationMemberRolesMutationVariables
  >(UpdateOrganizationMemberRolesDocument, options);
}
export type UpdateOrganizationMemberRolesMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMemberRolesMutation
>;
export type UpdateOrganizationMemberRolesMutationResult =
  Apollo.MutationResult<UpdateOrganizationMemberRolesMutation>;
export type UpdateOrganizationMemberRolesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationMemberRolesMutation,
    UpdateOrganizationMemberRolesMutationVariables
  >;
export const GetMemberDetailsOrganizationDocument = gql`
  query GetMemberDetailsOrganization {
    organization {
      applications(first: 100) {
        totalCount
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
        }
      }
    }
  }
`;

/**
 * __useGetMemberDetailsOrganizationQuery__
 *
 * To run a query within a React component, call `useGetMemberDetailsOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberDetailsOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberDetailsOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberDetailsOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >(GetMemberDetailsOrganizationDocument, options);
}
export function useGetMemberDetailsOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >(GetMemberDetailsOrganizationDocument, options);
}
export function useGetMemberDetailsOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMemberDetailsOrganizationQuery,
    GetMemberDetailsOrganizationQueryVariables
  >(GetMemberDetailsOrganizationDocument, options);
}
export type GetMemberDetailsOrganizationQueryHookResult = ReturnType<
  typeof useGetMemberDetailsOrganizationQuery
>;
export type GetMemberDetailsOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetMemberDetailsOrganizationLazyQuery
>;
export type GetMemberDetailsOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetMemberDetailsOrganizationSuspenseQuery
>;
export type GetMemberDetailsOrganizationQueryResult = Apollo.QueryResult<
  GetMemberDetailsOrganizationQuery,
  GetMemberDetailsOrganizationQueryVariables
>;
export const GetMemberDetailsUserDocument = gql`
  query GetMemberDetailsUser {
    user {
      applications(first: 100) {
        totalCount
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
          appearance {
            web {
              actions(first: 100) {
                nodes {
                  icon {
                    src
                    type
                  }
                  name
                  displayName
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberDetailsUserQuery__
 *
 * To run a query within a React component, call `useGetMemberDetailsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberDetailsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberDetailsUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberDetailsUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >(GetMemberDetailsUserDocument, options);
}
export function useGetMemberDetailsUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >(GetMemberDetailsUserDocument, options);
}
export function useGetMemberDetailsUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMemberDetailsUserQuery,
    GetMemberDetailsUserQueryVariables
  >(GetMemberDetailsUserDocument, options);
}
export type GetMemberDetailsUserQueryHookResult = ReturnType<
  typeof useGetMemberDetailsUserQuery
>;
export type GetMemberDetailsUserLazyQueryHookResult = ReturnType<
  typeof useGetMemberDetailsUserLazyQuery
>;
export type GetMemberDetailsUserSuspenseQueryHookResult = ReturnType<
  typeof useGetMemberDetailsUserSuspenseQuery
>;
export type GetMemberDetailsUserQueryResult = Apollo.QueryResult<
  GetMemberDetailsUserQuery,
  GetMemberDetailsUserQueryVariables
>;
export const GetMemberDetailsProductsDocument = gql`
  query GetMemberDetailsProducts {
    products {
      roles(first: 100) {
        totalCount
        nodes {
          productCode
          name
        }
      }
    }
  }
`;

/**
 * __useGetMemberDetailsProductsQuery__
 *
 * To run a query within a React component, call `useGetMemberDetailsProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberDetailsProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberDetailsProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberDetailsProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >(GetMemberDetailsProductsDocument, options);
}
export function useGetMemberDetailsProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >(GetMemberDetailsProductsDocument, options);
}
export function useGetMemberDetailsProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMemberDetailsProductsQuery,
    GetMemberDetailsProductsQueryVariables
  >(GetMemberDetailsProductsDocument, options);
}
export type GetMemberDetailsProductsQueryHookResult = ReturnType<
  typeof useGetMemberDetailsProductsQuery
>;
export type GetMemberDetailsProductsLazyQueryHookResult = ReturnType<
  typeof useGetMemberDetailsProductsLazyQuery
>;
export type GetMemberDetailsProductsSuspenseQueryHookResult = ReturnType<
  typeof useGetMemberDetailsProductsSuspenseQuery
>;
export type GetMemberDetailsProductsQueryResult = Apollo.QueryResult<
  GetMemberDetailsProductsQuery,
  GetMemberDetailsProductsQueryVariables
>;
export const UpdateContextUserDetailsDocument = gql`
  mutation UpdateContextUserDetails($input: UpdateContextUserDetailsInput!) {
    updateContextUserDetails(input: $input) {
      errors {
        ... on ContextUserUpdateValidationError {
          message
          errorDetails {
            key
            value
          }
        }
        ... on ContextUserCannotBeUpdatedError {
          message
        }
        ... on ContextUserNotFoundError {
          message
        }
        ... on ContextUserUpdateUnknownError {
          message
        }
      }
    }
  }
`;
export type UpdateContextUserDetailsMutationFn = Apollo.MutationFunction<
  UpdateContextUserDetailsMutation,
  UpdateContextUserDetailsMutationVariables
>;

/**
 * __useUpdateContextUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateContextUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContextUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContextUserDetailsMutation, { data, loading, error }] = useUpdateContextUserDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContextUserDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContextUserDetailsMutation,
    UpdateContextUserDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContextUserDetailsMutation,
    UpdateContextUserDetailsMutationVariables
  >(UpdateContextUserDetailsDocument, options);
}
export type UpdateContextUserDetailsMutationHookResult = ReturnType<
  typeof useUpdateContextUserDetailsMutation
>;
export type UpdateContextUserDetailsMutationResult =
  Apollo.MutationResult<UpdateContextUserDetailsMutation>;
export type UpdateContextUserDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateContextUserDetailsMutation,
    UpdateContextUserDetailsMutationVariables
  >;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    user {
      id
      email
      emailVerified
      created
      updated
      lastLogin
      signUpCompleted
      givenName
      familyName
      nickName
      phoneNumber
      company
      jobRole
      preferredLanguage
      isExternal
      emailVerified
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetRecommendationCardsDocument = gql`
  query GetRecommendationCards {
    personalizedContent {
      recommendationCards {
        cardId
        title
        description
        link {
          to
          type
        }
        icon {
          src
          type
        }
      }
    }
  }
`;

/**
 * __useGetRecommendationCardsQuery__
 *
 * To run a query within a React component, call `useGetRecommendationCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendationCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >(GetRecommendationCardsDocument, options);
}
export function useGetRecommendationCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >(GetRecommendationCardsDocument, options);
}
export function useGetRecommendationCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRecommendationCardsQuery,
    GetRecommendationCardsQueryVariables
  >(GetRecommendationCardsDocument, options);
}
export type GetRecommendationCardsQueryHookResult = ReturnType<
  typeof useGetRecommendationCardsQuery
>;
export type GetRecommendationCardsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendationCardsLazyQuery
>;
export type GetRecommendationCardsSuspenseQueryHookResult = ReturnType<
  typeof useGetRecommendationCardsSuspenseQuery
>;
export type GetRecommendationCardsQueryResult = Apollo.QueryResult<
  GetRecommendationCardsQuery,
  GetRecommendationCardsQueryVariables
>;
export const GetSubscriptionsDocument = gql`
  query GetSubscriptions($first: Int) {
    subscriptions(first: $first) {
      totalCount
      nodes {
        subscriptionId
        accountId
        contractStart
        contractEnd
        editionType
        productNames
        sellableProductCode
        entitlements {
          nodes {
            startDate
            endDate
            name
            consumption {
              currentPeriod {
                usages {
                  value
                }
              }
            }
            quantity
          }
        }
      }
    }
  }
`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(
    GetSubscriptionsDocument,
    options,
  );
}
export function useGetSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >(GetSubscriptionsDocument, options);
}
export function useGetSubscriptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >(GetSubscriptionsDocument, options);
}
export type GetSubscriptionsQueryHookResult = ReturnType<
  typeof useGetSubscriptionsQuery
>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionsLazyQuery
>;
export type GetSubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetSubscriptionsSuspenseQuery
>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables
>;
export const GetSubscriptionDocument = gql`
  query GetSubscription($subscriptionId: String) {
    subscriptions(
      where: { subscriptionId: { value: { eq: $subscriptionId } } }
    ) {
      nodes {
        subscriptionId
        accountId
        contractStart
        contractEnd
        editionType
        productNames
        sellableProductCode
        entitlements {
          nodes {
            startDate
            endDate
            name
            consumption {
              currentPeriod {
                usages {
                  value
                  reportedFor
                }
                endDate
                startDate
              }
            }
            quantity
            periodType
            lastReportedDate
            metric
            limitType
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(
    GetSubscriptionDocument,
    options,
  );
}
export function useGetSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >(GetSubscriptionDocument, options);
}
export function useGetSubscriptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >(GetSubscriptionDocument, options);
}
export type GetSubscriptionQueryHookResult = ReturnType<
  typeof useGetSubscriptionQuery
>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionLazyQuery
>;
export type GetSubscriptionSuspenseQueryHookResult = ReturnType<
  typeof useGetSubscriptionSuspenseQuery
>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<
  GetSubscriptionQuery,
  GetSubscriptionQueryVariables
>;
export const GetCountOfProductDocument = gql`
  query GetCountOfProduct($productCode: String!) {
    organization {
      applications(where: { productCode: { value: { eq: $productCode } } }) {
        totalCount
      }
    }
  }
`;

/**
 * __useGetCountOfProductQuery__
 *
 * To run a query within a React component, call `useGetCountOfProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfProductQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useGetCountOfProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  > &
    (
      | { variables: GetCountOfProductQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  >(GetCountOfProductDocument, options);
}
export function useGetCountOfProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  >(GetCountOfProductDocument, options);
}
export function useGetCountOfProductSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCountOfProductQuery,
    GetCountOfProductQueryVariables
  >(GetCountOfProductDocument, options);
}
export type GetCountOfProductQueryHookResult = ReturnType<
  typeof useGetCountOfProductQuery
>;
export type GetCountOfProductLazyQueryHookResult = ReturnType<
  typeof useGetCountOfProductLazyQuery
>;
export type GetCountOfProductSuspenseQueryHookResult = ReturnType<
  typeof useGetCountOfProductSuspenseQuery
>;
export type GetCountOfProductQueryResult = Apollo.QueryResult<
  GetCountOfProductQuery,
  GetCountOfProductQueryVariables
>;
export const GetDeploymentRegionsDocument = gql`
  query GetDeploymentRegions {
    products {
      code
      deploymentRegions {
        totalCount
        nodes {
          id
          description
        }
      }
    }
  }
`;

/**
 * __useGetDeploymentRegionsQuery__
 *
 * To run a query within a React component, call `useGetDeploymentRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeploymentRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeploymentRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeploymentRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >(GetDeploymentRegionsDocument, options);
}
export function useGetDeploymentRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >(GetDeploymentRegionsDocument, options);
}
export function useGetDeploymentRegionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeploymentRegionsQuery,
    GetDeploymentRegionsQueryVariables
  >(GetDeploymentRegionsDocument, options);
}
export type GetDeploymentRegionsQueryHookResult = ReturnType<
  typeof useGetDeploymentRegionsQuery
>;
export type GetDeploymentRegionsLazyQueryHookResult = ReturnType<
  typeof useGetDeploymentRegionsLazyQuery
>;
export type GetDeploymentRegionsSuspenseQueryHookResult = ReturnType<
  typeof useGetDeploymentRegionsSuspenseQuery
>;
export type GetDeploymentRegionsQueryResult = Apollo.QueryResult<
  GetDeploymentRegionsQuery,
  GetDeploymentRegionsQueryVariables
>;
export const ProvisionConnectDocument = gql`
  mutation ProvisionConnect(
    $name: String!
    $displayName: String!
    $regiondId: String!
    $auditLogsEnabled: Boolean
  ) {
    provisionProduct(
      input: {
        connect: {
          name: $name
          displayName: $displayName
          regionId: $regiondId
          auditLogsEnabled: $auditLogsEnabled
        }
      }
    ) {
      errors {
        ... on ProductProvisioningValidationError {
          message
          errorDetails {
            key
            value
          }
        }
        ... on ProductProvisioningConflictError {
          message
        }
        ... on UnknownError {
          message
        }
      }
    }
  }
`;
export type ProvisionConnectMutationFn = Apollo.MutationFunction<
  ProvisionConnectMutation,
  ProvisionConnectMutationVariables
>;

/**
 * __useProvisionConnectMutation__
 *
 * To run a mutation, you first call `useProvisionConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionConnectMutation, { data, loading, error }] = useProvisionConnectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      regiondId: // value for 'regiondId'
 *      auditLogsEnabled: // value for 'auditLogsEnabled'
 *   },
 * });
 */
export function useProvisionConnectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProvisionConnectMutation,
    ProvisionConnectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProvisionConnectMutation,
    ProvisionConnectMutationVariables
  >(ProvisionConnectDocument, options);
}
export type ProvisionConnectMutationHookResult = ReturnType<
  typeof useProvisionConnectMutation
>;
export type ProvisionConnectMutationResult =
  Apollo.MutationResult<ProvisionConnectMutation>;
export type ProvisionConnectMutationOptions = Apollo.BaseMutationOptions<
  ProvisionConnectMutation,
  ProvisionConnectMutationVariables
>;
