/* eslint-disable no-console */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import {
  IGetMembersDataItem,
  IGetMembersResponse,
  IMemberRole,
  EOrganizationAccessRole,
} from 'features/members/services/identity/membersModel';
import groupBy from 'lodash/groupBy';
import { nameDoesNotStartWithSpecialCharRegex } from 'features/common/validations/matchers';
import {
  ITenant,
  Product,
  identity as identityConfig,
} from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

const identity = identityConfig(env);
const MAX_MEMBER_RECORDS = 1000;

const userNoRoleCSVMessage = 'User has no assigned apps';
const ownerCSVMessage = 'Can access everything';
const adminRoleCSVMessage = 'Can access all apps';

// https://owasp.org/www-community/attacks/CSV_Injection
export const sanitizeCSVCell = (cell: any) => {
  if (cell && !nameDoesNotStartWithSpecialCharRegex.test(cell)) {
    return '"' + "'" + cell.replace('"', '""') + '"';
  }
  return cell;
};

export const calculateNumberOfRequests = (
  totalRecords: number,
  pageSize: number,
) => {
  // only request the first 1000 records
  const pagesForRequest =
    totalRecords <= MAX_MEMBER_RECORDS
      ? Math.ceil(totalRecords / pageSize)
      : Math.ceil(MAX_MEMBER_RECORDS / pageSize);
  return pagesForRequest;
};

export const getAllMembers = async (
  axiosInstance: AxiosInstance,
  pageSize: number,
) => {
  const page = 1;
  let membersArray: IGetMembersDataItem[] = [];

  //make the initial call to retrieve total records
  const {
    data: { data: members, totalRecords },
  } = await axiosInstance.get<IGetMembersResponse>(
    identity.get_members.url(page, pageSize, ''),
    { timeout: 1500 },
  );
  const pagesToRequest = calculateNumberOfRequests(totalRecords, pageSize);

  const axiosResponses = [];
  for (let page = 2; page <= pagesToRequest; page++) {
    const axiosResponse = await axiosInstance.get<IGetMembersResponse>(
      identity.get_members.url(page, pageSize, ''),
      { timeout: 1500 },
    );

    axiosResponses.push(axiosResponse);
  }

  await axios
    .all(axiosResponses)
    .then((responses: AxiosResponse<IGetMembersResponse>[]) => {
      //store the retrieved members so we don't fetch them again
      membersArray = [...members];

      responses.map((response) => {
        membersArray = [...membersArray, ...response.data.data];
      });
    })
    .catch((error) => {
      // react on errors.
      console.error(error);
      throw Error("Members couldn't be retrieved");
    });
  return membersArray;
};

export const getRolesForMember = (roles: IMemberRole[], tenants: ITenant[]) => {
  //transform  roles array to be parsed by lodash groupby function
  const rolesArray = roles.map((role) =>
    role.scope === 'Organization'
      ? {
          ...role,
          type: 'organizationRole',
        }
      : {
          ...role,
          type: 'tenantRolesArray',
        },
  );

  const {
    organizationRole: [organizationRole],
    tenantRolesArray = [],
  } = groupBy(rolesArray, 'type');

  if (
    organizationRole.role === EOrganizationAccessRole['Organization Owner'] ||
    organizationRole.role === EOrganizationAccessRole['Organization Admin']
  )
    return {
      organizationRole: organizationRole.role,
      tenantRoles: adminRoleCSVMessage,
      ...(organizationRole.role ===
      EOrganizationAccessRole['Organization Owner']
        ? { tenantRoles: ownerCSVMessage }
        : {}),
      ...(organizationRole.role ===
      EOrganizationAccessRole['Organization Admin']
        ? { tenantRoles: adminRoleCSVMessage }
        : {}),
    };

  const tenantRoles =
    tenantRolesArray
      .map(({ tenantId, role }) => {
        const tenant = tenants.find(({ id }) => id === tenantId);

        if (!tenant) {
          return null;
        }

        const { viewModel } = new Product({ tenant, env });

        return `[${viewModel.title}:${role}] ${viewModel.subTitle}`;
      })
      .filter(Boolean)
      .join(', ') || userNoRoleCSVMessage;

  return {
    organizationRole: organizationRole.role,
    tenantRoles,
  };
};

export const generateCSVRowObject =
  (tenants: ITenant[]) =>
  ({ email, lastLogin, givenName, familyName, roles }: IGetMembersDataItem) => {
    const lastLoginDate = format(
      new Date(lastLogin).getTime(),
      'MMM d, yyyy, HH:mm',
    );

    const { organizationRole, tenantRoles } = getRolesForMember(roles, tenants);

    return {
      Email: sanitizeCSVCell(email),
      'Given name': sanitizeCSVCell(givenName),
      'Family name': sanitizeCSVCell(familyName),
      'Organizational access': sanitizeCSVCell(organizationRole),
      'App access': sanitizeCSVCell(tenantRoles),
      'Last login date': sanitizeCSVCell(lastLoginDate),
    };
  };

export const generateCSV = async (fileName: string, data: any[]) => {
  const csv = await import('csv-stringify/browser/esm');
  const FileSaver = require('file-saver');
  const csvData: any[] = [];

  // Initialize the stringifier
  const stringifier = csv.stringify({
    header: true,
    columns: [
      'Email',
      'Given name',
      'Family name',
      'Organizational access',
      'App access',
      'Last login date',
    ],
  });

  // Use the readable stream api to consume CSV data
  stringifier.on('readable', function () {
    let row;
    while ((row = stringifier.read()) !== null) {
      csvData.push(row);
    }
  });

  // Catch any error
  stringifier.on('error', function (err) {
    console.error(err.message);
  });

  stringifier.on('finish', function () {
    const file = new File(csvData, fileName, {
      type: 'text/csv;charset=utf-8;',
    });
    FileSaver.saveAs(file);
  });

  // Write each row to the stream
  data.forEach((row) => {
    stringifier.write(row);
  });
  stringifier.end();
};
