import { Text, Tooltip } from '@chakra-ui/react';

// Labels/Names: Keep within 20-30 characters to maintain a clean UI.
const TruncatedText = ({
  text,
  maxLength = 23,
  openDelay = 500,
}: {
  text: string;
  maxLength?: number;
  openDelay?: number;
}) => {
  if (text.length <= maxLength) {
    return (
      <Text variant='small' w='full' textAlign='center' whiteSpace='nowrap'>
        {text}
      </Text>
    );
  }

  const partLength = Math.floor(maxLength / 2);
  const start = text.slice(0, partLength);
  const end = text.slice(text.length - partLength);

  const truncatedText = `${start}...${end}`;

  return (
    <Tooltip label={text} openDelay={openDelay} aria-label={text}>
      <Text variant='small' w='full' textAlign='center' whiteSpace='nowrap'>
        {truncatedText}
      </Text>
    </Tooltip>
  );
};

export default TruncatedText;
