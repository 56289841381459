import { PageNotFound } from 'features/main/components/CustomErrorPages';
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { HomePage } from './homepage/HomePage';
import useSetDocumentTitle from 'features/common/hooks/useSetDocumentTitle';

const HomeContainer = () => {
  useSetDocumentTitle();
  return (
    <Switch>
      <ApmRoute exact path='/' component={HomePage} />
      <ApmRoute component={PageNotFound} />
    </Switch>
  );
};

export default HomeContainer;
