import { Redirect, RouteProps } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { env } from 'features/common/config/envConfig';
import { useGetCurrentUserOrgs } from 'features/profile/services/identity/user';
import { APP_ORGANIZATION, APP_ROLES, ORG_ROLES } from './resource';
import { useAuth0TokenClaims } from 'features/common/auth/claims';
import {
  IUserRole,
  TokenCustomClaimKeysEnum,
} from '@sitecore-ui/portal-singular';
import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMainState } from 'features/main/context';
import {
  ContextUserApplication,
  useGetUserAppAccessQuery,
  useGetUserOrgAccessQuery,
  UserApplicationRole,
  UserOrganizationRole,
} from 'gql/graphql';

const { ORG_ADMIN, ORG_OWNER, ORG_USER } = ORG_ROLES;
const { ADMIN } = APP_ROLES;

export const transformData = ({
  orgAccess,
  appAccess,
}: {
  orgAccess: UserOrganizationRole[];
  appAccess: ContextUserApplication[];
}): IUserRole[] => {
  const appData = appAccess
    //@ts-ignore
    .flatMap(({ assignedRoles: { nodes } }) => nodes)
    .map(
      ({
        applicationId,
        organizationId,
        productCode,
        role,
      }: UserApplicationRole) => ({
        scope: productCode,
        role,
        organizationId,
        tenantId: applicationId,
      }),
    );

  const orgData = orgAccess.map(({ organizationId, name }) => ({
    scope: 'Organization',
    role: name,
    organizationId,
    tenantId: null,
  }));

  return [...orgData, ...appData];
};

function useAppAccess() {
  const { claims } = useAuth0TokenClaims();
  const state = useMainState();

  const hasOrgAdminAccess = state.env?.ORG_ADMIN || false;

  const hasOrgOwnerAccess = state.env?.ORG_OWNER || false;

  const hasOrgAdminOwnerAccess = state.env?.ORG_ADMIN_OR_OWNER || false;

  const belongsToOrg = (roles: string[]) =>
    roles.some((r) => r.includes('Organization'));

  const hasOrg = useMemo(
    () =>
      belongsToOrg((claims && claims[TokenCustomClaimKeysEnum.ROLES]) ?? []),
    [claims],
  );

  const {
    data: appRoles,
    loading: appRolesLoading,
    error: appRolesError,
  } = useGetUserAppAccessQuery({
    variables: { first: 100, env },
    skip: hasOrgAdminOwnerAccess,
  });
  const {
    data: orgRoles,
    loading: orgRolesLoading,
    error: orgRolesError,
  } = useGetUserOrgAccessQuery({ variables: { first: 25 } });

  const userRoles = useMemo(
    () =>
      transformData({
        orgAccess: orgRoles?.user?.organizationRoles?.nodes ?? [],
        //@ts-ignore
        appAccess: appRoles?.user?.applications?.nodes ?? [],
      }),
    [orgRoles, appRoles],
  );

  const userRolesAreLoading = orgRolesLoading || appRolesLoading;

  const hasError = appRolesError || orgRolesError;

  return {
    claims,
    hasOrgAdminAccess,
    hasOrgOwnerAccess,
    hasOrgAdminOwnerAccess,
    hasOrg,
    userRolesAreLoading,
    userRoles,
    hasError,
  };
}

type AdminOwnerWrapperType = {
  children: JSX.Element;
};

/**
 * This is used as a "Guard Wrapper" that renders child components only when current user
 * has one of Admin|Organization Owner|Organization Admin roles.
 */
function AdminOwnerWrapper({ children }: AdminOwnerWrapperType) {
  const { hasOrgAdminOwnerAccess } = useAppAccess();

  return hasOrgAdminOwnerAccess ? children : null;
}

/**
 * This is used as a "Guard Wrapper" that renders child components only when current user
 * has none of Admin|Organization Owner|Organization Admin roles.
 */
function UserWrapper({ children }: AdminOwnerWrapperType) {
  const { hasOrgAdminOwnerAccess } = useAppAccess();

  return !hasOrgAdminOwnerAccess ? children : null;
}

/**
 * This is used as a "Guard Route" that is only be accessible when current user
 * has one of Admin|Organization Owner|Organization Admin roles,
 * else it redirects to the home screeen.
 */
function AdminOwnerRoute({ component: Component, ...rest }: RouteProps) {
  const { isAuthenticated, user } = useAuth0();
  const roles = user && user[TokenCustomClaimKeysEnum.ROLES];

  const isAdminOwner =
    roles.filter((item: any) =>
      [ORG_ADMIN, ORG_OWNER, ADMIN].includes(item.split('\\')[1]),
    ).length > 0;

  return (
    <ApmRoute
      {...rest}
      render={(props: any) =>
        isAuthenticated && isAdminOwner ? (
          <>{Component && <Component {...props} />}</>
        ) : (
          <Redirect to='/no-access' />
        )
      }
    />
  );
}

export { AdminOwnerWrapper, AdminOwnerRoute, useAppAccess };

function useOrgs() {
  const {
    data: userOrgsData,
    isError: userOrgsError,
    isLoading: userOrgsLoading,
    isFetched: userOrgsDataIsFetched,
  } = useGetCurrentUserOrgs({
    pagenumber: 1,
    pagesize: 100,
  });

  const hasOrgs = Boolean(userOrgsData?.data?.data?.length ?? 0);

  return {
    userOrgsData: userOrgsData?.data?.data,
    userOrgsError,
    userOrgsLoading,
    hasOrgs,
    userOrgsDataIsFetched,
  };
}

type UserHasOrgsType = {
  children: React.ReactNode;
};

/**
 * This is used as a "Conditional Wrapper" that renders child components only when current user
 * has at least one org.
 */
function UserHasOrgs({ children }: UserHasOrgsType) {
  const { hasOrgs } = useOrgs();

  return <>{hasOrgs ? children : null}</>;
}

function UserHasNoOrgs({ children }: UserHasOrgsType) {
  const { user } = useAuth0();
  const roles = (user && user[TokenCustomClaimKeysEnum.ROLES]) || [];

  const hasOrgs =
    roles.filter((item: any) =>
      [ORG_ADMIN, ORG_OWNER, ORG_USER].includes(item.split('\\')[1]),
    ).length > 0;

  return <>{!hasOrgs ? children : null}</>;
}

const UserHasNoTenants = ({ children }: { children?: React.ReactNode }) => {
  const state = useMainState();

  return (
    <>{state?.env?.LABELS.includes('WITH_NO_TENANTS') ? children : null}</>
  );
};

export { useOrgs, UserHasOrgs, UserHasNoOrgs, UserHasNoTenants, UserWrapper };

function getSingleOrgDetails({
  userRoles,
  organizationId,
  hasOrgAdminOwnerAccess,
}: {
  userRoles: IUserRole[];
  organizationId: string;
  hasOrgAdminOwnerAccess?: boolean;
}) {
  const orgRoles = userRoles?.filter(
    (o) => o.organizationId === organizationId && o.scope === APP_ORGANIZATION,
  );

  if (hasOrgAdminOwnerAccess && orgRoles) {
    const [first] = orgRoles.filter((o) =>
      [ORG_ADMIN, ORG_OWNER].includes(o.role),
    );

    return first;
  }

  if (orgRoles) return orgRoles[0];

  return null;
}
export { getSingleOrgDetails };
